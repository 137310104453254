import styled from "styled-components"

import { BillingSummary } from "src/components/Homes/CreateHomeWizard/BillingSummary/BillingSummary"
import { CreateHomeTerms } from "src/components/Homes/CreateHomeWizard/CreateHomeTerms"
import { TFetchCreateHomeEstimateResponse } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import LightBulbIcon from "src/ui/icons/lightbulb-on.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroupBuildingSummaryStep({
  createHomeEstimate,
  hideBillingInformation,
  checkedTermsError,
  checkedTerms,
  onCheckTerms,
  isLoadingEstimate,
  onReviewClick,
  importHomesCount,
  hasTimedOut,
}: {
  createHomeEstimate?: TFetchCreateHomeEstimateResponse
  isLoadingEstimate: boolean
  hideBillingInformation?: boolean
  checkedTermsError: boolean
  checkedTerms: boolean
  onCheckTerms: () => void
  onReviewClick: () => void
  importHomesCount: number
  hasTimedOut: boolean
}) {
  const { t, langKeys } = useTranslate()

  if (!hideBillingInformation && (isLoadingEstimate || !createHomeEstimate)) {
    return (
      <LoadingWrapper>
        <MSkeleton variant="rect" height={20} />
        <MSkeleton variant="rect" />
        <MSkeleton variant="rect" />
      </LoadingWrapper>
    )
  }

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.L}>
        {t(langKeys.summary)}
      </MText>
      <BannerWrapper>
        {hasTimedOut && (
          <MBanner
            type="warning"
            title={t(langKeys.home_group_import_homes_timeout_title)}
            icon={<LightBulbIcon width={24} />}
            fullWidth
          >
            {t(langKeys.home_group_import_homes_timeout_description)}
          </MBanner>
        )}

        <MBanner
          type="neutral"
          icon={<LightBulbIcon width={24} />}
          title={t(langKeys.home_group_import_homes_review_title)}
          buttons={[
            {
              label: t(langKeys.home_group_import_homes_review_button_label),
              onClick: onReviewClick,
              buttonProps: {
                variant: "minimal",
              },
            },
          ]}
          horizontal
          fullWidth
        />
      </BannerWrapper>

      {!hideBillingInformation && createHomeEstimate && (
        <BillingSummary createHomeEstimate={createHomeEstimate} />
      )}

      {hideBillingInformation && (
        <MCard border boxShadow={false}>
          <MText variant="subtitle">
            {t(langKeys.activating_home_title, {
              homes: importHomesCount,
            })}
          </MText>
        </MCard>
      )}

      {!hideBillingInformation && (
        <CreateHomeTerms
          checkedTerms={checkedTerms}
          onCheckTerms={onCheckTerms}
          checkedTermsError={checkedTermsError}
          homeCount={importHomesCount}
        />
      )}
    </div>
  )
}

const LoadingWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const BannerWrapper = styled.div`
  display: grid;
  gap: ${spacing.L};
  margin-bottom: ${spacing.L};
`
