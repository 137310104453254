import { differenceInDays } from "date-fns"

import {
  deviceReadingType,
  hardwareRevision,
  p1DeviceReadingType,
  p2DeviceReadingType,
} from "src/data/devices/logic/deviceConstants"
import {
  DeviceReadingType,
  HardwareType,
  HardwareVersionBoundary,
  TDevice,
  TMouldRiskLevel,
  TReactions,
  TReactionType,
} from "src/data/devices/types/deviceTypes"
import { TClockTypeMaybe } from "src/data/user/user"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { localizedDate } from "src/ui/LocalizedDate/localized-date"
import { extractHHMM } from "src/utils/l10n"

export function formatLastHeardFrom(
  lastHeardFromDate: string,
  t: ITranslateFunction,
  clockType: TClockTypeMaybe,
  timezone?: string
) {
  const dateDiff = differenceInDays(Date.now(), new Date(lastHeardFromDate))

  switch (dateDiff) {
    case 0:
      return `${extractHHMM(
        new Date(localizedDate(lastHeardFromDate, clockType, timezone)),
        clockType
      )}`
    case 1:
      return t(langKeys.yesterday)
    default:
      return localizedDate(lastHeardFromDate, clockType, timezone)
  }
}

export function mouldRiskLevelStringify(
  riskLevel: TMouldRiskLevel | undefined,
  t: ITranslateFunction
) {
  switch (riskLevel) {
    case "ANALYZING":
      return t(langKeys.risk_of_mold_analyzing)
    case "LOW":
      return t(langKeys.risk_of_mold_low)
    case "MEDIUM":
      return t(langKeys.risk_of_mold_medium)
    case "HIGH":
      return t(langKeys.risk_of_mold_high)
    default:
      return t(langKeys.risk_of_mold_unknown)
  }
}

export function mouldInfo(
  riskLevel: TMouldRiskLevel | undefined,
  t: ITranslateFunction
) {
  if (riskLevel === "ANALYZING") {
    return t(langKeys.risk_of_mold_graph_body_analyzing)
  }
  if (riskLevel === "MEDIUM") {
    return t(langKeys.risk_of_mold_graph_body_medium)
  }
  if (riskLevel === "HIGH") {
    return t(langKeys.risk_of_mold_graph_body_high)
  }
}

export function showMouldInfo(riskLevel: TMouldRiskLevel | undefined) {
  if (!riskLevel) {
    return false
  }
  return ["ANALYZING", "MEDIUM", "HIGH"].includes(riskLevel)
}

export function getSensorValue(
  sensor: { time: string; value: number } | undefined,
  decimals = 0
) {
  if (!sensor || !sensor.value) return "-"

  return sensor.value.toFixed(decimals)
}

export function getDeviceHardwareType(hardwareVersion: number): {
  type: HardwareType
  revision?: string
} {
  if (!hardwareVersion) {
    return {
      type: HardwareType.NONE,
    }
  }

  if (hardwareVersion === 255) {
    return {
      type: HardwareType.P1,
      revision: hardwareRevision[hardwareVersion],
    }
  }

  if (
    hardwareVersion >= HardwareVersionBoundary.P1_BOUNDARY_START &&
    hardwareVersion <= HardwareVersionBoundary.P1_BOUNDARY_END
  ) {
    return {
      type: HardwareType.P1,
      revision: hardwareRevision[hardwareVersion],
    }
  }

  if (
    hardwareVersion >= HardwareVersionBoundary.P2_BOUNDARY_START &&
    hardwareVersion <= HardwareVersionBoundary.P2_BOUNDARY_END
  ) {
    return {
      type: HardwareType.P2,
      revision: hardwareRevision[hardwareVersion],
    }
  }

  if (
    hardwareVersion >= HardwareVersionBoundary.P3_BOUNDARY_START &&
    hardwareVersion <= HardwareVersionBoundary.P3_BOUNDARY_END
  ) {
    return {
      type: HardwareType.P3,
      revision: hardwareRevision[hardwareVersion],
    }
  }

  if (
    hardwareVersion >= HardwareVersionBoundary.A1_BOUNDARY_START &&
    hardwareVersion <= HardwareVersionBoundary.A1_BOUNDARY_END
  ) {
    return {
      type: HardwareType.A1,
      revision: hardwareRevision[hardwareVersion],
    }
  }

  if (
    hardwareVersion >= HardwareVersionBoundary.WLD_BOUNDARY_START &&
    hardwareVersion <= HardwareVersionBoundary.WLD_BOUNDARY_END
  ) {
    return {
      type: HardwareType.WLD,
    }
  }

  return {
    type: HardwareType.NONE,
  }
}

const pointMessageTypes: Record<number, string> = {
  1: "HEARTBEAT_REQUEST",
  2: "HEARTBEAT_RESPONSE",
  3: "EVENT_REQUEST",
  4: "EVENT_RESPONSE",
  5: "ENROLL_REQUEST",
  6: "ENROLL_RESPONSE",
  7: "DIAGNOSTICS",
  8: "CVAR_REQUEST",
  9: "ALARM_TURN_OFF_REQUEST",
  10: "COMMAND_RESPONSE",
  11: "HAP_STATUS",
  12: "HAP_SECURITY_SYSTEM_CONTROL_REQUEST",
  13: "HAP_SECURITY_SYSTEM_CONTROL_RESPONSE",
  14: "CELLULAR_STATUS",
}
export function getPointMessageType(messageType: number): string {
  return pointMessageTypes[messageType] ?? ""
}

export function isValidDeviceReadingtype(
  type: string
): type is DeviceReadingType {
  const allTypes: string[] = [
    ...deviceReadingType,
    ...p1DeviceReadingType,
    ...p2DeviceReadingType,
  ]

  if (allTypes.indexOf(type) !== -1) {
    return true
  }

  return false
}
export const pointEventTypes: Record<number, string> = {
  0: "DEVICE_POWER_ON",
  1: "SENSOR_THRESHOLD_GT",
  2: "SENSOR_THRESHOLD_LT",
  3: "BUTTON_PRESSED",
  4: "OTA_SUCCEEDED",
  5: "OTA_FAILED",
  6: "MCU_WATCHDOG_RESET",
  7: "BATTERY_LOW",
  8: "NETWORK_DOWN",
  9: "ALARM_HEARD",
  10: "WIFI_WATCHDOG_RESET",
  11: "TAMPER",
  12: "ACTIVITY",
  13: "GLASS_BREAK",
  14: "TEMPERATURE_RAPID_RISE",
  15: "WIFI_RECONFIGURED",
  16: "CHARGER_CONNECTED",
  17: "CHARGER_DISCONNECTED",
  18: "BATTERY_CHARGING_COMPLETE",
  19: "MOTION_PASSIVE_INFRARED",
  20: "DEVICE_POWER_OFF",
  22: "TAMPER_MOUNTED",
  23: "TAMPER_REMOVED",
  24: "SOUND_OF_INTEREST",
  25: "BLE_SCAN",
  26: "IGNORED_POWER_SWITCH",
  27: "SENSOR_SWAP_SUCCEEDED",
  28: "SENSOR_SWAP_FAILED",
  29: "GAS_SCAN",
  30: "FIRE_SMOKE_ALARM",
  31: "FIRE_SMOKE_CLEARED",
  32: "CO_ALARM",
  33: "CO_CLEARED",
  34: "SELFTEST_COMPLETED",

  // Synthetic events generated by the backend.
  1000: "DEVICE_OFFLINE",
  1001: "DEVICE_ONLINE",
  1002: "FIRMWARE_CHANGED",
  1003: "TEMPERATURE_HIGH",
  1004: "TEMPERATURE_LOW",
  1005: "HUMIDITY_HIGH",
  1006: "HUMIDITY_LOW",
  1007: "SOUND_LEVEL_HIGH",
  1008: "SMOKE_DETECTED",
  1009: "GLASS_BREAK_BACKEND_CONFIRMED",
  1010: "ALARM_HEARD_BACKEND_CONFIRMED",

  // Insight related events
  2000: "MOULD_INSIGHT_LOW",
  2001: "MOULD_INSIGHT_MEDIUM",
  2002: "MOULD_INSIGHT_HIGH",
  2003: "ALMOST_FREEZING",
  2004: "AIR_TOO_DRY",
  2005: "AIR_TOO_HUMID",
  2006: "BEDROOM_TOO_WARM",
  2007: "ROOM_TOO_WARM",
  2008: "MOULD_INSIGHT_ANALYZING",
}
export function getPointEventType(eventType: number): string | undefined {
  return pointEventTypes[eventType]
}

export function isEurekaDevice(d: Pick<TDevice, "hardware_version">) {
  return getDeviceHardwareType(d.hardware_version).type === HardwareType.A1
}

export function isWaterLeakDetectionDevice(
  d: Pick<TDevice, "hardware_version">
) {
  return getDeviceHardwareType(d.hardware_version).type === HardwareType.WLD
}

export function isMonitoringDevice(d: Pick<TDevice, "hardware_version">) {
  return [HardwareType.P1, HardwareType.P2, HardwareType.P3].includes(
    getDeviceHardwareType(d.hardware_version).type
  )
}

/**
 * This is different from having ICM (Indoor Climate Monitoring) support.
 * This is checking if the device has temperature readings at all.
 * ICM support means that alerts can be triggered based on temperature/humidity readings.
 * For example, an outdoor device would not have ICM support but it would have temperature readings.
 */
export function hasTemperatureAndHumidityReadings(
  d: Pick<TDevice, "hardware_version">
) {
  return [
    HardwareType.P1,
    HardwareType.P2,
    HardwareType.P3,
    HardwareType.A1,
  ].includes(getDeviceHardwareType(d.hardware_version).type)
}

export function isCigaretteDevice(d: Pick<TDevice, "hardware_version">) {
  return (
    isEurekaDevice(d) ||
    getDeviceHardwareType(d.hardware_version).type === HardwareType.P3
  )
}

export function hasIndoorClimateSupport(device: TDevice) {
  const deviceType = getDeviceHardwareType(device.hardware_version).type
  const supportedHardware = [HardwareType.P2, HardwareType.P3, HardwareType.A1]
  const isIndoorDevice = !device.placed_outdoors

  const isSupportedDevice =
    supportedHardware.includes(deviceType) && isIndoorDevice

  return isSupportedDevice
}

export function getDeviceTemperatureReactions({ device }: { device: TDevice }) {
  const lowType: TReactions["type"] = "home:temperature:low"
  const highType: TReactions["type"] = "home:temperature:high"
  const reactionTypes: TReactionType[] = [lowType, highType]

  const temperatureReactions =
    device.configuration?.reactions.filter((reaction) =>
      reactionTypes.includes(reaction.type)
    ) ?? []

  const notificationsEnabled =
    temperatureReactions?.length > 0 &&
    temperatureReactions?.every((reaction) => reaction.notifications.length > 0)

  const lowThreshold = temperatureReactions.find(
    (reaction) => reaction.type === lowType
  )?.value

  const highThreshold = temperatureReactions.find(
    (reaction) => reaction.type === highType
  )?.value

  return { notificationsEnabled, lowThreshold, highThreshold }
}

export function getDeviceHumidityReactions({ device }: { device: TDevice }) {
  const lowType: TReactions["type"] = "home:humidity:low"
  const highType: TReactions["type"] = "home:humidity:high"
  const reactionTypes: TReactionType[] = [lowType, highType]

  const humidityReactions =
    device.configuration?.reactions.filter((reaction) =>
      reactionTypes.includes(reaction.type)
    ) ?? []

  const notificationsEnabled =
    humidityReactions?.length > 0 &&
    humidityReactions?.every((reaction) => reaction.notifications.length > 0)

  const lowThreshold = humidityReactions.find(
    (reaction) => reaction.type === lowType
  )?.value

  const highThreshold = humidityReactions.find(
    (reaction) => reaction.type === highType
  )?.value

  return { notificationsEnabled, lowThreshold, highThreshold }
}
