import styled from "styled-components"

import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { EventButton } from "src/components/VirtualDeviceControl/EventButton"
import { SliderSetting } from "src/components/VirtualDeviceControl/SettingsSlider"
import { VirtualDeviceBatteryIcon } from "src/components/VirtualDeviceControl/VirtualDeviceBatteryIcon"
import {
  HardwareVersionBoundary,
  TVirtualDevice,
} from "src/data/devices/types/deviceTypes"
import {
  IVirtualEnvironmentRequest,
  usePostVirtualDeviceEvent,
  usePostVirtualEnvironment,
} from "src/data/homes/queries/virtualDeviceQueries"
import { DEFAULT_HOME_ID } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { MButton } from "src/ui/Button/MButton"
import WifiGoodIcon from "src/ui/icons/wifi-good.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { AlmostFreezingToggle } from "./AlmostFreezingToggle"
import { VirtualDeviceIcmControls } from "./VirtualDeviceIcmControls"

export function VirtualA1DeviceControls({
  simulatedDevice,
}: {
  simulatedDevice: TVirtualDevice
}) {
  const { orgId } = useOrganization()
  const postVirtualDeviceEvent = usePostVirtualDeviceEvent()
  const postVirtualEnvironment = usePostVirtualEnvironment()

  const homeId = simulatedDevice.home || DEFAULT_HOME_ID

  const fetchHome = useFetchHome({ orgId, homeId })

  if (fetchHome.isLoading) {
    return null
  }
  if (fetchHome.isError) {
    throw Error(`Could not fetch home ${homeId}`)
  }

  if (!simulatedDevice.virtual_environment) {
    return null
  }

  function handleEureka(eventType: IVirtualEnvironmentRequest["event_type"]) {
    postVirtualDeviceEvent.mutate({
      orgId,
      deviceId: simulatedDevice.device_id,
      homeId: homeId,
      eventType,
    })
  }

  const home = fetchHome.data
  const updating = postVirtualDeviceEvent.isLoading || fetchHome.isLoading
  const smokingDetectionAvailable =
    simulatedDevice.hardware_version >=
    HardwareVersionBoundary.P3_BOUNDARY_START
  const isCalibrationDone =
    new Date(
      home?.smoking_detection?.calibration_done_at ??
        new Date(new Date().getTime() + 100 * 1000)
    ) < new Date()

  return (
    <CardWrapper>
      <Column>
        <SliderSetting
          label="V"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={() => (
            <VirtualDeviceBatteryIcon
              voltage={simulatedDevice.battery?.voltage ?? 0}
              device={simulatedDevice}
            />
          )}
          enabled={
            simulatedDevice.offline === false ||
            simulatedDevice.battery?.voltage <= 2.5
          } // 2.5 is the lowest voltage and means virtual device is offline
          step={0.01}
          min={2.5}
          max={3.0}
          attribute={"battery_voltage"}
          marks={[
            {
              value: 2.5,
              label: "Empty",
            },
            {
              value: 2.6,
              label: "Low",
            },
            {
              value: 2.8,
              label: "Normal",
            },
            {
              value: 3.0,
              label: "Full",
            },
          ]}
        />

        <SliderSetting
          label="db"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={WifiGoodIcon}
          step={1}
          min={-100}
          max={0}
          attribute={"wifi_rssi"}
          marks={[
            { value: -100, label: "Offline" },
            ...[-50, 0].map((value) => ({
              value,
              label: `${value}db`,
            })),
          ]}
          enabled={
            simulatedDevice.offline === false ||
            simulatedDevice.virtual_environment.wifi_rssi <= -100
          } // -100 is the lowest rssi and means virtual device is offline
        />

        <VirtualDeviceIcmControls
          simulatedDevice={simulatedDevice}
          orgId={orgId}
          homeId={homeId}
        />

        <MButton
          loading={updating}
          disabled={simulatedDevice.offline}
          onClick={() => handleEureka("self_test_completed_fail")}
        >
          Send failed self-test
        </MButton>
        <MButton
          loading={updating}
          disabled={simulatedDevice.offline}
          onClick={() => handleEureka("self_test_completed_success")}
        >
          Send successful self-test
        </MButton>
      </Column>

      <Column>
        <EventButton
          eventType="smoking_detected"
          orgId={orgId}
          deviceId={simulatedDevice.device_id}
          homeId={homeId}
          disabled={
            !smokingDetectionAvailable ||
            !isCalibrationDone ||
            simulatedDevice.offline
          }
          label="Smoking detected"
        />

        {!isCalibrationDone && (
          <InfoMessage variant="bodyS" color="secondary">
            Cannot trigger smoking event until calibration is done
          </InfoMessage>
        )}

        <SettingToggle
          title="Smoking calibration done"
          titleProps={{ variant: "body" }}
          value={smokingDetectionAvailable && isCalibrationDone}
          disabled={
            !smokingDetectionAvailable ||
            simulatedDevice.offline ||
            home.smoking_detection?.state === "disabled"
          }
          onSave={async (checked) => {
            try {
              await postVirtualEnvironment.mutateAsync({
                orgId,
                deviceId: simulatedDevice.device_id,
                homeId: simulatedDevice.home ?? DEFAULT_HOME_ID,
                body: {
                  smoking_calibration_done: checked
                    ? new Date(
                        new Date().getTime() - 1000 * 3600 * 24
                      ).toISOString()
                    : new Date(
                        new Date().getTime() + 1000 * 3600 * 24
                      ).toISOString(),
                },
              })
              return { isSuccess: true }
            } catch (e) {
              return { isSuccess: false }
            }
          }}
        />

        <AlmostFreezingToggle
          simulatedDevice={simulatedDevice}
          onSave={async (eventType) => {
            handleEureka(eventType)
            return true
          }}
        />

        {!smokingDetectionAvailable && (
          <InfoMessage variant="bodyS" color="secondary">
            Only M3 & A1 devices can trigger smoking detection
          </InfoMessage>
        )}

        <SettingToggle
          title="Fire alarm alerting"
          titleProps={{ variant: "body" }}
          disabled={simulatedDevice.offline || updating}
          value={simulatedDevice.fire_alarm?.status === "alerting"}
          onSave={async (checked) => {
            try {
              await postVirtualDeviceEvent.mutateAsync({
                orgId,
                deviceId: simulatedDevice.device_id,
                homeId: homeId,
                eventType: checked ? "fire_smoke_alarm" : "fire_smoke_cleared",
              })
              return { isSuccess: true }
            } catch (e) {
              return { isSuccess: false }
            }
          }}
        />

        <SettingToggle
          title="CO alarm alerting"
          titleProps={{ variant: "body" }}
          disabled={simulatedDevice.offline || updating}
          value={simulatedDevice.carbon_monoxide_alarm?.status === "alerting"}
          onSave={async (checked) => {
            try {
              await postVirtualDeviceEvent.mutateAsync({
                orgId,
                deviceId: simulatedDevice.device_id,
                homeId: homeId,
                eventType: checked ? "co_alarm" : "co_cleared",
              })
              return { isSuccess: true }
            } catch (e) {
              return { isSuccess: false }
            }
          }}
        />

        <SettingToggle
          title="End Of Life"
          titleProps={{ variant: "body" }}
          disabled={simulatedDevice.offline || updating}
          value={simulatedDevice.end_of_life_at !== undefined}
          onSave={async (checked) => {
            try {
              await postVirtualDeviceEvent.mutateAsync({
                orgId,
                deviceId: simulatedDevice.device_id,
                homeId: homeId,
                eventType: checked
                  ? "fire_alarm_trouble_eol"
                  : "fire_alarm_trouble_cleared",
              })
              return { isSuccess: true }
            } catch (e) {
              return { isSuccess: false }
            }
          }}
        />

        <SettingToggle
          title="Mounted"
          titleProps={{ variant: "body" }}
          disabled={simulatedDevice.offline || updating}
          value={simulatedDevice.mount_status === "mounted"}
          onSave={async (checked) => {
            try {
              await postVirtualDeviceEvent.mutateAsync({
                orgId,
                deviceId: simulatedDevice.device_id,
                homeId: homeId,
                eventType: checked ? "tamper_mounted" : "tamper_removed",
              })
              return { isSuccess: true }
            } catch (e) {
              return { isSuccess: false }
            }
          }}
        />
      </Column>
    </CardWrapper>
  )
}

const InfoMessage = styled(MText)`
  max-width: 240px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: space-between;
  gap: ${spacing.XS};
`

const CardWrapper = styled.div`
  display: flex;
  gap: ${spacing.XL};
`
