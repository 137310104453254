export enum FeatureFlag {
  // Features
  SHOW_FLAGS_LINK = "showFlagsLink",
  VIRTUAL_DEVICES = "virtualDevices",
  SHOW_OUTDOOR_MOTION_GRAPH = "showOutdoorMotionGraph",
  OAUTH = "oauthEnabled",
  HOME_DETAILS_BETA = "homeDetailsBeta",
  SHOW_NEW_HOME_DETAILS_VIEW = "showNewHomeDetailsView",
  HOME_LIST_WIDGETS = "homeListWidgets",
  MINUT_EMPLOYEE = "minutEmployee",
  EXPORT_SENSOR_VALUES_FULL_RESOLUTION = "exportSensorValuesFullResolution",
  PARADISE_GRAPHS_2_0 = "paradiseGraphs2_0",
  PARADISE_QUOTES = "paradiseQuotes",

  // Dev
  DEBUG = "debug", // Generic flag for toggling debugging
  BETA = "beta", // Generic flag for features ready for testing
  REACT_QUERY_DEVTOOLS = "reactQueryDevTools",
  USE_MOCK_DATA = "useMockData",
  INSTALL_DEVICE = "installDevice",
  LOGOUT_DEBUGGER = "logoutDebugger",

  // Logging
  LOGGING_STORAGE_DEFAULT = "loggingStorageDefault",
  LOGGING_DEBUG = "loggingDebugDefault",
  LOGGING_ROUTER = "loggingRouter",
  LOGGING_ANALYTICS = "loggingAnalytics",
  LOGGING_SENTRY_CAPTURE_CONSOLE = "loggingSentryCaptureConsole",

  // Experimental
  EXPERIMENTAL_EVENT_ANNOTATIONS = "experimentalEventAnnotations",
}
