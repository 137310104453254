import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { TPostUpdateSubscriptionEstimate } from "src/data/subscription/types/subscriptionTypes"

export enum Taxability {
  TAXABLE = "taxable",
  EXEMPT = "exempt",
}
export interface ICustomer {
  id: string
}

export interface IBillingAddress {
  first_name?: string // max chars=150
  last_name?: string // max chars=150
  email?: string // max chars=70
  company?: string // max chars=250
  phone?: string // max chars=50
  city?: string // max chars=50
  country?: string // max chars=50
  line1?: string // max chars=150
  line2?: string // max chars=150
  state?: string // max chars=50
  state_code?: string // max chars=50
  validation_status?: ValidationStatus
  zip?: string // max chars=20
}

export enum ValidationStatus {
  NOT_VALIDATED = "not_validated",
  VALID = "valid",
  PARTIALLY_VALID = "partially_valid",
  INVALID = "invalid",
}

export interface ICard {
  first_name?: string
  last_name?: string
  brand: CardBrand
  expiry_month: number
  expiry_year: number
  last4: string
  billing_zip?: string
  masked_number?: string
}

export enum PaymentSourceType {
  CARD = "card",
  APPLE_PAY = "apple_pay",
  GOOGLE_PAY = "google_pay",
}

export enum PaymentSourceStatus {
  VALID = "valid",
  EXPIRING = "expiring",
  EXPIRED = "expired",
  INVALID = "invalid",
  PENDING_VERIFICATION = "pending_verification",
}

export interface IPaymentSource {
  id: string
  status: PaymentSourceStatus
  type: PaymentSourceType
  card?: ICard
}

export enum CardBrand {
  VISA = "visa",
  MASTERCARD = "mastercard",
  AMERICAN_EXPRESS = "american_express",
}

export enum SubscriptionStatus {
  FUTURE = "future", // The Subscription is scheduled to start in a future date.
  IN_TRIAL = "in_trial", // The subscription is in trial.
  ACTIVE = "active", // The subscription is in active state and will be charged at start of each term based on the recurring items(plan & addons etc.,).
  NON_RENEWING = "non_renewing", // The subscription will be cancelled at end of the current term.
  PAUSED = "paused", // The subscription is paused. No new recurring actions will be allowed, but any pending payments will be collected.
  CANCELLED = "cancelled", // The subscription has been cancelled. No new recurring actions will take place, but any pending payments will be collected.
}

/** Timestamp UTC in seconds */
export type UTCSeconds = number

export enum BillingPeriodUnit {
  MONTH = "month",
  YEAR = "year",
}

export type Coupon = {
  coupon_id: string
  apply_till?: UTCSeconds
  applied_count: number
  coupon_code?: string
}

export interface ISubscription {
  id: string
  customer_id: string
  plan_id: string
  payment_source_id?: string
  currency_code: TSubscriptionCurrencyCode
  plan_quantity: number
  plan_unit_price?: number // in cents
  plan_amount?: number // in cents
  billing_period?: number // nbr of $billing_period_unit
  billing_period_unit?: BillingPeriodUnit
  status: SubscriptionStatus
  start_date?: UTCSeconds
  trial_end?: UTCSeconds // End of the trial period for the subscription. Presence of this value for 'future' subscription implies the subscription will go into 'in_trial' state when it starts.
  current_term_start?: UTCSeconds
  current_term_end?: UTCSeconds
  next_billing_at?: UTCSeconds
  created_at?: UTCSeconds
  started_at?: UTCSeconds
  activated_at?: UTCSeconds
  pause_date?: UTCSeconds
  resume_date?: UTCSeconds
  cancelled_at?: UTCSeconds
  updated_at?: UTCSeconds
  due_invoice_count?: number
  total_dues?: string
  deleted: boolean
  coupons?: Coupon[]
  addons?: IChargebeeAddon[]
  has_scheduled_changes?: boolean // If true, there are subscription changes scheduled on next renewal. default=false
}

export interface IChargebeeAddon {
  id: string
  quantity?: number
  unit_price?: number //in cents
  amount?: number //in cents
  trial_end?: number //timestap UTC in seconds
  remaining_billing_cycles?: number
  quantity_in_decimal?: string
  unit_price_in_decimal?: string
  amount_in_decimal?: string
}

export interface ISubscriptionEstimate {
  object: "subscription_estimate"
  status: SubscriptionStatus
  next_billing_at: number
  currency_code: TSubscriptionCurrencyCode
}

export interface IDiscount {
  amount: number // Discount amount. in cents, min=0
  description?: string // Detailed description of this discount line item. optional, string, max chars=250
  entity_type: DiscountItemType // Type of this Discount lineitem. enumerated string
  entity_id?: string // The identifier of the modelled entity this lineitem is bases on. Will be null for 'Prorated Credits' & 'Promotional Credits'. optional, string, max chars=100
}

export enum DiscountItemType {
  ITEM_LEVEL_COUPON = "item_level_coupon", // Represents the 'Item' level coupons applied to this invoice. Further the 'coupon_id' attribute specifies the coupon id this discount is based on.
  DOCUMENT_LEVEL_COUPON = "document_level_coupon", // Represents the 'Document' level coupons applied to this document. Further the 'coupon_id' attribute specifies the coupon id this discount is based on.
  PROMOTIONAL_CREDITS = "promotional_credits", // Represents the Promotional Credits item in invoice. The 'coupon_id' attribute will be null in this case.
  PRORATED_CREDITS = "prorated_credits", // Represents the credit adjustment items in invoice. The 'coupon_id' attribute will be null in this case.
}

export enum InvoicePriceType {
  TAX_EXCLUSIVE = "tax_exclusive",
  TAX_INCLUSIVE = "tax_inclusive",
}

export enum LineItemEntityType {
  PLAN_SETUP = "plan_setup", // Indicates that this lineitem is based on 'Plan Setup' charge. The 'entity_id' attribute specifies the plan id.
  PLAN = "plan", // Indicates that this lineitem is based on 'Plan' entity. The 'entity_id' attribute specifies the plan id.
  ADDON = "addon", // Indicates that this lineitem is based on 'Addon' entity. The 'entity_id' attribute specifies the addon id.
  ADHOC = "adhoc", // Indicates that this lineitem is not modelled. i.e created adhoc. So the 'entity_id' attribute will be null in this case.
}

export enum InvoiceStatusType {
  PAID = "paid", // Indicates a paid invoice.
  POSTED = "posted", // Indicates the payment is not yet collected and will be in this state till the due date to indicate the due period.
  PAYMENT_DUE = "payment_due", // Indicates the payment is not yet collected and is being retried as per retry settings.
  NOT_PAID = "not_paid", // Indicates the payment is not made and all attempts to collect is failed.
  VOIDED = "voided", // Indicates a voided invoice.
  PENDING = "pending", //Indicates the invoice is not closed yet. New line items can be added when the invoice is in this state.
}

export enum DunningStatusType {
  IN_PROGRESS = "in_progress", // Dunning is still in progress.
  EXHAUSTED = "exhausted", // Maximum number of attempts have been made.
  STOPPED = "stopped", // Dunning has stopped for this invoice.
  SUCCESS = "success", // Payment successfully collected during dunning process.
}

export interface IInvoice {
  id: string
  po_number?: string // Purchase Order Number for this invoice.
  customer_id: string
  price_type: InvoicePriceType
  date?: UTCSeconds // Date indicated on the invoice. The date is based on your billing configuration - normal billing or metered billing.
  due_date?: UTCSeconds
  currency_code: TSubscriptionCurrencyCode // The currency code (ISO 4217 format) for the invoice.
  total?: number // Invoiced amount in cents.
  amount_adjusted?: number // Total adjustments made against this invoice in cents.
  write_off_amount?: 0 // Amount written off against this invoice in cents.
  amount_due?: number
  amount_paid?: number // Total payments received for this invoice in cents.
  recurring?: boolean // Boolean indicating whether this invoice belongs to a subscription.
  amount_to_collect?: 0
  billing_address?: IBillingAddress
  credits_applied?: number // Total credits applied against this invoice in cents.
  deleted: boolean
  object: "invoice"
  paid_at?: UTCSeconds // Timestamp indicating the date & time this invoice got paid.
  round_off_amount?: 0
  status: InvoiceStatusType // Current status of this invoice.
  sub_total: number
  tax: 0
  dunning_status?: DunningStatusType
  next_retry_at?: UTCSeconds
}

export type TEstimate = NonNullable<TPostUpdateSubscriptionEstimate["estimate"]>
export type TEstimateBreakdown = NonNullable<
  TPostUpdateSubscriptionEstimate["breakdown"]
>
export type TInvoiceEstimate = NonNullable<TEstimate["invoice_estimate"]>
export type TDiscount = NonNullable<TInvoiceEstimate["discounts"]>[number]
export type TCreditNoteEstimate = NonNullable<
  TEstimate["credit_note_estimates"]
>[number]
export type TInvoiceEstimateTax = NonNullable<TInvoiceEstimate["taxes"]>[number]
