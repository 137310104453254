import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import axios, { AxiosError } from "axios"

import { ICustomer, IPaymentSource } from "src/components/Account/types"
import { API_DEFAULT } from "src/constants/minutApi"
import {
  billingKeys,
  useBillingCache,
} from "src/data/billing/queries/billingQueryCache"
import { TPostSetupIntentRequestBody } from "src/data/billing/queries/billingTypes"
import { TPostSetupIntentResponse } from "src/data/billing/queries/billingTypes"
import {
  ICreatePaymentSourceWithIntentBody,
  IPaymentSourceDeleteResponse,
  IPaymentSourceResponse,
  IPostUpdateBillingInfo,
  IPostUpdateBillingInfoResponse,
  ISubscriptionsBody,
  ISubscriptionsResponse,
  TAvailablePlansForUserResponse,
  TBillingHistoryQueryParams,
  TBillingHistoryResponse,
  TFetchCreditNotePdfResponse,
  TFetchCurrentSubscription500Error,
  TFetchCurrentSubscriptionResponse,
  TFetchUserBillingInfoResponse,
  TPlanRenewalEstimateBreakdownFilter,
  TPlanRenewalEstimateBreakdownResponse,
  TPlanRenewalEstimateResponse,
} from "src/data/billing/types/billingTypes"
import { useInvoiceCache } from "src/data/invoices/queries/invoiceQueryCache"
import { TUser } from "src/data/user/user"
import { useMinutQuery } from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchAvailablePlansForUser({
  userId,
  options,
}: {
  userId: string
  options?: UseQueryOptions<
    TAvailablePlansForUserResponse,
    AxiosError,
    TAvailablePlansForUserResponse,
    ReturnType<typeof billingKeys.availablePlans>
  >
}) {
  /**https://api.staging.minut.com/v7/docs/internal#get-/subscriptions/available_plans/-userId- */
  async function fetchAvailablePlansForUser(userId: string) {
    const response =
      await minutApiHttpClient.get<TAvailablePlansForUserResponse>(
        `${API_DEFAULT}/subscriptions/available_plans/${userId}`
      )

    return response.data
  }

  return useQuery(
    billingKeys.availablePlans(userId),
    () => fetchAvailablePlansForUser(userId),
    options
  )
}

export function useFetchBillingInfoWithCreate({
  user,
  options,
}: {
  user: TUser
  options?: UseQueryOptions<
    TFetchUserBillingInfoResponse,
    AxiosError,
    TFetchUserBillingInfoResponse,
    ReturnType<typeof billingKeys.userBillingInfo>
  >
}) {
  async function fetchBillingInfoWithCreate(
    user: TUser
  ): Promise<TFetchUserBillingInfoResponse> {
    try {
      const response = await minutApiHttpClient.get(
        `${API_DEFAULT}/users/${user.user_id}/billing_info`
      )

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        const [firstName, ...lastName] = user.fullname.split(" ")
        await minutApiHttpClient.post(`${API_DEFAULT}/chargebee/customers`, {
          id: user.user_id,
          email: user.email,
          first_name: firstName,
          last_name: lastName.join(" "),
        })

        const response = await minutApiHttpClient.get(
          `${API_DEFAULT}/users/${user.user_id}/billing_info`
        )

        return response.data
      } else {
        throw error
      }
    }
  }

  return useQuery(
    billingKeys.userBillingInfo(user.user_id),
    () => fetchBillingInfoWithCreate(user),
    {
      keepPreviousData: true,
      staleTime: 60 * 1000,
      ...options,
    }
  )
}

export function useFetchCurrentSubscription({
  userId,
  props,
}: {
  userId: string
  props?: {
    options?: UseQueryOptions<
      TFetchCurrentSubscriptionResponse | null,
      AxiosError<TFetchCurrentSubscription500Error>,
      TFetchCurrentSubscriptionResponse | null,
      ReturnType<typeof billingKeys.currentSubscription>
    >
  }
}) {
  const { options } = props || {}

  async function fetchCurrentSubscription() {
    const response =
      await minutApiHttpClient.get<TFetchCurrentSubscriptionResponse>(
        `${API_DEFAULT}/users/${userId}/current_subscription`,
        {
          params: {
            include_tax_exempt_prices: true,
          },
        }
      )
    const subscription = response.data
    return subscription
  }

  return useQuery({
    queryKey: billingKeys.currentSubscription(userId),
    queryFn: () => fetchCurrentSubscription(),
    ...options,
  })
}

export function useFetchPaymentMethod({
  paymentSourceId,
  options,
}: {
  paymentSourceId: string | undefined
  options?: UseQueryOptions<
    IPaymentSource,
    AxiosError,
    IPaymentSource,
    ReturnType<typeof billingKeys.paymentSource>
  >
}) {
  async function fetchPaymentMethod(): Promise<IPaymentSource> {
    const response = await minutApiHttpClient.get<IPaymentSourceResponse>(
      `${API_DEFAULT}/chargebee/payment_sources/${paymentSourceId}`
    )
    const paymentSourceResponse = response.data
    return paymentSourceResponse.payment_source
  }

  return useQuery(
    billingKeys.paymentSource(paymentSourceId ?? ""),
    () => fetchPaymentMethod(),
    {
      ...options,
      enabled:
        options?.enabled !== undefined
          ? !!paymentSourceId && options.enabled
          : !!paymentSourceId,
    }
  )
}

export function useDeletePaymentMethod() {
  const { invalidateCachedBillingInfo } = useBillingCache()
  const { removeCachedPaymentSource } = useBillingCache()

  async function deletePaymentMethod(
    paymentSourceId: string
  ): Promise<IPaymentSourceDeleteResponse> {
    const response =
      await minutApiHttpClient.delete<IPaymentSourceDeleteResponse>(
        `${API_DEFAULT}/chargebee/payment_sources/${paymentSourceId}/delete`
      )
    const result = response.data
    return result
  }

  return useMutation<
    IPaymentSourceDeleteResponse,
    AxiosError,
    {
      paymentSourceId: string
    }
  >(({ paymentSourceId }) => deletePaymentMethod(paymentSourceId), {
    onSuccess: (data: IPaymentSourceDeleteResponse) => {
      invalidateCachedBillingInfo(data.customer.id)
      removeCachedPaymentSource()
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostRemoveScheduledCancellation<ErrorType = any>() {
  const { invalidateCachedBillingInfo, updateCachedSubscription } =
    useBillingCache()

  async function postRemoveScheduledCancellation(
    subscriptionId: string
  ): Promise<ISubscriptionsResponse> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/subscriptions/${subscriptionId}/remove_scheduled_cancellation`
    )
    const result: ISubscriptionsResponse = response.data
    return result
  }

  return useMutation<
    ISubscriptionsResponse,
    AxiosError<ErrorType>,
    {
      subscriptionId: string
    }
  >(({ subscriptionId }) => postRemoveScheduledCancellation(subscriptionId), {
    onSuccess: (data: ISubscriptionsResponse) => {
      invalidateCachedBillingInfo(data.customer.id)
      updateCachedSubscription(() => data.subscription)
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function usePostCancelSubscription<ErrorType = any>() {
  const { updateCachedSubscription } = useBillingCache()

  async function postCancelSubscription(
    id: string,
    data: ISubscriptionsBody
  ): Promise<ISubscriptionsResponse> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/chargebee/subscriptions/${id}/cancel`,
      data
    )
    const result: ISubscriptionsResponse = response.data
    return result
  }

  return useMutation<
    ISubscriptionsResponse,
    AxiosError<ErrorType>,
    {
      subscriptionId: string
      subscriptionBody: ISubscriptionsBody
    }
  >(
    ({ subscriptionId, subscriptionBody }) =>
      postCancelSubscription(subscriptionId, subscriptionBody),
    {
      onSuccess: (data: ISubscriptionsResponse) => {
        updateCachedSubscription(() => data.subscription)
      },
    }
  )
}

export function usePostUpdateBillingInfo() {
  const { invalidateCachedBillingInfo } = useBillingCache()

  async function postUpdateBillingInfo({
    customerId,
    body,
  }: IPostUpdateBillingInfo): Promise<IPostUpdateBillingInfoResponse> {
    const response =
      await minutApiHttpClient.post<IPostUpdateBillingInfoResponse>(
        `${API_DEFAULT}/chargebee/customers/${customerId}/update_billing_info`,
        body
      )
    return response.data
  }

  return useMutation<
    IPostUpdateBillingInfoResponse,
    AxiosError,
    IPostUpdateBillingInfo
  >(postUpdateBillingInfo, {
    onSuccess: (data) => {
      invalidateCachedBillingInfo(data.customer.id)
    },
  })
}

export function usePutReplacePrimaryPaymentSource() {
  const { invalidateCachedBillingInfo, removeCachedPaymentSource } =
    useBillingCache()
  const { resetInvoices } = useInvoiceCache()

  async function putReplacePrimaryPaymentSource(
    data: ICreatePaymentSourceWithIntentBody
  ): Promise<ICustomer> {
    const response = await minutApiHttpClient.put<{ customer: ICustomer }>(
      `${API_DEFAULT}/payment/replace_primary_payment_source`,
      data
    )
    const result = response.data.customer
    return result
  }

  return useMutation<
    ICustomer,
    AxiosError,
    {
      body: ICreatePaymentSourceWithIntentBody
    }
  >(({ body }) => putReplacePrimaryPaymentSource(body), {
    onSuccess: (customer: ICustomer) => {
      invalidateCachedBillingInfo(customer.id)
      removeCachedPaymentSource()
      resetInvoices()
    },
  })
}

export function usePostCreatePaymentSourceWithIntent() {
  const { invalidateCachedBillingInfo } = useBillingCache()
  const { resetInvoices } = useInvoiceCache()

  async function postCreatePaymentSourceWithIntent(
    data: ICreatePaymentSourceWithIntentBody
  ): Promise<ICustomer> {
    const response = await minutApiHttpClient.post<{ customer: ICustomer }>(
      `${API_DEFAULT}/chargebee/payment_sources/create_using_payment_intent`,
      data
    )
    const result = response.data.customer
    return result
  }

  return useMutation<
    ICustomer,
    AxiosError,
    {
      body: ICreatePaymentSourceWithIntentBody
    }
  >(({ body }) => postCreatePaymentSourceWithIntent(body), {
    onSuccess: (customer: ICustomer) => {
      invalidateCachedBillingInfo(customer.id)
      resetInvoices()
    },
  })
}

export function usePostSetupIntent() {
  async function postSetupIntent(body: TPostSetupIntentRequestBody) {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/payment/setup_intent`,
      body
    )
    return response.data
  }

  return useMutation<
    TPostSetupIntentResponse,
    AxiosError,
    TPostSetupIntentRequestBody
  >({
    mutationFn: postSetupIntent,
  })
}

// This is the hook that will be used but it's internals and interface will change once the API is deployed
export function useFetchBillingHistory({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter?: TBillingHistoryQueryParams
  options?: UseQueryOptions<
    TBillingHistoryResponse,
    AxiosError,
    TBillingHistoryResponse,
    ReturnType<typeof billingKeys.billingHistory>
  >
}) {
  async function fetchBillingHistory() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/organizations/${orgId}/billing_events`,
      {
        params: {
          limit: filter?.limit,
          offset: filter?.offset,
          search_billing_activity_filter:
            filter?.search_billing_activity_filter,
        },
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: billingKeys.billingHistory(orgId, filter),
    queryFn: fetchBillingHistory,
    ...options,
  })
}

export function useFetchPlanRenewalEstimate({
  userId,
  options,
}: {
  userId: string
  options?: UseQueryOptions<
    TPlanRenewalEstimateResponse,
    AxiosError,
    TPlanRenewalEstimateResponse,
    ReturnType<typeof billingKeys.planRenewalEstimate>
  >
}) {
  async function fetchPlanRenewalEstimate() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/subscriptions/estimate/renewal_amount`
    )

    return response.data
  }

  return useQuery({
    queryKey: billingKeys.planRenewalEstimate(userId),
    queryFn: fetchPlanRenewalEstimate,
    ...options,
  })
}

export function useFetchPlanRenewalEstimateBreakdown<
  TQueryFnData = TPlanRenewalEstimateBreakdownResponse,
>({
  userId,
  filter,
  options,
}: {
  userId: string
  filter?: TPlanRenewalEstimateBreakdownFilter
  options?: UseQueryOptions<
    TPlanRenewalEstimateBreakdownResponse,
    AxiosError,
    TQueryFnData,
    ReturnType<typeof billingKeys.planRenewalEstimateBreakdown>
  >
}) {
  async function fetchPlanRenewalEstimateBreakdown() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/subscriptions/estimate/renewal_breakdown`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: billingKeys.planRenewalEstimateBreakdown(userId, filter),
    queryFn: fetchPlanRenewalEstimateBreakdown,
    ...options,
  })
}

export function useFetchBillingEvent({
  orgId,
  eventId,
}: {
  orgId: string
  eventId: string
}) {
  return useMinutQuery<"/organizations/{organization_id}/billing_events/{billing_event_id}">(
    {
      queryKey: billingKeys.billingEvent(eventId),
      apiPath:
        "/organizations/{organization_id}/billing_events/{billing_event_id}",
      queryPath: `/organizations/${orgId}/billing_events/${eventId}`,
    }
  )
}

export function usePostFetchCreditNotePdf() {
  async function postFetchCreditNotePdf({
    creditNoteId,
  }: {
    creditNoteId: string
  }): Promise<string> {
    const response = await minutApiHttpClient.post<TFetchCreditNotePdfResponse>(
      `${API_DEFAULT}/chargebee/credit_notes/${creditNoteId}/pdf`
    )
    return response.data.download?.download_url ?? ""
  }

  return useMutation<
    string,
    AxiosError,
    {
      creditNoteId: string
    }
  >({
    mutationFn: postFetchCreditNotePdf,
  })
}
