import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { TVirtualDevice } from "src/data/devices/types/deviceTypes"
import { IVirtualEnvironmentRequest } from "src/data/homes/queries/virtualDeviceQueries"

export function AlmostFreezingToggle({
  simulatedDevice,
  onSave,
}: {
  simulatedDevice: TVirtualDevice
  onSave: (
    eventType: IVirtualEnvironmentRequest["event_type"]
  ) => Promise<boolean>
}) {
  return (
    <SettingToggle
      title="Almost freezing"
      titleProps={{ variant: "body" }}
      value={!!simulatedDevice.virtual_environment?.is_almost_freezing}
      disabled={simulatedDevice.offline || !!simulatedDevice.placed_outdoors}
      onSave={async (checked) => {
        const result = await onSave(
          checked ? "almost_freezing" : "almost_freezing_cleared"
        )
        return { isSuccess: result }
      }}
    />
  )
}
