import { useMemo } from "react"

import { getCountryDisplayName } from "src/data/countries/countryUtil"
import { MSelect } from "src/ui/MSelect/MSelect"

export function ParadiseCountrySelector({
  selectedCountryCode,
  onSelectCountryCode,
  countryCodes,
  name,
  label,
  required,
  requiredIndicator,
}: {
  selectedCountryCode: string
  onSelectCountryCode: (country: string) => void
  countryCodes: string[]
  name: string
  label?: string
  required?: boolean
  requiredIndicator?: boolean
}) {
  const options = useMemo(() => {
    const parsedCountries = countryCodes.map((code) => ({
      value: code,
      label:
        getCountryDisplayName({
          countryCode: code,
          locale: "en",
        }) ?? "",
    }))

    return parsedCountries.sort((a, b) => a.label.localeCompare(b.label)) || []
  }, [countryCodes])

  return (
    <MSelect
      name={name}
      label={label}
      value={selectedCountryCode}
      onChange={onSelectCountryCode}
      options={[
        {
          label: "",
          value: "",
          hidden: true,
        },
        ...options,
      ]}
      required={required}
      requiredIndicator={requiredIndicator}
    />
  )
}
