import styled from "styled-components"

import { mColors } from "@minuthq/meatball-ui-react"

import { ParadiseCreateOrderShippableProducts } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/ParadiseCreateOrderShippableProducts"
import { ParadiseCreateOrderShippingAddress } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/ParadiseCreateOrderShippingAddress"
import { ParadiseCreateOrderShippingMethod } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/ParadiseCreateOrderShippingMethod"
import { ParadiseCreateOrderSummary } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/ParadiseCreateOrderSummary"
import { useParadiseCreateOrder } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/useParadiseCreateOrder"
import { ParadiseUserCombobox } from "src/components/Paradise/ParadiseUserCombobox"
import { ParadiseLayout } from "src/components/Paradise/sharedStyles"
import { MButton } from "src/ui/Button/MButton"
import { MDivider } from "src/ui/Divider/Divider"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const FORM_ID = "paradise-create-order-form"

export function ParadiseCreateOrder() {
  const {
    selectedUser,
    setSelectedUser,
    userHasSubscription,
    currencyCode,
    cart,
    setCart,
    resetCart,
    shippingAddress,
    updateShippingAddress,
    createOrder,
    updateCart,
    totalCartQuantity,
    validationErrorKey,
    createOrderLoading,
    createOrderError,
  } = useParadiseCreateOrder()

  return (
    <div>
      <TopSection>
        <MText variant="heading2">Create order</MText>
      </TopSection>
      <Main>
        {createOrderError && (
          <ErrorBannerWrapper>
            <MBanner type="error" title={createOrderError.message} fullWidth>
              Error key: {createOrderError.response?.data.error_key}
            </MBanner>
          </ErrorBannerWrapper>
        )}
        <Content>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              createOrder()
            }}
            id={FORM_ID}
          >
            <div>
              <MText variant="heading3">User</MText>
              <MDivider />
              <ParadiseUserCombobox
                selectedUser={selectedUser}
                onChange={(user) => {
                  setSelectedUser(user)
                  resetCart()
                }}
                required
              />

              {!!selectedUser && !userHasSubscription && (
                <MText color="warning">
                  The selected user does not have a subscription. Please add a
                  subscription or select a different user
                </MText>
              )}
            </div>

            <Fieldset disabled={!userHasSubscription}>
              <MText variant="heading3">Shippable products</MText>
              <MDivider />

              {validationErrorKey === "empty_cart" && (
                <MBanner type="warning" fullWidth>
                  The cart is empty. Please add products to the cart before
                  creating an order
                </MBanner>
              )}

              <ParadiseCreateOrderShippableProducts
                cart={cart}
                updateShippableProducts={(products) => {
                  updateCart({
                    ...cart,
                    shippableProducts: products,
                    shippingMethodId: null,
                  })
                }}
                currency={currencyCode}
              />
            </Fieldset>

            <Fieldset disabled={!userHasSubscription}>
              <MText variant="heading3">Shipping address</MText>
              <MDivider />

              <ParadiseCreateOrderShippingAddress
                shippingAddress={shippingAddress}
                updateShippingAddress={updateShippingAddress}
                currencyCode={currencyCode}
              />
            </Fieldset>

            <Fieldset disabled={!userHasSubscription}>
              <MText variant="heading3">Shipping method</MText>
              <MDivider />

              {validationErrorKey === "no_shipping_method" && (
                <MBanner
                  type="warning"
                  fullWidth
                  style={{ marginBottom: spacing.M }}
                >
                  Please select a shipping method
                </MBanner>
              )}

              {totalCartQuantity > 0 ? (
                <ParadiseCreateOrderShippingMethod
                  cart={cart}
                  shippingCountry={shippingAddress.country}
                  updateShippingMethod={(shippingMethodId) =>
                    setCart({ ...cart, shippingMethodId })
                  }
                  currency={currencyCode}
                />
              ) : (
                <MBanner type="info" fullWidth>
                  Please add products to the cart before selecting a shipping
                  method
                </MBanner>
              )}
            </Fieldset>
          </Form>
          <RightWrapper>
            <MCard border boxShadow={false}>
              <ParadiseCreateOrderSummary
                cart={cart}
                user={selectedUser}
                currencyCode={currencyCode}
              />
            </MCard>

            <MButton form={FORM_ID} type="submit" loading={createOrderLoading}>
              Create order
            </MButton>
          </RightWrapper>
        </Content>
      </Main>
    </div>
  )
}

const TopSection = styled(ParadiseLayout)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${mColors.neutral};
  border-bottom: 1px solid ${mColors.divider};

  margin-bottom: ${spacing.M};
`

const Main = styled(ParadiseLayout)`
  max-width: 1400px;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: start;
  gap: ${spacing.L};
`

const ErrorBannerWrapper = styled.div`
  margin-bottom: ${spacing.L};
`

const Form = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }
`

const RightWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
