import { chargebeeAddonUrl } from "src/constants/hrefs"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { ExternalLink } from "src/ui/Link/ExternalLink"

export function ParadiseShippableProductActions({
  shippableProduct,
}: {
  shippableProduct: TParadiseShippableProduct
}) {
  return (
    <ExternalLink href={chargebeeAddonUrl(shippableProduct.product_id)}>
      Chargebee
    </ExternalLink>
  )
}
