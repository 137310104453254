import { useState } from "react"

import { components } from "@minuthq/minut-api-types/schema"

import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { usePostParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderQueries"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"

export type TParadiseCreateOrderCart = {
  shippableProducts: {
    product: TParadiseShippableProduct
    quantity: number
  }[]
  shippingMethodId: string | null
}

export type TParadiseCreateOrderShippingAddressForm = {
  first_name: string
  last_name: string
  company?: string
  phone: string
  line1: string
  line2?: string
  city: string
  state_code?: string
  zip?: string
  country: string
}

type ErrorKey = "empty_cart" | "no_shipping_method"

const initialCart: TParadiseCreateOrderCart = {
  shippableProducts: [],
  shippingMethodId: null,
}

const initialShippingAddress: TParadiseCreateOrderShippingAddressForm = {
  first_name: "",
  last_name: "",
  company: "",
  phone: "",
  country: "",
  state_code: "",
  city: "",
  zip: "",
  line1: "",
  line2: "",
}

export function useParadiseCreateOrder() {
  const { navigate } = useRouter()

  const [selectedUser, setSelectedUser] = useState<TParadiseUser | null>(null)
  const [cart, setCart] = useState(initialCart)
  const [shippingAddress, setShippingAddress] = useState(initialShippingAddress)
  const [validationErrorKey, setValidationErrorKey] = useState<ErrorKey | null>(
    null
  )

  const postParadiseOrder = usePostParadiseOrder()

  const fetchUserSubscription = useFetchCurrentSubscription({
    userId: selectedUser?.user_id ?? "",
    props: {
      options: {
        enabled: !!selectedUser?.user_id,
      },
    },
  })

  const userHasSubscription = !!fetchUserSubscription.data
  const currencyCode = fetchUserSubscription.data?.currency_code ?? "USD"

  const totalCartQuantity = cart.shippableProducts.reduce(
    (acc, product) => acc + product.quantity,
    0
  )

  function updateCart(cart: TParadiseCreateOrderCart) {
    setCart(cart)
    resetValidationError()
  }

  function resetCart() {
    setCart(initialCart)
  }

  function updateShippingAddress(
    shippingAddress: Partial<TParadiseCreateOrderShippingAddressForm>
  ) {
    setShippingAddress((prev) => ({
      ...prev,
      ...shippingAddress,
    }))
  }

  function createOrder() {
    if (!userHasSubscription) {
      return
    }

    const cartIsEmpty = totalCartQuantity === 0

    if (cartIsEmpty) {
      setValidationErrorKey("empty_cart")
      return
    }

    if (!cart.shippingMethodId) {
      setValidationErrorKey("no_shipping_method")
      return
    }

    postParadiseOrder.mutate(
      {
        user_id: selectedUser?.user_id ?? "",
        shippable_products: cart.shippableProducts.map((product) => ({
          id: product.product.product_id,
          quantity: product.quantity,
        })),
        shipping_method_id: cart.shippingMethodId,
        // The country in the form is not the same type as the country in the API.
        // But we do use a select and render the countries and their codes from the BE so we can be sure that the country is valid.
        shipping_address:
          shippingAddress as components["schemas"]["CheckoutShippingAddress"],
      },
      {
        onSuccess: (data) => {
          navigate(Routes.ParadiseOrder.location(data.order_number).pathname)
        },
      }
    )
  }

  function resetValidationError() {
    setValidationErrorKey(null)
  }

  return {
    selectedUser,
    setSelectedUser,
    cart,
    setCart,
    resetCart,
    shippingAddress,
    updateShippingAddress,
    createOrder,
    userHasSubscription,
    currencyCode,
    validationErrorKey,
    updateCart,
    createOrderError: postParadiseOrder.error,
    createOrderLoading: postParadiseOrder.isLoading,
    totalCartQuantity,
  }
}
