import { useFormik } from "formik"

import {
  useFetchProducts,
  useFetchShippingDestinations,
} from "src/data/ecommerce/ecommerceQueries"
import { usePostParadiseQuote } from "src/data/paradise/paradiseQuotes/paradiseQuoteQueries"

type TParadiseCreateQuoteForm = {
  customerName: string
  shippingCountry: string
  expiresAt: Date | null
  cart: {
    planId: string
    prepaidHomeQuantity: number
    planAssociatedShippableProducts: {
      productId: string
      quantity: number
    }[]
    additionalShippableProducts: {
      productId: string
      quantity: number
    }[]
    discountCode: string
  }
}

export const initialParadiseCreateQuoteValues: TParadiseCreateQuoteForm = {
  customerName: "",
  shippingCountry: "",
  expiresAt: null,
  cart: {
    planId: "",
    prepaidHomeQuantity: 0,
    planAssociatedShippableProducts: [],
    additionalShippableProducts: [],
    discountCode: "",
  },
}
export function useParadiseCreateQuote() {
  const form = useFormik<TParadiseCreateQuoteForm>({
    initialValues: initialParadiseCreateQuoteValues,
    onSubmit: createQuote,
  })

  const postParadiseQuote = usePostParadiseQuote()

  const fetchShippingDestinations = useFetchShippingDestinations()

  const selectedDestination = fetchShippingDestinations.data?.find(
    (destination) => destination.country_code === form.values.shippingCountry
  )
  const fetchProducts = useFetchProducts({
    filter: {
      currency_code: selectedDestination?.currency_code,
    },
    options: {
      enabled: !!selectedDestination,
    },
  })

  const selectedProduct = fetchProducts.data?.products.find(
    (product) => product.plan_id === form.values.cart.planId
  )
  const selectedPlanAssociatedShippableProducts =
    selectedProduct?.shippable_products.filter(
      (product) => product.type === "plan_associated"
    )
  const selectedAdditionalShippableProducts =
    selectedProduct?.shippable_products.filter(
      (product) => product.type === "additional"
    )

  function setValues(values: Partial<TParadiseCreateQuoteForm>) {
    form.setValues((prev) => ({
      ...prev,
      ...values,
    }))
  }

  function createQuote(values: TParadiseCreateQuoteForm) {
    postParadiseQuote.mutate({
      customer_name: values.customerName,
      expires_at: values.expiresAt?.toISOString() || "",
      cart: {
        plan_id: values.cart.planId,
        prepaid_home_quantity: values.cart.prepaidHomeQuantity,
        plan_associated_shippable_products:
          values.cart.planAssociatedShippableProducts.map((product) => ({
            product_id: product.productId,
            quantity: product.quantity,
          })),
        additional_shippable_products:
          values.cart.additionalShippableProducts.map((product) => ({
            product_id: product.productId,
            quantity: product.quantity,
          })),
        discount_code: values.cart.discountCode,
      },
    })
  }

  return {
    form: {
      ...form,
      setValues,
    },
    shippingDestinations: fetchShippingDestinations.data,
    isLoadingShippingDestinations: fetchShippingDestinations.isLoading,
    products: fetchProducts.data?.products,
    isInitialLoadingProducts: fetchProducts.isInitialLoading,
    selectedProduct,
    selectedPlanAssociatedShippableProducts,
    selectedAdditionalShippableProducts,
    postParadiseQuoteIsLoading: postParadiseQuote.isLoading,
    postParadiseQuoteData: postParadiseQuote.data,
    postParadiseQuoteError: postParadiseQuote.error,
    resetPostParadiseQuote: postParadiseQuote.reset,
  }
}
