import { Fragment, useEffect, useMemo, useState } from "react"

import { mColors } from "src/ui/colors"
import {
  ColumnHeaderLabelButton,
  ColumnHeaderLabelWrapper,
  ColumnHeaderWrapper,
  IconWrapper,
  SortingIconsButton,
} from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import ChevronIcon from "src/ui/icons/chevron-down.svg"
import ChevronDownIcon from "src/ui/icons/chevron-down.svg"
import { MPopover } from "src/ui/MPopover/MPopover"
import { logger } from "src/utils/logger"
import { localStorageFactory } from "src/utils/storageUtil"
import { Maybe, SVGProps } from "src/utils/tsUtil"

export type TableColumn<T, V extends string = string> = {
  value: V
  label: string
  renderLabel?: (label: string) => React.ReactNode
  sortButtonSpacing?: "compact" | "grow"
  hidden?: boolean
  disabled?: boolean
  columnWidth?: number | string
  enableSort?: boolean
  renderCell: (data: T) => React.ReactNode
  disableClickPropagation?: boolean
  popoverContent?: React.ReactNode
  popoverIcon?: React.FC<SVGProps>
}

export type TTableSort = {
  id: string
  order: "desc" | "asc"
}

export function useTableColumns<T>({
  columns,
  data,
  sort,
  onSortChange,
  options,
}: {
  columns: TableColumn<T>[]
  data?: T[]
  sort?: Maybe<TTableSort>
  onSortChange?: (sort: TTableSort) => void
  options?: {
    localStorageKey?: string
    disableColumnHiding?: boolean
  }
}) {
  const localStorage = localStorageFactory<{ [key: string]: boolean }>({
    key: options?.localStorageKey ?? "",
  })

  const [hiddenColumns, setHiddenColumns] = useState<{
    [key: string]: boolean
  }>(() => {
    if (!options?.disableColumnHiding && options?.localStorageKey) {
      const columnsInStorage = localStorage.get()

      if (columnsInStorage) {
        return columnsInStorage
      }
    }

    return Object.fromEntries(columns.map((c) => [c.value, !!c.hidden]))
  })

  useEffect(() => {
    localStorage.set(hiddenColumns)
  }, [hiddenColumns, localStorage])

  const interactiveColumns = columns.filter((c) => !c.disabled)
  const visibleColumns = columns.filter(
    (c) => !c.hidden && !hiddenColumns[c.value]
  )
  const interactiveVisibleColumns = interactiveColumns.filter(
    (c) => !c.hidden && !hiddenColumns[c.value]
  )

  const headerElements = visibleColumns.map((c) => {
    const label = c.renderLabel?.(c.label) ?? c.label

    const isSorted = sort && sort.id === c.value
    const hasSortEnabled = c.enableSort && onSortChange

    const hasPopover = !!c.popoverContent
    const PopoverIcon = c.popoverIcon ?? ChevronDownIcon

    return (
      <ColumnHeaderWrapper key={c.value} $justify={c.sortButtonSpacing}>
        {hasPopover ? (
          <MPopover
            trigger={
              <ColumnHeaderLabelButton>
                {label}
                <PopoverIcon height={18} color={mColors.textSecondary} />
              </ColumnHeaderLabelButton>
            }
            placement="bottom-start"
            offset={{
              mainAxis: 10,
              crossAxis: -4,
            }}
            disableFocusListener
          >
            {c.popoverContent}
          </MPopover>
        ) : (
          <ColumnHeaderLabelWrapper>{label}</ColumnHeaderLabelWrapper>
        )}
        {hasSortEnabled && (
          <SortingIconsButton
            onClick={() =>
              onSortChange?.({
                id: c.value,
                order: sort?.order === "desc" ? "asc" : "desc",
              })
            }
          >
            {isSorted ? (
              <IconWrapper $up={sort?.order === "asc"}>
                <ChevronIcon width={10} />
              </IconWrapper>
            ) : (
              <>
                <IconWrapper $up>
                  <ChevronIcon width={8} />
                </IconWrapper>
                <IconWrapper>
                  <ChevronIcon width={8} />
                </IconWrapper>
              </>
            )}
          </SortingIconsButton>
        )}
      </ColumnHeaderWrapper>
    )
  })

  const rows = useMemo(() => {
    if (data) {
      return data.flatMap((d, i) => (
        <Fragment key={i}>
          {visibleColumns.map((c) =>
            c.disableClickPropagation ? (
              <div key={c.value} onClick={(e) => e.stopPropagation()}>
                {c.renderCell(d)}
              </div>
            ) : (
              <Fragment key={c.value}>{c.renderCell(d)}</Fragment>
            )
          )}
        </Fragment>
      ))
    }
  }, [data, visibleColumns])

  const templateColumns = useMemo(() => {
    const strArr = visibleColumns.map((c, i) => {
      // Make sure the right most column expand to the right, if for example is set to "min-contents"
      if (i === visibleColumns.length - 1) {
        return "auto"
      }

      if (c.columnWidth) {
        if (typeof c.columnWidth === "string") {
          return `${c.columnWidth}`
        }

        return `${c.columnWidth}px`
      }

      return "minmax(300px, 1fr)"
    })

    return strArr.join(" ")
  }, [visibleColumns])

  function updateColumnVisibility(columnValue: string, hidden: boolean) {
    if (options?.disableColumnHiding) {
      logger.warn("[useTableColumns] Column hising is disabled")
      return
    }

    setHiddenColumns((prev) => {
      return {
        ...prev,
        [columnValue]: hidden,
      }
    })
  }

  return {
    visibleColumns,
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  }
}
