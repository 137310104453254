import styled from "styled-components"

import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { TParadiseCombinedQuoteWithUsers } from "src/components/Paradise/ParadiseQuotes/paradiseQuoteTypes"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { Routes } from "src/router/routes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseQuoteOverview({
  quote,
}: {
  quote: TParadiseCombinedQuoteWithUsers
}) {
  return (
    <Box>
      <ParadiseOverviewExpandable rawJson={quote}>
        <ParadiseSettingStatic
          title="Quote id"
          value={
            <CopyWrapper>
              <MText variant="bodyS">{quote.id}</MText>
              <CopyText value={quote.id} copiedText="Copied!" width={18} />
            </CopyWrapper>
          }
        />

        <ParadiseSettingStatic
          title="Customer name"
          value={<MText variant="bodyS">{quote.customer_name}</MText>}
        />

        <ParadiseSettingStatic
          title="Purchasing customer"
          value={
            quote.customer ? (
              <div>
                <InternalLink
                  to={Routes.ParadiseUser.location(quote.customer.id)}
                >
                  {quote.customer.email}
                </InternalLink>
                <CopyText
                  value={quote.customer.email}
                  width={16}
                  copiedText="Copied!"
                />
              </div>
            ) : (
              <div>{quote.customer_id || "-"}</div>
            )
          }
        />

        <ParadiseSettingStatic
          title="Order number"
          value={
            quote.order_number ? (
              <div>
                <InternalLink
                  to={Routes.ParadiseOrder.location(quote.order_number)}
                >
                  {quote.order_number}
                </InternalLink>
                <CopyText
                  value={quote.order_number}
                  width={16}
                  copiedText="Copied!"
                />
              </div>
            ) : (
              <div>{quote.order_number || "-"}</div>
            )
          }
        />

        <ParadiseSettingStatic
          title="Sales rep"
          value={
            quote.salesRep ? (
              <div>
                <InternalLink
                  to={Routes.ParadiseUser.location(quote.salesRep.id)}
                >
                  {quote.salesRep.email}
                </InternalLink>
                <CopyText
                  value={quote.salesRep.email}
                  width={16}
                  copiedText="Copied!"
                />
              </div>
            ) : (
              <div>{quote.sales_rep_user_id}</div>
            )
          }
        />

        <ParadiseSettingStatic
          title="Created at"
          value={formatUtcDate({ date: quote.created_at })}
        />

        <ParadiseSettingStatic
          title="Expires at"
          value={formatUtcDate({ date: quote.expires_at })}
        />

        <ParadiseSettingStatic
          title="Expires at"
          value={formatUtcDate({ date: quote.updated_at })}
        />
      </ParadiseOverviewExpandable>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
`
