import styled from "styled-components"

import { mColors, spacing } from "@minuthq/meatball-ui-react"

import { ParadiseShippingMethodZonePrice } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethodDetails/ParadiseShippingMethodZonePrice"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { getCountryDisplayName } from "src/data/countries/countryUtil"
import { prettifyApiFieldValue } from "src/data/orders/orderUtils"
import { TParadiseShippingZone } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function ParadiseShippingZone({
  zone,
  currency,
}: {
  zone: TParadiseShippingZone
  currency: TSubscriptionCurrencyCode
}) {
  return (
    <MCard border boxShadow={false}>
      <CountriesWrapper>
        {zone.countries.map((country) => (
          <MTooltip
            key={country}
            title={getCountryDisplayName({
              countryCode: country,
              locale: "en",
            })}
          >
            <CountryLabel variant="bodyS">{country}</CountryLabel>
          </MTooltip>
        ))}
      </CountriesWrapper>

      <TextWrapper>
        <MText variant="bodyS" color="secondary">
          Estimated delivery:
        </MText>

        <MText variant="bodyS" color="secondary">
          {formatEstimatedDelivery({
            min: zone.estimated_delivery_time.min,
            max: zone.estimated_delivery_time.max,
            unit: zone.estimated_delivery_time.unit,
          })}
        </MText>

        <MText variant="bodyS" color="secondary">
          Warehouse:
        </MText>

        <MText variant="bodyS" color="secondary">
          {prettifyApiFieldValue(zone.warehouse)}
        </MText>

        <MText variant="bodyS" color="secondary">
          Warehouse shipping method:
        </MText>

        <MText variant="bodyS" color="secondary">
          {zone.warehouse_shipping_method}
        </MText>
      </TextWrapper>

      <ParadiseShippingMethodZonePrice
        prices={zone.price}
        currency={currency}
      />
    </MCard>
  )
}

function formatEstimatedDelivery({
  min,
  max,
  unit,
}: {
  min: number
  max: number
  unit: string
}) {
  const translatedUnit =
    unit === "day" ? (max === 1 ? "day" : "days") : max === 1 ? "week" : "weeks"

  if (min === max) {
    return `${min} ${translatedUnit}`
  }

  return `${min}-${max} ${translatedUnit}`
}

const CountriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.S};
`

const CountryLabel = styled(MText)`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: ${mColors.textSecondary};
  text-underline-offset: 2px;
`

const TextWrapper = styled.div`
  margin-top: ${spacing.M};

  display: grid;
  grid-template-columns: max-content auto;
  gap: ${spacing.XS};
`
