import countries from "src/data/countries/countries.json"
import {
  TParadiseOrder,
  TParadiseOrdersQueryParams,
} from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { arrayIncludes } from "src/utils/genericUtil"

export const validParadiseOrdersSortIds: NonNullable<
  TParadiseOrdersQueryParams["sort_by"]
>[] = ["created_at", "status"]

export function parseParadiseOrdersSortField(
  sortValue: string | undefined
): sortValue is TParadiseOrdersQueryParams["sort_by"] {
  if (!sortValue || !arrayIncludes(validParadiseOrdersSortIds, sortValue)) {
    return false
  }

  return true
}

export function parseParadiseCountryCodesField(
  countryCodes?: string[] | null
): TParadiseOrdersQueryParams["country_codes"] {
  if (!countryCodes) {
    return undefined
  }

  const availableCountryCodes = countries.map((country) => country.code)

  return countryCodes.filter((countryCode) =>
    arrayIncludes(availableCountryCodes, countryCode)
  ) as TParadiseOrdersQueryParams["country_codes"]
}

export function getWMSLink(warehouse: TParadiseOrder["assigned_warehouse"]) {
  switch (warehouse) {
    case "postpac":
      return "https://ecom.ongoingsystems.se/ecom"
    case "link_logistics":
      return "https://linklogisticsus.ongoingsystems.se/linklogisticsus"
    case "byrd":
      return "https://app.getbyrd.com/"
    default:
      return ""
  }
}

export function isOrderEditable(
  orderStatus: TParadiseOrder["status"]
): boolean {
  return ["new", "awaiting_shipment"].includes(orderStatus)
}
