import { useEffect, useState } from "react"

import { useDebouncedCallback } from "use-debounce"

import { MSearchField } from "src/ui/MSearchField/MSearchField"

const DEFAULT_DELAY = 200 // ms

type TSearchFilterProps = {
  placeholder?: string
  initialValue?: string
  onChange: (value: string) => void
  delay?: number
  disabled?: boolean
  isLoading?: boolean
}

/**
 * Filter component that reports a changed value after a delay; useful for
 * debouncing local search results.
 */
export function SearchFilter({
  placeholder = "",
  initialValue = "",
  onChange,
  delay = DEFAULT_DELAY,
  disabled,
  isLoading,
}: TSearchFilterProps) {
  const [text, setText] = useState(initialValue)
  useEffect(() => {
    // This effect is to let the parent control display text
    setText(initialValue)
  }, [initialValue])

  const debouncedCallback = useDebouncedCallback((s: string) => {
    onChange(s)
  }, delay)

  const setValue = (s: string) => {
    setText(s)
    debouncedCallback(s)
  }

  const clearValue = () => {
    // We call onChange('') here to instantly clear the search. However, in
    // order to not run into race conditions with debounce, we also call the
    // debounced function, so that the 'clear' action is queued up properly.
    onChange("")
    setValue("")
  }

  return (
    <MSearchField
      placeholder={placeholder}
      value={text}
      onChange={setValue}
      onClear={clearValue}
      disabled={disabled}
      isLoading={isLoading}
    />
  )
}
