import { useCallback, useState } from "react"

import {
  getStoredAuthorization,
  getUrlAuth,
  setStoredAuthorization,
  setStoredImpersonation,
} from "src/data/auth/auth"
import { clearAuthStorage } from "src/data/auth/authStorage"
import { TMaybeAuthorization } from "src/data/user/user"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { debug, logger } from "src/utils/logger"
import {
  setApiClientAccessToken,
  setTokenExpiredInterceptor,
} from "src/utils/minutApiHttpClient"

export interface IAuthState {
  authorization: TMaybeAuthorization
  setAuthorization(a: TMaybeAuthorization): void
}

/** This hook mimics the state behavior that was in redux, where we previously
 * kept track of the sign in state */
export function useAuthState(): IAuthState {
  const [authorization, _setAuthorization] = useState<TMaybeAuthorization>(
    () => {
      const auth = initAuth()
      if (auth) {
        setApiClientAccessToken(auth.access_token)
      }
      return auth
    }
  )

  useEffectOnce(() => {
    setTokenExpiredInterceptor({
      onFailedRefresh: () => {
        logger.log("Refresh failed, you need to logout and try again")
        // Since authenticated routes check if auth is available, and redirect
        // to login otherwise, just clearing the data here should be enough.
        clearAuthStorage()
        _setAuthorization(null)

        // Ideally we'd disable the api accesss token here as well, but it
        // causes a bunch of Axios errors on token timeout.
        // setApiClientAccessToken(null)
      },
    })
  })

  const setAuthorization = useCallback((auth: TMaybeAuthorization) => {
    if (auth == null) {
      debug.log(`clearing auth state`)
      clearAuthStorage()
      _setAuthorization(null)
      setApiClientAccessToken(null)
      return
    }
    debug.log(`Setting auth state:`, auth)
    setStoredAuthorization(auth)
    _setAuthorization(auth)
    setApiClientAccessToken(auth.access_token)
  }, [])

  return {
    authorization,
    setAuthorization,
  }
}

function initAuth() {
  // If there's auth data in the URL we will prefer that above all else
  const urlAuth = getUrlAuth()
  if (urlAuth) {
    setStoredImpersonation(urlAuth)
    return urlAuth
  }

  // If there's auth data stored in localStorage we will use that
  const storedAuth = getStoredAuthorization()
  if (storedAuth) {
    setStoredAuthorization(storedAuth)
    return storedAuth
  }

  // Nothing found, user will need to login
  return null
}
