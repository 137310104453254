import { useState } from "react"

import { SmokingDetectionIcon } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionIcon"
import { SmokingDetectionPopover } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionPopover"
import { ISmokingDetection, THome } from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import {
  MonitoringButton,
  OnOffStatusLabel,
} from "src/ui/MonitoringButton/MonitoringButton"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function SmokingDetectionButton({
  homeId,
  smokingDetection,
  violations,
  hasAccess,
  hideStatus,
}: {
  homeId: THome["home_id"]
  smokingDetection: THome["smoking_detection"]
  violations: THome["violations"]
  hasAccess: boolean
  hideStatus?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isEnabled = smokingDetection?.state === "enabled"

  function handleClickToggle(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  const tooltipText = hasAccess
    ? getTooltipText({
        state: smokingDetection?.state,
        status: smokingDetection?.status,
        t,
      })
    : t(langKeys.not_enough_permissions)

  return (
    <div>
      <MTooltip title={tooltipText} placement="bottom">
        <div>
          <MonitoringButton
            label={<OnOffStatusLabel isOn={isEnabled} />}
            labelType={isEnabled ? "good" : "default"}
            onClick={handleClickToggle}
            icon={
              <SmokingDetectionIcon
                state={smokingDetection?.state}
                status={smokingDetection?.status}
                iconProps={{ height: "30", width: "30" }}
              />
            }
            hideStatus={hideStatus}
            disabled={!hasAccess}
            row={true}
          />
        </div>
      </MTooltip>

      {open && (
        <SmokingDetectionPopover
          homeId={homeId}
          anchorEl={anchorEl}
          violations={violations}
          state={smokingDetection?.state}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

function getTooltipText({
  state,
  status,
  t,
}: {
  state: ISmokingDetection["state"] | undefined
  status?: ISmokingDetection["status"]
  t: TTranslateFunction
}) {
  if (!state || state === "disabled") {
    return t(langKeys.smoking_detection_status_off)
  }

  if (state === "enabled" && status === "alerting") {
    return t(langKeys.smoking_detection_status_ongoing)
  }

  return t(langKeys.smoking_detection_status_on)
}
