import { DeviceReadingType } from "src/data/devices/types/deviceTypes"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

export const PARADISE_PATH = "/paradise"

const ParadiseDashboard = createBasicLocation(PARADISE_PATH)

const ParadiseHomes = createBasicLocation(`${PARADISE_PATH}/homes`)

const ParadiseHome: ILocation<string> = {
  location(homeId) {
    return {
      pathname: `${ParadiseHomes.location().pathname}/${homeId}`,
    }
  },
}

const ParadiseUsers = createBasicLocation(`${PARADISE_PATH}/users`)

const ParadiseUser: ILocation<string> = {
  location(userId) {
    return {
      pathname: `${ParadiseUsers.location().pathname}/${userId}`,
    }
  },
}

const ParadiseOrganizations = createBasicLocation(
  `${PARADISE_PATH}/organizations`
)

const ParadiseOrganization: ILocation<string> = {
  location(orgId) {
    return {
      pathname: `${ParadiseOrganizations.location().pathname}/${orgId}`,
    }
  },
}
const ParadiseDevices = createBasicLocation(`${PARADISE_PATH}/devices`)

const ParadiseDevice: ILocation<string> = {
  location(deviceId) {
    return {
      pathname: `${ParadiseDevices.location().pathname}/${deviceId}`,
    }
  },
}

const ParadiseDeviceReadings: ILocation<{
  deviceId: string
  type: DeviceReadingType | ":type"
}> = {
  location({ deviceId, type }) {
    return {
      pathname: `${ParadiseDevice.location(deviceId).pathname}/${type}`,
    }
  },
}

const ParadiseDeviceQuickInsights: ILocation<{
  deviceId: string
}> = {
  location({ deviceId }) {
    return {
      pathname: `${ParadiseDevice.location(deviceId).pathname}/quick_insights`,
    }
  },
}

const ParadiseDeviceGraphViews: ILocation<{
  deviceId: string
}> = {
  location({ deviceId }) {
    return {
      pathname: `${ParadiseDevice.location(deviceId).pathname}/graph_views`,
    }
  },
}

const ParadiseClients = createBasicLocation(`${PARADISE_PATH}/clients`)

const ParadiseClient: ILocation<string> = {
  location(clientId) {
    return {
      pathname: `${ParadiseClients.location().pathname}/${clientId}`,
    }
  },
}

const ParadiseFirmwares = createBasicLocation(`${PARADISE_PATH}/firmwares`)

const ParadiseFirmware: ILocation<string> = {
  location(fwNumber) {
    return {
      pathname: `${ParadiseFirmwares.location().pathname}/${fwNumber}`,
    }
  },
}

const ParadiseProfiles = createBasicLocation(`${PARADISE_PATH}/profiles`)

const ParadiseOrders = createBasicLocation(`${PARADISE_PATH}/orders`)

const ParadiseOrder: ILocation<string> = {
  location(orderNumber) {
    return {
      pathname: `${ParadiseOrders.location().pathname}/${orderNumber}`,
    }
  },
}

const ParadiseCreateOrder = createBasicLocation(`${PARADISE_PATH}/create_order`)

const ParadiseShippableProducts = createBasicLocation(
  `${PARADISE_PATH}/shippable_products`
)

const ParadiseShippableProduct: ILocation<string> = {
  location(id) {
    return {
      pathname: `${ParadiseShippableProducts.location().pathname}/${id}`,
    }
  },
}

const ParadiseShipping = createBasicLocation(`${PARADISE_PATH}/shipping`)

const ParadiseShippingMethod: ILocation<string> = {
  location(id) {
    return {
      pathname: `${ParadiseShipping.location().pathname}/shipping_methods/${id}`,
    }
  },
}

const ParadiseProfile: ILocation<string> = {
  location(profileId) {
    return {
      pathname: `${ParadiseProfiles.location().pathname}/${profileId}`,
    }
  },
}

const ParadiseHomeGroup: ILocation<{ orgId: string; homeGroupId: string }> = {
  location({ orgId, homeGroupId }) {
    return {
      pathname: `${ParadiseOrganization.location(orgId).pathname}/home_groups/${homeGroupId}`,
    }
  },
}

const ParadiseQuotes = createBasicLocation(`${PARADISE_PATH}/quotes`)

const ParadiseQuote: ILocation<string> = {
  location(quoteId) {
    return {
      pathname: `${ParadiseQuotes.location().pathname}/${quoteId}`,
    }
  },
}

export const ParadiseRoutes = {
  ParadiseDashboard,
  ParadiseHomes,
  ParadiseHome,
  ParadiseUsers,
  ParadiseUser,
  ParadiseOrganizations,
  ParadiseOrganization,
  ParadiseDevices,
  ParadiseDevice,
  ParadiseDeviceReadings,
  ParadiseDeviceQuickInsights,
  ParadiseDeviceGraphViews,
  ParadiseClients,
  ParadiseClient,
  ParadiseFirmwares,
  ParadiseFirmware,
  ParadiseProfiles,
  ParadiseProfile,
  ParadiseOrders,
  ParadiseOrder,
  ParadiseCreateOrder,
  ParadiseShippableProducts,
  ParadiseShipping,
  ParadiseShippingMethod,
  ParadiseShippableProduct,
  ParadiseHomeGroup,
  ParadiseQuotes,
  ParadiseQuote,
}
