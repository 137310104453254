import { Country as CountryCode } from "react-phone-number-input"

import { components, paths } from "@minuthq/minut-api-types/schema"

import { TDevice } from "src/data/devices/types/deviceTypes"
import { Maybe } from "src/utils/tsUtil"

export type ILocation = Exclude<THome["location"], undefined>

export type TCountryCode = CountryCode

export type Violations = Exclude<THome["violations"], undefined>[number]

export const DEFAULT_OCCUPANCY_THRESHOLD = 0
export const DEFAULT_PROFILE_ID = ""
export const DEFAULT_CHECKIN_MESSAGE = ""
export const DEFAULT_HOME_ID = ""
export const DEFAULT_HOME_NAME = (homeId: string) => homeId

export type IGuestCommunication =
  components["schemas"]["HomeGuestCommunication"]

export type THome = components["schemas"]["Home"] & {
  // Will be removed once the backend has added/updated this field
  icm_profile_id?: string
}

export type THomeAddress = NonNullable<THome["address"]>

export interface IStatefulHome extends THome {
  homeAlertState?: "warning" | "emergency" // This is a frontend state indicating the alert level of the home
  indoor_climate_monitoring?: TIndoorClimateMonitoring
}

export interface IEmergencyContact {
  name: string
  phone_number: string
}

export interface ISmokingDetection {
  /**
   * Will be set to true if smoking detection can be enabled on the home
   */
  available: boolean
  /**
   * The current state of smoking detection. Can be used to set the state
   */
  state: "enabled" | "disabled"
  /**
   * | Status | Meaning |
            |----|---|
            | undefined | If state is set to disabled this will be undefined|
            |alerting| Smoking has been detected|
            |idle| Smoking detection is enabled, and no smoke is detected|
   */
  status?: "alerting" | "idle"
  updated_at: string
  calibration_done_at?: string // ISO date string
}

export type IDisturbanceMonitoring =
  components["schemas"]["DisturbanceMonitoring"]

export type TDisturbanceMonitoringActive = NonNullable<
  THome["disturbance_monitoring"]
>["disturbance_monitoring_active"]

export type TMaybeHome = Maybe<THome>

export enum MonitoringType {
  MOTION = "MOTION",
  OCCUPANCY = "OCCUPANCY",
  SOUND = "SOUND",
  TEMPERATURE = "TEMPERATURE",
  HUMIDITY = "HUMIDITY",
  MOLD = "MOLD",
  FIRECO = "FIRE & CO",
  CIGARETTE = "CIGARETTE",
  INDOOR_CLIMATE = "INDOOR CLIMATE",
}

export interface IStatefulHomeWithDevices extends IStatefulHome {
  devices: TDevice[]
}

export type IHomeDisturbancePutBody =
  paths["/homes/{home_id}/disturbance"]["put"]["requestBody"]["content"]["application/json"]

export type IHomeDisturbancePutResponse =
  paths["/homes/{home_id}/disturbance"]["put"]["responses"]["200"]["content"]["application/json"]

export type TIndoorClimateMonitoring = NonNullable<
  THome["indoor_climate_monitoring"]
>

export type TThermostatMode = NonNullable<THome["thermostat_mode_on_checkin"]>

export type THomeType = THome["space_use_type"]
