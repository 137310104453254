import { TParadiseShippableProductsQueryParams } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { arrayIncludes } from "src/utils/genericUtil"

export const validParadiseShippableProductsSortIds: NonNullable<
  TParadiseShippableProductsQueryParams["sort_by"]
>[] = ["created_at", "sku"]

export function parseParadiseShippableProductsSortField(
  sortValue: string | undefined
): sortValue is TParadiseShippableProductsQueryParams["sort_by"] {
  if (
    !sortValue ||
    !arrayIncludes(validParadiseShippableProductsSortIds, sortValue)
  ) {
    return false
  }

  return true
}
