import styled from "styled-components"

import { mColors, Text } from "@minuthq/meatball-ui-react"
import { isBefore } from "date-fns"

import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { ParadiseQuoteStatus } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteStatus"
import { TParadiseQuote } from "src/data/paradise/paradiseQuotes/paradiseQuoteTypes"
import ArrowRightIcon from "src/ui/icons/right-arrow.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseQuoteDetailsMeta({ quote }: { quote: TParadiseQuote }) {
  const isExpired = isBefore(new Date(quote.expires_at), new Date())

  return (
    <Wrapper>
      <ParadiseDetailsHeading>{quote.customer_name}</ParadiseDetailsHeading>
      <ParadiseQuoteStatus status={quote.status} />
      <DatesWrapper>
        <Text variant="bodyS" color="secondary">
          {formatUtcDate({
            date: quote.created_at,
          })}
        </Text>
        <div>
          <ArrowRightIcon width={24} color={mColors.textSecondary} />
        </div>
        <Text variant="bodyS" color="secondary">
          {formatUtcDate({
            date: quote.expires_at,
          })}
        </Text>
        {isExpired && (
          <MBadge color="error" size="x-small">
            Expired
          </MBadge>
        )}
      </DatesWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.XS};
`

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};

  & svg {
    display: block;
  }
`
