import styled from "styled-components"

import { spacing } from "@minuthq/meatball-ui-react"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"

export function ParadiseSearchMultiSelect({
  placeholder,
  selectedValues,
  options,
  onChange,
  onSearch,
  isLoading,
  isLoadingMore,
  error,
}: {
  placeholder: string
  selectedValues: string[]
  options: {
    label: string
    value: string
  }[]
  onChange: (values: string[]) => void
  onSearch: (searchedValue: string) => void
  isLoading: boolean
  isLoadingMore?: boolean
  error?: string
}) {
  return (
    <div>
      <SearchFilter
        placeholder={placeholder}
        initialValue=""
        onChange={onSearch}
        isLoading={isLoading}
      />

      <List $dimmed={isLoadingMore}>
        {!!error && (
          <MBanner size="small" type="error">
            {error}
          </MBanner>
        )}

        {options.map((option) => (
          <StyledMCheckbox
            key={option.value}
            label={option.label}
            checked={selectedValues.includes(option.value)}
            onCheck={(checked) => {
              if (checked) {
                onChange([...selectedValues, option.value])
              } else {
                onChange(selectedValues.filter((u) => u !== option.value))
              }
            }}
            disabled={isLoadingMore}
          />
        ))}
      </List>
    </div>
  )
}

const List = styled.div<{ $dimmed?: boolean }>`
  display: grid;
  max-height: 60vh;
  overflow-y: auto;

  opacity: ${({ $dimmed }) => ($dimmed ? 0.5 : 1)};
`

const StyledMCheckbox = styled(MCheckbox)`
  padding-block: ${spacing.S};
  padding-inline: ${spacing.XS};
`
