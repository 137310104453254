import { useRef, useState } from "react"
import styled from "styled-components"

import { Grow, Paper, Popper } from "@material-ui/core"
import { useOnClickOutside } from "usehooks-ts"

import { MChip } from "src/components/MChip/MChip"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { getPortalRoot } from "src/ui/BaseModalDialog/baseModalDialogUtils"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsLegacy, greyScale } from "src/ui/colors"
import ChevronDownIcon from "src/ui/icons/chevron-down.svg"
import { spacing } from "src/ui/spacing"

export type FilterChipProps = {
  label: string
  value: string
  active: boolean
  onRemove?: () => void
  removeLabel?: string
  initialOpen?: boolean
  children: React.ReactNode
}

export function FilterChip({
  label,
  value,
  active,
  onRemove,
  removeLabel,
  initialOpen,
  children,
}: FilterChipProps) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(popperRef, (e) => {
    const eventTarget = e.target as Element

    if (!anchorRef.current?.contains(eventTarget)) {
      setOpen(false)
    }
  })

  useEffectOnce(() => {
    if (initialOpen) {
      setOpen(true)
    }
  })

  return (
    <div>
      <MChip
        label={
          <StyledDl>
            {!active ? (
              label
            ) : (
              <>
                <dt>{label}</dt>
                <Separator />
                <dd>{value}</dd>
              </>
            )}
          </StyledDl>
        }
        Icon={ChevronDownIcon}
        onClick={() => setOpen((prev) => !prev)}
        size="small"
        borderType="dashed"
        active={active}
        ref={anchorRef}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        ref={popperRef}
        style={{ marginBlock: spacing.XS }}
        placement="bottom-start"
        container={getPortalRoot}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <DropdownContentBox>
              <StyledSelect>
                {children}
                {onRemove && (
                  <BoxBottom>
                    <TextButton onClick={() => onRemove()}>
                      {removeLabel || "Remove filter"}
                    </TextButton>
                  </BoxBottom>
                )}
              </StyledSelect>
            </DropdownContentBox>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const DropdownContentBox = styled(Paper)`
  min-width: 200px;
`

const StyledDl = styled.dl`
  display: inline-flex;
  gap: ${spacing.XS};
  align-items: center;
  margin: 0;

  > dd,
  > dt {
    margin: 0;
  }
`

const Separator = styled.div`
  height: 0.75rem;
  width: 1px;
  background-color: ${colorsLegacy.divider};
`

const StyledSelect = styled.div`
  padding: ${spacing.S} 0;
  width: 300px;
`

const BoxBottom = styled.div`
  border-top: 1px solid ${greyScale[50]};
  padding: ${spacing.XS} 0 0 ${spacing.S};
`
