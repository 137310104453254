import { components } from "@minuthq/minut-api-types/schema"

import { arrayIncludes } from "src/utils/genericUtil"

type TCurrency = components["schemas"]["Currency"]

export const validCurrencyCodes: NonNullable<TCurrency>[] = [
  "AUD",
  "CAD",
  "EUR",
  "GBP",
  "USD",
]

export function isValidCurrency(
  sortValue: string | null | undefined
): sortValue is TCurrency {
  if (!sortValue || !arrayIncludes(validCurrencyCodes, sortValue)) {
    return false
  }

  return true
}
