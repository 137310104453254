import { useMemo } from "react"

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import {
  TParadiseShippingMethodZonePriceCondition,
  TParadiseShippingZone,
} from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { formatPrice } from "src/utils/formatPrice"

export function ParadiseShippingMethodZonePrice({
  prices,
  currency,
}: {
  prices: TParadiseShippingZone["price"]
  currency: TSubscriptionCurrencyCode
}) {
  const transformedPrices = useMemo(() => {
    return prices.toSorted((a, b) => {
      return a.min - b.min
    })
  }, [prices])

  const tableColumns: TableColumn<TParadiseShippingZone["price"][number]>[] = [
    {
      value: "range",
      label: "Range",
      renderCell: (data) => (
        <div>
          {formatPriceRange({
            min: data.min,
            max: data.max,
            basedOn: data.based_on,
            currency: currency,
          })}
        </div>
      ),
      columnWidth: "auto",
    },
    {
      value: "amount",
      label: "Amount",
      renderCell: (data) => (
        <div>
          {
            (
              data.amount as unknown as {
                amount: number

                formatted_amount: string

                currency: string
              }
            ).formatted_amount
          }
        </div>
      ),
      columnWidth: "auto",
    },
  ]

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: tableColumns,
    data: transformedPrices,
  })

  return (
    <ParadiseTable
      header={headerElements}
      rows={rows ?? []}
      templateColumns={templateColumns}
      disableRowHover
    />
  )
}

function formatPriceRange({
  min,
  max,
  basedOn,
  currency,
}: {
  min: number
  max?: number
  basedOn: TParadiseShippingMethodZonePriceCondition
  currency: TSubscriptionCurrencyCode
}) {
  const transformedMin =
    basedOn === "weight" ? `${min}g` : formatPrice(min, currency)

  const transformedMax = max
    ? basedOn === "weight"
      ? `${max}g`
      : formatPrice(max, currency)
    : undefined

  if (transformedMax) {
    return `${transformedMin} - ${transformedMax}`
  }

  return `${transformedMin} and up`
}
