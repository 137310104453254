import { useCallback, useMemo } from "react"

import { useAppLocation } from "src/hooks/useAppLocation"
import {
  TAcceptedTypes,
  TMapSearchParamsByKeys,
  TSearchParamsByKey,
} from "src/router/searchParamsTypes"
import { parseSearchParamValue } from "src/router/searchParamsUtils"
import { useRouter } from "src/router/useRouter"
import { Nullable, PartialNullable } from "src/utils/tsUtil"

type TSetSearchParamsOptions = { replace?: boolean }

type TSetSearchParamsFn<FormattedSearchParams> = <
  Key extends keyof FormattedSearchParams,
>(
  key: Key,
  value: FormattedSearchParams[Key] | null,
  options?: TSetSearchParamsOptions
) => void

export type TSortedSearchParams<AvailableKeys> = {
  [key in keyof AvailableKeys]: {
    key: key
    value: AvailableKeys[key]
  }
}[keyof AvailableKeys][]

// This will be used by components that take in ´setSearchParams´, will remove ignore once it is used
// ts-prune-ignore-next
export type TSetSearchParamsProp<
  T extends {
    [key: string]: TAcceptedTypes | TAcceptedTypes[] | null
  },
> = TSetSearchParamsFn<T>

export function useSearchParams<
  const Keys extends TSearchParamsByKey[],
  FormattedSearchParams = TMapSearchParamsByKeys<Keys>,
>({ keys }: { keys: Keys }) {
  const location = useAppLocation()
  const { navigate } = useRouter()

  const searchParams = useMemo(() => {
    const currentSearchParams = new URLSearchParams(location.search)

    return generateSearchParamsObject(currentSearchParams, keys)
  }, [location.search, keys])

  /**
   * A sorted array of the search params.
   * They are sorted in the order they are defined in the URL.
   */
  const sortedSearchParams = useMemo(() => {
    const currentSearchParams = new URLSearchParams(location.search)

    return Array.from(currentSearchParams.entries())
      .map(([key, value]) => {
        const keyConfig = keys.find((k) => k.key === key)

        if (!keyConfig) {
          return null
        }

        return {
          key: keyConfig.key as Keys[number]["key"],
          value: parseSearchParamValue({
            value,
            type: keyConfig.type,
            list: keyConfig.list,
          }),
        }
      })
      .filter(Boolean) as TSortedSearchParams<FormattedSearchParams>
  }, [location.search, keys])

  /** Use this for atomic updates when you need to set multiple search params
   * simultaneously. */
  const setSearchParamsAll = useCallback(
    (
      params: PartialNullable<FormattedSearchParams>,
      options?: TSetSearchParamsOptions
    ) => {
      // We use `window.location.search` here instead of `location.search` due to ´location.search´
      // being out of sync with the URL when state is updated multiple times in a row, URL is changed but the state is not
      const searchParams = new URLSearchParams(window.location.search)

      for (const [key, value] of Object.entries(params)) {
        const keyConfig = keys.find((k) => k.key === key)
        const keyAcceptsFalsy = !!keyConfig?.acceptFalsy

        const valueIsFalsy =
          value === false ||
          value === "" ||
          value === 0 ||
          (Array.isArray(value) && value.length === 0)

        // We have to check for NaN here becuase converted strings passed in to this function can be NaN if the required type is number
        if (
          value === null ||
          Number.isNaN(value) ||
          (!keyAcceptsFalsy && valueIsFalsy)
        ) {
          searchParams.delete(key)
        } else {
          searchParams.set(key, String(value))
        }
      }

      navigate(
        { search: searchParams.toString(), hash: window.location.hash },
        { replace: options?.replace }
      )
    },
    [navigate, keys]
  )

  const setSearchParams: TSetSearchParamsFn<FormattedSearchParams> =
    useCallback(
      (key, value, options) => {
        setSearchParamsAll(
          { [key]: value } as Partial<FormattedSearchParams>,
          options
        )
      },
      [setSearchParamsAll]
    )

  return {
    searchParams,
    sortedSearchParams,
    setSearchParams,
    setSearchParamsAll,
  } as const
}

function generateSearchParamsObject<Keys extends TSearchParamsByKey[]>(
  currentSearchParams: URLSearchParams,
  keys: Keys
) {
  const searchParamsObjectEntries = keys.map((k) => [
    k.key,
    parseSearchParamValue({
      value: currentSearchParams.get(k.key),
      type: k.type,
      list: k.list,
    }),
  ])

  return Object.fromEntries(searchParamsObjectEntries) as Nullable<
    TMapSearchParamsByKeys<Keys>
  >
}
