import { useMemo } from "react"
import styled from "styled-components"

import { TClockType } from "src/data/user/user"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import {
  TIMELINE_COLUMN_GAP,
  TIMELINE_ICON_SIZE,
} from "src/ui/Timeline/timelineConstants"
import { TimelineItem, TTimeline } from "src/ui/Timeline/TimelineItem"
import { groupArrayValues } from "src/utils/genericUtil"
import { formatDate } from "src/utils/l10n"

type TimelineProps = {
  timeline: TTimeline[]
  /**
   * This will be used to render the grouped date labels.
   * @param timestamp
   * @returns Formatted date string
   */
  dateFormatter?: (timestamp: string) => string
  /**
   * This will be used to render the time in the timeline item.
   * @param timestamp
   * @returns Formatted time string
   */
  timeFormatter?: (timestamp: string) => string
  clockType?: TClockType
}

export function Timeline({
  timeline,
  dateFormatter,
  timeFormatter,
  clockType,
}: TimelineProps) {
  const groupedTimeline = useMemo(() => {
    const grouped = groupArrayValues({
      arr: timeline,
      grouper: (item) =>
        dateFormatter
          ? dateFormatter(item.timestamp)
          : formatDate({ date: item.timestamp, excludeTime: true }),
    })

    return grouped
  }, [timeline, dateFormatter])

  return (
    <Wrapper>
      {groupedTimeline.map((group) => (
        <div key={group.label}>
          <DateLabel
            variant="subtitleS"
            color="secondary"
            marginBottom={spacing.XS2}
          >
            {group.label}
          </DateLabel>
          {group.items.map((t, i) => (
            <TimelineItem
              key={i}
              timelineItem={t}
              timeFormatter={timeFormatter}
              clockType={clockType}
            />
          ))}
        </div>
      ))}

      <Line />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;

  display: grid;
  row-gap: ${spacing.M};
`

const DateLabel = styled(MText)`
  margin-left: calc(${TIMELINE_ICON_SIZE} + ${TIMELINE_COLUMN_GAP});
`

const Line = styled.div`
  position: absolute;
  top: 0;
  left: calc(${TIMELINE_ICON_SIZE} / 2);
  transform: translateX(-50%);
  z-index: -1;

  width: 2px;
  height: 100%;
  background-color: ${mColors.neutralDark};
`
