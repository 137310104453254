import { TParadiseShippingMethodsQueryParams } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { arrayIncludes } from "src/utils/genericUtil"

export const validParadiseOrdersSortIds: NonNullable<
  TParadiseShippingMethodsQueryParams["sort_by"]
>[] = ["created_at"]

export function parseParadiseShippingMethodsSortField(
  sortValue: string | undefined
): sortValue is NonNullable<TParadiseShippingMethodsQueryParams["sort_by"]> {
  if (!sortValue || !arrayIncludes(validParadiseOrdersSortIds, sortValue)) {
    return false
  }

  return true
}
