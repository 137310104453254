import { useMemo } from "react"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { TParadiseOrderResponse } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { mColors } from "src/ui/colors"
import OpenLetterIcon from "src/ui/icons/open-letter.svg"
import RefreshIcon from "src/ui/icons/refresh.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { Timeline } from "src/ui/Timeline/Timeline"
import { TTimeline } from "src/ui/Timeline/TimelineItem"
import { formatDateAsTime, formatUtcDate } from "src/utils/l10n"

export function ParadiseOrderTimeline({
  timeline,
}: {
  timeline: TParadiseOrderResponse["timeline"]
}) {
  const formattedTimeline = useMemo(() => {
    return timeline.toReversed().map<TTimeline>((item) => ({
      description: item.formatted_values.description,
      timestamp: item.timestamp,
      indicator: getTimelineIndicator(item.event_type),
      content: <TimeLineContent event={item} />,
    }))
  }, [timeline])

  return (
    <div>
      <ParadiseDetailsSection title="Timeline">
        <Timeline
          timeline={formattedTimeline}
          dateFormatter={(timestamp) =>
            formatUtcDate({
              date: timestamp,
              excludeTime: true,
            })
          }
          timeFormatter={(timestamp) =>
            formatDateAsTime({
              date: timestamp,
              timezone: "UTC",
              clockType: "24",
            })
          }
        />
      </ParadiseDetailsSection>
    </div>
  )
}

function TimeLineContent({
  event,
}: {
  event: TParadiseOrderResponse["timeline"][number]
}) {
  switch (event.event_type) {
    case "email_sent":
      return (
        <ExternalLink href={event.email_source_url}>View Email</ExternalLink>
      )
    default:
      return null
  }
}

function getTimelineIndicator(
  eventType: TParadiseOrderResponse["timeline"][number]["event_type"]
): TTimeline["indicator"] | undefined {
  switch (eventType) {
    case "status_changed":
      return {
        Icon: RefreshIcon,
      }
    case "email_sent":
      return {
        Icon: OpenLetterIcon,
      }
    case "email_delivery_failure":
      return {
        Icon: OpenLetterIcon,
        color: mColors.systemError,
      }
    default:
      return
  }
}
