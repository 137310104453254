import { TSignalQuality } from "src/data/devices/types/deviceTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import CellExcellent from "src/ui/icons/cellular-excellent.svg"
import CellFair from "src/ui/icons/cellular-fair.svg"
import CellGood from "src/ui/icons/cellular-good.svg"
import CellNone from "src/ui/icons/cellular-none.svg"
import CellWeak from "src/ui/icons/cellular-weak.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { SVGElement, SVGProps } from "src/utils/tsUtil"

export function CellularStatus({
  quality,
  offline = false,
  ...props
}: {
  quality: TSignalQuality
  offline: boolean
} & SVGProps) {
  const { t } = useTranslate()

  const cellular: TIconMap = offline
    ? { title: t(langKeys.offline), svg: CellNone }
    : getIconMapping(quality, t)

  const CellularIcon = cellular.svg

  return (
    <MTooltip title={cellular.title}>
      <div>
        <CellularIcon height={30} width={30} {...props} />
      </div>
    </MTooltip>
  )
}

type TIconMap = {
  title: string
  svg: SVGElement
}

function getIconMapping(
  quality: TSignalQuality,
  t: ITranslateFunction
): TIconMap {
  const iconMapping = [
    { title: t(langKeys.signal_none), svg: CellWeak },
    { title: t(langKeys.signal_weak), svg: CellWeak },
    { title: t(langKeys.signal_fair), svg: CellFair },
    { title: t(langKeys.signal_good), svg: CellGood },
    { title: t(langKeys.signal_excellent), svg: CellExcellent },
  ] as const satisfies TIconMap[]

  return iconMapping[Number(quality)] ?? iconMapping[0]
}
