import styled from "styled-components"

import { format } from "date-fns"

import {
  InputContainer,
  TInputContainerProps,
} from "src/ui/InputContainer/InputContainer"
import { canShowLabel } from "src/ui/InputContainer/inputContainerUtils"

export type NativeDateTimeFieldProps = TInputContainerProps & {
  value: Date | null
  onChange: (value: Date) => void
  required?: boolean
}

export function NativeDateTimeField({
  label,
  value,
  onChange,
  required,
  size,
  ...props
}: NativeDateTimeFieldProps) {
  return (
    <InputContainer
      {...(canShowLabel(size)
        ? {
            label,
            size,
          }
        : {
            size,
          })}
      disableClearButton
      shrink
      {...props}
    >
      <StyledInput
        type="datetime-local"
        value={!!value ? format(value, "yyyy-MM-dd'T'hh:mm") : ""}
        onChange={(e) => {
          onChange(new Date(e.target.value))
        }}
        required={required}
      />
    </InputContainer>
  )
}

const StyledInput = styled.input`
  all: unset;
  width: 100%;
  background-color: transparent;
  border: none;
`
