import { TVirtualDevice } from "src/data/devices/types/deviceTypes"
import HumidityIcon from "src/ui/icons/humidity.svg"
import MoldRiskIcon from "src/ui/icons/mold.svg"
import TempIcon from "src/ui/icons/temperature.svg"

import { EventButton } from "./EventButton"
import { SliderSetting } from "./SettingsSlider"

export function VirtualDeviceIcmControls({
  simulatedDevice,
  orgId,
  homeId,
}: {
  simulatedDevice: TVirtualDevice
  orgId: string
  homeId: string
}) {
  return (
    <>
      <SliderSetting
        label="°C"
        orgId={orgId}
        simulatedDevice={simulatedDevice}
        Icon={TempIcon}
        step={1}
        min={-20}
        max={50}
        attribute={"temperature"}
        enabled={simulatedDevice.offline === false}
        marks={[-10, 20, 45].map((value) => ({ value, label: `${value}°C` }))}
      />

      <SliderSetting
        label="%"
        orgId={orgId}
        simulatedDevice={simulatedDevice}
        Icon={HumidityIcon}
        step={1}
        min={0}
        max={100}
        attribute={"humidity"}
        enabled={simulatedDevice.offline === false}
        marks={[0, 50, 100].map((value) => ({ value, label: `${value}%` }))}
      />

      <SliderSetting
        label="level"
        orgId={orgId}
        simulatedDevice={simulatedDevice}
        Icon={MoldRiskIcon}
        step={1}
        min={1}
        max={3}
        attribute={"mould_risk_level"}
        enabled={simulatedDevice.offline === false}
        marks={[
          {
            value: 1,
            label: "Low",
          },
          {
            value: 2,
            label: "Elevated",
          },
          {
            value: 3,
            label: "High",
          },
        ]}
      />

      <EventButton
        eventType={"generate_mould_risk_data"}
        orgId={orgId}
        deviceId={simulatedDevice.device_id}
        homeId={homeId}
        disabled={simulatedDevice.offline === true}
        label="1 year random mould risk data"
      />
    </>
  )
}
