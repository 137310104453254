import { useEffect, useState } from "react"
import styled from "styled-components"

import {
  autoUpdate,
  flip as flipMiddleware,
  FloatingFocusManager,
  FloatingPortal,
  offset as offsetMiddleware,
  OffsetOptions,
  Placement,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from "@floating-ui/react"

type TPopoverProps = {
  trigger: React.ReactNode

  /**
   * Wheter to open/close on `click` or `hover`
   */
  triggerType?: "click" | "hover"
  children: React.ReactNode
  placement?: Placement

  /**
   * Offset from the trigger element.
   *
   * If `number` it will be relative to the `placement`
   * More information about the options: https://floating-ui.com/docs/offset#options
   */
  offset?: OffsetOptions
  disableFocusListener?: boolean

  /**
   * Root defines the element the popover will be positioned relative to
   * @default body
   */
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>

  /**
   * Make the trigger inline
   */
  inline?: boolean

  /**
   * Disable scroll when open
   */
  disableScroll?: boolean

  /**
   * Disable flip when the popover is close to the edge of the root
   */
  disableFlip?: boolean
}

export function MPopover({
  trigger,
  triggerType = "click",
  children,
  placement,
  offset,
  disableFocusListener,
  root,
  inline,
  disableScroll,
  disableFlip,
}: TPopoverProps) {
  const [open, setOpen] = useState(false)

  const floating = useFloating({
    open,
    onOpenChange: (open) => {
      setOpen(open)
    },
    placement,
    transform: true,
    middleware: [offsetMiddleware(offset), !disableFlip && flipMiddleware()],
    // This makes sure we re-position the floating element when it resizes
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        elementResize: true,
      })
      return cleanup
    },
  })

  const click = useClick(floating.context, {
    enabled: triggerType === "click",
  })

  const hover = useHover(floating.context, {
    enabled: triggerType === "hover",
    move: false,
    mouseOnly: true,
    delay: { open: 100 },
    handleClose: safePolygon(),
  })

  const focus = useFocus(floating.context, {
    enabled: !disableFocusListener,
  })

  const dismiss = useDismiss(floating.context, {
    enabled: triggerType === "click",
  })

  const interactions = useInteractions([hover, click, focus, dismiss])

  useEffect(() => {
    if (open && disableScroll) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
  }, [open, disableScroll])

  return (
    <div>
      <ReferenceWrapper
        {...interactions.getReferenceProps()}
        ref={floating.refs.setReference}
        $inline={inline}
      >
        {trigger}
      </ReferenceWrapper>
      {open && (
        <FloatingPortal root={root}>
          <FloatingFocusManager context={floating.context} modal={false}>
            <div
              ref={floating.refs.setFloating}
              style={floating.floatingStyles}
              {...interactions.getFloatingProps()}
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  )
}

const ReferenceWrapper = styled.div<{ $inline?: boolean }>`
  display: ${({ $inline }) => ($inline ? "inline-block" : "block")};
`
