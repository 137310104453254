import { useState } from "react"
import styled from "styled-components"

import {
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core"
import { mColors } from "@minuthq/meatball-ui-react"

import { useTranslate } from "src/i18n/useTranslate"
import OptionsIcon from "src/ui/icons/options-horizontal.svg"
import PenIcon from "src/ui/icons/pen-outlined.svg"
import TrashIcon from "src/ui/icons/trash.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

type IClickEvent<E extends HTMLElement = HTMLElement> = React.MouseEvent<
  E,
  MouseEvent
>

interface IMenuItems {
  key: string
  contents: React.ReactNode
  onClick: null | ((e?: IClickEvent<HTMLLIElement>) => void)
  hidden?: boolean
  disabled?: boolean
  tooltip?: {
    title: string | null
    placement?: "top" | "bottom" | "left" | "right"
  }
}

export function MoreButton({
  onEdit,
  onDelete,
  menuItems,
  editLabel,
  deleteLabel,
  showDeleteIcon = true,
  disabled,
  transformOrigin,
}: {
  onEdit?: () => void
  onDelete?: () => void
  menuItems?: IMenuItems[]
  editLabel?: React.ReactNode
  deleteLabel?: React.ReactNode
  showDeleteIcon?: boolean
  disabled?: boolean
  transformOrigin?: {
    vertical: "bottom" | "top"
    horizontal: "left" | "right"
  }
}) {
  const { t, langKeys } = useTranslate()
  const popoverId = "more-button"

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  function handleMoreClick(e: IClickEvent<HTMLButtonElement>) {
    e.stopPropagation()
    const anchor: HTMLButtonElement = e.currentTarget
    setAnchorEl(anchor)
    setMenuOpen(true)
  }

  function handleDelete(e: IClickEvent) {
    e.stopPropagation()
    onDelete?.()
    if (anchorEl) {
      setMenuOpen(false)
    }
  }

  function handleEdit(e: IClickEvent) {
    e.stopPropagation()
    onEdit?.()
    if (anchorEl) {
      setMenuOpen(false)
    }
  }

  function handleClose(e: IClickEvent) {
    e.stopPropagation()
    setMenuOpen(false)
  }

  return (
    <>
      <Menu
        id={popoverId}
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
        transformOrigin={transformOrigin}
      >
        {menuItems?.map((item) => {
          if (!item.contents || item?.hidden) return null

          if (!item.onClick) {
            return <ListItem key={item.key}>{item.contents}</ListItem>
          }

          const menuItem = (
            <MenuItem
              key={item.key}
              onClick={(e) => {
                item.onClick?.(e)
                handleClose(e)
              }}
              disabled={item.disabled}
            >
              {item.contents}
            </MenuItem>
          )

          return item.tooltip?.title ? (
            <MTooltip
              title={item.tooltip.title}
              key={item.key}
              placement={item.tooltip.placement}
            >
              {menuItem}
            </MTooltip>
          ) : (
            menuItem
          )
        })}

        {!!onEdit && (
          <StyledMenuItem onClick={handleEdit}>
            <PenIcon height="16" width="16" />
            <ListItemText primary={editLabel || t(langKeys.edit)} />
          </StyledMenuItem>
        )}

        {!!onDelete && (
          <StyledMenuItem onClick={handleDelete}>
            {showDeleteIcon && (
              <TrashIcon
                height="16"
                width="16"
                fill={mColors.systemErrorDark}
              />
            )}
            <LisItemDeleteText primary={deleteLabel || t(langKeys.remove)} />
          </StyledMenuItem>
        )}
      </Menu>

      <StyledIconButton
        aria-label="more-button"
        aria-describedby={popoverId}
        onClick={handleMoreClick}
        disabled={disabled}
        color="inherit"
      >
        <OptionsIcon width={24} height={24} />
      </StyledIconButton>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  padding: ${spacing.XS};
`

const LisItemDeleteText = styled(ListItemText)`
  color: ${mColors.systemErrorDark};
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
  justify-content: center;
`
