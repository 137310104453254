import { TParadiseOrdersQueryParams } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import {
  TSetSearchParamsProp,
  TSortedSearchParams,
  useSearchParams,
} from "src/router/useSearchParams"
import { Nullable } from "src/utils/tsUtil"

// Prefix order params with `orders_` to avoid conflicts with other filters.
type TParadiseOrdersSearchFilterConfig = {
  [K in keyof Required<
    Omit<TParadiseOrdersQueryParams, "limit" | "sort_by" | "sort_order">
  > as `orders_${K}`]: K extends "user_ids" | "country_codes"
    ? string[]
    : NonNullable<TParadiseOrdersQueryParams[K]>
}

export type TParadiseOrdersFilters = Nullable<TParadiseOrdersSearchFilterConfig>
export type TSortedParadiseOrdersFilters =
  TSortedSearchParams<TParadiseOrdersSearchFilterConfig>

export function useParadiseOrdersFilter(): {
  filter: TParadiseOrdersFilters
  sortedFilters: TSortedParadiseOrdersFilters
  setFilter: TSetSearchParamsProp<TParadiseOrdersFilters>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
    sortedSearchParams: sortedFilters,
  } = useSearchParams({
    keys: [
      {
        key: "orders_order_number",
        type: "string",
      },
      {
        key: "orders_user_ids",
        type: "string",
        list: true,
      },
      {
        key: "orders_offset",
        type: "number",
      },
      {
        key: "orders_country_codes",
        type: "string",
        list: true,
      },
    ],
  })

  return {
    filter,
    // We do not count pagination as a filter
    sortedFilters: sortedFilters.filter((f) => f.key !== "orders_offset"),
    setFilter: (key, value) => {
      if (key === "orders_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        orders_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("orders_offset", offset)
    },
    offset: filter.orders_offset || 0,
  }
}
