import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseShippingZone } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethodDetails/ParadiseShippingMethodZone"
import { TParadiseShippingMethod } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"

export function ParadiseShippingMethodZonesSection({
  shippingMethod,
}: {
  shippingMethod: TParadiseShippingMethod
}) {
  return (
    <ParadiseDetailsSection title="Shipping Zones">
      {shippingMethod.zones.map((zone, i) => (
        <ParadiseShippingZone
          key={i}
          zone={zone}
          currency={shippingMethod.currency_code}
        />
      ))}
    </ParadiseDetailsSection>
  )
}
