import styled from "styled-components"

import { mColors, spacing } from "@minuthq/meatball-ui-react"

import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useTableColumns } from "src/ui/GridTable/useTableColumns/useTableColumns"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { formatPrice } from "src/utils/formatPrice"

type TAvailableShippableProduct = {
  imageUrl?: string
  productId: string
}

type TSelectedShippableProduct = {
  productId: string
  quantity: number
}

export function ParadiseShippableProductsQuantityTable({
  selectedShippableProducts,
  availableShippableProducts,
  updateShippableProducts,
  currency,
  hideTotal = false,
  disableQuantity = false,
}: {
  availableShippableProducts: TAvailableShippableProduct[]
  selectedShippableProducts: TSelectedShippableProduct[]
  updateShippableProducts: (
    shippableProducts: TSelectedShippableProduct[]
  ) => void
  currency: TSubscriptionCurrencyCode
  hideTotal?: boolean
  disableQuantity?: boolean
}) {
  const { rows, templateColumns, headerElements } =
    useTableColumns<TAvailableShippableProduct>({
      columns: [
        {
          value: "product",
          label: "Product",
          renderCell: (data) => {
            return (
              <ProductWrapper>
                <ImageWrapper>
                  <img src={data.imageUrl} />
                </ImageWrapper>
                <div>
                  <MText>{data.productId}</MText>
                </div>
              </ProductWrapper>
            )
          },
          columnWidth: "1fr",
        },
        {
          value: "quantity",
          label: "Quantity",
          renderCell: (data) => {
            const selectedShippableProduct = selectedShippableProducts.find(
              (product) => product.productId === data.productId
            )

            const isSelected = !!selectedShippableProduct

            return (
              <div>
                <QuantityWrapper>
                  <MTextField
                    type="number"
                    value={(selectedShippableProduct?.quantity ?? 0).toString()}
                    max={100}
                    min={0}
                    onChange={(value) => {
                      const parsedValue = parseInt(value)

                      if (parsedValue > 100 || parsedValue < 0) {
                        return
                      }

                      if (isSelected) {
                        updateShippableProducts(
                          selectedShippableProducts.map((product) => {
                            if (product.productId === data.productId) {
                              return {
                                ...product,
                                quantity: parseInt(value),
                              }
                            }

                            return product
                          })
                        )

                        return
                      }

                      updateShippableProducts([
                        ...selectedShippableProducts,
                        {
                          productId: data.productId,
                          quantity: parseInt(value),
                        },
                      ])
                    }}
                    size="small"
                    disableClearButton
                    disabled={disableQuantity}
                  />
                </QuantityWrapper>
              </div>
            )
          },
          columnWidth: "1fr",
        },
        {
          value: "total",
          label: "Total",
          renderCell: () => <div>{formatPrice(0, currency)}</div>,
          columnWidth: "auto",
          hidden: hideTotal,
        },
      ],
      data: availableShippableProducts,
    })

  return (
    <div>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        disableRowHover
        removeMargin
      />
    </div>
  )
}

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  gap: ${spacing.S};
`

const ImageWrapper = styled.div`
  border: 1px solid ${mColors.divider};
  border-radius: 0.5rem;

  & > img {
    width: 100%;
    height: 100%;
  }
`

const QuantityWrapper = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
`
