import {
  TParadiseShippingMethodsForCartRequestBody,
  TParadiseShippingMethodsQueryParams,
} from "src/data/paradise/paradiseShipping/paradiseShippingTypes"

export const paradiseShippingKeys = {
  all() {
    return ["paradiseShipping"] as const
  },

  destinations() {
    return [...this.all(), "destionations"] as const
  },

  destinationsLists() {
    return [...this.all(), "destionations", "list"] as const
  },

  destinationsList() {
    return [...this.destinationsLists()] as const
  },

  shippingMethods() {
    return [...this.all(), "shippingMethods"] as const
  },

  shippingMethodsLists() {
    return [...this.shippingMethods(), "list"] as const
  },

  shippingMethodsList(filter?: TParadiseShippingMethodsQueryParams) {
    if (filter) {
      return [...this.shippingMethodsLists(), filter] as const
    }

    return [...this.shippingMethodsLists()] as const
  },

  shippingMethod(id: string) {
    return [...this.shippingMethods(), id] as const
  },

  shippingMethodsForCart(cart: TParadiseShippingMethodsForCartRequestBody) {
    return [...this.shippingMethods(), "cart", cart] as const
  },
} as const
