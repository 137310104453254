import { spacing } from "src/ui/spacing"

/**
 * The size of the icon in the timeline
 *
 * This is used to set the size of the icon in the timeline and
 * positioning the line and grouped date labels correctly.
 */
export const TIMELINE_ICON_SIZE = "1rem"

/**
 * The gap between the timeline columns
 *
 * This is used to set the gap within the timeline and
 * positioning the line and grouped date labels correctly.
 */
export const TIMELINE_COLUMN_GAP = spacing.S
