import styled from "styled-components"

import { mColors, spacing, Text } from "@minuthq/meatball-ui-react"

import { ParadiseCopyBox } from "src/components/Paradise/ParadiseCopyBox"
import { generateQuoteLink } from "src/components/Paradise/ParadiseQuotes/paradiseQuoteUtils"
import CheckCircleIcon from "src/ui/icons/checkmark-round.svg"

export function ParadiseCreateQuoteDialogSuccess({
  quoteId,
  shippingCountry,
}: {
  quoteId: string
  shippingCountry: string
}) {
  return (
    <Wrapper>
      <CheckCircleIcon width={65} color={mColors.systemGood} />
      <Text variant="heading3">Quote created successfully</Text>

      <ParadiseCopyBox text={generateQuoteLink(quoteId, shippingCountry)} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
  align-items: center;
  justify-content: center;
  height: 100%;
`
