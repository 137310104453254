import { MChip } from "src/components/MChip/MChip"
import XIcon from "src/ui/icons/x.svg"

export function DeleteChip({
  label,
  onDelete,
}: {
  label: React.ReactNode
  onDelete: () => void
}) {
  return <MChip label={label} Icon={XIcon} onClick={onDelete} />
}
