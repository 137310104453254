import { TParadiseQuotesQueryParams } from "src/data/paradise/paradiseQuotes/paradiseQuoteTypes"
import {
  TSetSearchParamsProp,
  TSortedSearchParams,
  useSearchParams,
} from "src/router/useSearchParams"
import { Nullable } from "src/utils/tsUtil"

type TParadiseQuotesSearchFilterConfig = {
  [K in keyof Required<
    Omit<TParadiseQuotesQueryParams, "limit">
  > as `quotes_${K}`]: NonNullable<TParadiseQuotesQueryParams[K]>
}

export type TParadiseQuotesFilters = Nullable<TParadiseQuotesSearchFilterConfig>
export type TSortedParadiseQuotesFilters =
  TSortedSearchParams<TParadiseQuotesSearchFilterConfig>

export function useParadiseQuotesFilter(): {
  filter: TParadiseQuotesFilters
  sortedFilters: TSortedParadiseQuotesFilters
  setFilter: TSetSearchParamsProp<TParadiseQuotesFilters>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
    sortedSearchParams: sortedFilters,
  } = useSearchParams({
    keys: [
      {
        key: "quotes_offset",
        type: "number",
      },
    ],
  })

  return {
    filter,
    // We do not count pagination as a filter
    sortedFilters: sortedFilters.filter((f) => f.key !== "quotes_offset"),
    setFilter: (key, value) => {
      if (key === "quotes_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        quotes_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("quotes_offset", offset)
    },
    offset: filter.quotes_offset || 0,
  }
}
