import { SubscriptionStatus } from "src/components/Account/types"
import { NotEnoughPermissionsDialog } from "src/components/FeatureBlockers/NotEnoughPermissionsDialog"
import { ReactivateBlockerDialog } from "src/components/FeatureBlockers/ReactivateBlockerDialog"
import { StoreBlockerDialog } from "src/components/FeatureBlockers/StoreBlockerDialog"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { BlockerReason } from "src/components/Homes/CreateHomeWizard/useCreateHomeWizard"
import { isProdEnv } from "src/constants/env"
import { HREF_MINUT_STAGING_STORE, HREF_MINUT_STORE } from "src/constants/hrefs"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"

export function CreateHomeBlocker({
  blockerReason,
}: {
  blockerReason: BlockerReason | undefined
}) {
  const { goBack } = useRouter()

  const { org } = useOrganization()

  if (blockerReason === "needs-subcription") {
    return org.current_subscription_status !== SubscriptionStatus.CANCELLED ? (
      <StoreBlockerDialog
        open={true}
        onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
        onConfirm={() => {
          window.location.href = isProdEnv
            ? HREF_MINUT_STORE
            : HREF_MINUT_STAGING_STORE
        }}
      />
    ) : (
      <UpgradeBlockerDialog
        context="subscription_required"
        open={true}
        onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
      />
    )
  }

  if (blockerReason === "needs-reactivation") {
    return (
      <ReactivateBlockerDialog
        open={true}
        onClose={() => {
          goBack({ defaultPath: Routes.Homes.location() })
        }}
      />
    )
  }

  if (blockerReason === "access-denied") {
    return (
      <NotEnoughPermissionsDialog
        open={true}
        onClose={() => {
          goBack({ defaultPath: Routes.Homes.location() })
        }}
      />
    )
  }

  return null
}
