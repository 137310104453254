import styled from "styled-components"

import { Switch } from "@material-ui/core"
import { InfoIcon } from "@minuthq/meatball-ui-icons"
import { spacing } from "@minuthq/meatball-ui-react"

import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseSelectShippingCountriesFilter } from "src/components/Paradise/ParadiseSelectShippingCountriesFilter"
import {
  TParadiseShippingMethodsFilters,
  useParadiseShippingMethodsFilter,
} from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/useParadiseShippingMethodsFilter"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  FilterWrapper,
  IdWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { prettifyApiFieldValue } from "src/data/orders/orderUtils"
import {
  isValidCurrency,
  validCurrencyCodes,
} from "src/data/paradise/logic/paradiseLogic"
import { parseParadiseCountryCodesField } from "src/data/paradise/paradiseOrders/logic/paradiseOrderLogic"
import { parseParadiseShippingMethodsSortField } from "src/data/paradise/paradiseShipping/logic/paradiseShippingLogic"
import { useFetchParadiseShippingMethods } from "src/data/paradise/paradiseShipping/paradiseShippingQueries"
import { TParadiseShippingMethod } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { Routes } from "src/router/routes"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { FilterChip } from "src/ui/Filter/FilterChip"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { unique } from "src/utils/genericUtil"
import { formatUtcDate } from "src/utils/l10n"

const shippingMethodsFilterLabel: Record<
  keyof TParadiseShippingMethodsFilters,
  string
> = {
  shipping_methods_currency_code: "Currency code",
  shipping_methods_include_deleted: "Include deleted",
  shipping_methods_offset: "Offset",
  shipping_methods_countries: "Countries",
  shipping_methods_warehouse: "Warehouse",
} as const

export function ParadiseShippingMethods() {
  const { filter, offset, setFilter, setOffset, sortedFilters } =
    useParadiseShippingMethodsFilter()

  const { sortValue, setSortValue } = useSortParam()

  const limit = 50
  const fetchParadiseShippingMethods = useFetchParadiseShippingMethods({
    filter: {
      currency_code: isValidCurrency(filter.shipping_methods_currency_code)
        ? filter.shipping_methods_currency_code
        : undefined,
      countries: parseParadiseCountryCodesField(
        filter.shipping_methods_countries
      ),
      include_deleted: filter.shipping_methods_include_deleted || undefined,
      warehouse: filter.shipping_methods_warehouse ?? undefined,
      limit,
      offset: filter.shipping_methods_offset ?? 0,
      sort_by: parseParadiseShippingMethodsSortField(sortValue?.id)
        ? sortValue.id
        : undefined,
      sort_order: sortValue?.order,
    },
  })

  const tableColumns: TableColumn<TParadiseShippingMethod>[] = [
    {
      value: "id",
      label: "ID",
      renderCell: (data) => (
        <div>
          <IdWrapper>
            <InternalLink to={Routes.ParadiseShippingMethod.location(data.id)}>
              {data.id}
            </InternalLink>
            {data.deleted && data.deleted_at && (
              <MTooltip
                title={`Deleted at ${formatUtcDate({ date: data.deleted_at })}`}
              >
                <InfoIcon width={12} />
              </MTooltip>
            )}
          </IdWrapper>
        </div>
      ),
      columnWidth: "auto",
    },
    {
      value: "shipping_method_id",
      label: "Shipping method id",
      renderCell: (data) => <div>{data.shipping_method_id}</div>,
      columnWidth: "auto",
    },
    {
      value: "created_at",
      label: "Created at",
      renderCell: (data) => (
        <div>{formatUtcDate({ date: data.created_at })}</div>
      ),
      enableSort: true,
      columnWidth: "auto",
    },
    {
      value: "name",
      label: "Name",
      renderCell: (data) => <div>{data.name}</div>,
      columnWidth: "auto",
    },
    {
      value: "display_name",
      label: "Display name",
      renderCell: (data) => <div>{data.display_name}</div>,
      columnWidth: "auto",
    },
    {
      value: "currency_code",
      label: "Currency",
      renderCell: (data) => <div>{data.currency_code}</div>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <MSelect
            value={filter.shipping_methods_currency_code ?? ""}
            size="small"
            options={validCurrencyCodes.map((currency) => ({
              label: currency,
              value: currency,
            }))}
            onChange={(v) => {
              setFilter("shipping_methods_currency_code", v)
            }}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
      columnWidth: "auto",
    },
    {
      value: "countries",
      label: "Countries",
      renderCell: (data) => {
        const countryCodes = data.zones
          .flatMap((zone) => zone.countries)
          .map((countryCode) => countryCode)
          .toSorted((a, b) => a.localeCompare(b))

        return (
          <div>
            <CountriesWrapper>
              {countryCodes.slice(0, 8).join(", ")}{" "}
              {countryCodes.length > 8 && (
                <MTooltip title={countryCodes.slice(8).join(", ")}>
                  <MText
                    variant="bodyS"
                    color="secondary"
                    textDecoration="underline"
                    style={{
                      textDecorationStyle: "dashed",
                    }}
                  >
                    more
                  </MText>
                </MTooltip>
              )}
            </CountriesWrapper>
          </div>
        )
      },
      popoverContent: (
        <ParadiseSelectShippingCountriesFilter
          selectedCountries={
            parseParadiseCountryCodesField(filter.shipping_methods_countries) ??
            []
          }
          onChange={(countries) =>
            setFilter("shipping_methods_countries", countries)
          }
        />
      ),
      popoverIcon: FilterIcon,
      columnWidth: "auto",
    },
    {
      value: "warehouse",
      label: "Warehouse",
      renderCell: (data) => (
        <div>
          {unique(
            data.zones.flatMap((zone) => prettifyApiFieldValue(zone.warehouse))
          ).join(", ")}
        </div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <MSelect
            value={filter.shipping_methods_warehouse ?? ""}
            size="small"
            options={[
              {
                label: "Postpac",
                value: "postpac",
              },
              {
                label: "Link logistics",
                value: "link_logistics",
              },
              {
                label: "Byrd",
                value: "byrd",
              },
            ]}
            onChange={(v) => {
              setFilter("shipping_methods_warehouse", v)
            }}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
  ]

  const {
    interactiveVisibleColumns,
    interactiveColumns,
    updateColumnVisibility,
    headerElements,
    rows,
    templateColumns,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchParadiseShippingMethods.data?.shipping_methods,
    sort: sortValue,
    onSortChange: setSortValue,
  })

  return (
    <div>
      <TopWrapper>
        <FilterWrapper>
          <FilterChip
            label="Include deleted"
            value={filter.shipping_methods_include_deleted ? "True" : "False"}
            active={filter.shipping_methods_include_deleted !== null}
            onRemove={() => setFilter("shipping_methods_include_deleted", null)}
          >
            <FilterContent>
              <MText variant="subtitleS" marginBottom={spacing.S}>
                Include inactive devices
              </MText>
              <Switch
                checked={!!filter.shipping_methods_include_deleted}
                onChange={(_, checked) =>
                  setFilter("shipping_methods_include_deleted", checked)
                }
              />
            </FilterContent>
          </FilterChip>
          <ParadiseAppliedFilters
            filters={sortedFilters.map((f) => ({
              label: shippingMethodsFilterLabel[f.key],
              value: String(f.value),
              onRemove: () => setFilter(f.key, null),
            }))}
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        error={{
          hasError: fetchParadiseShippingMethods.isError,
          title: fetchParadiseShippingMethods.error?.message,
        }}
        isLoadingMore={fetchParadiseShippingMethods.isPreviousData}
        isLoading={fetchParadiseShippingMethods.isLoading}
      />

      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchParadiseShippingMethods.data?.paging.total_count}
      />
    </div>
  )
}

const CountriesWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${spacing.XS};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`
