import { Dispatch, SetStateAction } from "react"
import styled from "styled-components"

import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchIndoorClimateMonitoringPresets,
  useFetchNoiseMonitoringPresets,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import {
  TICMMonitoringPreset,
  TMonitoringPresetOptions,
  TNoiseMonitoringPreset,
  TSelectedPresetId,
} from "src/data/profileSettings/types/monitoringPresetTypes"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function SelectMonitoringPresetStep({
  selectedPresetId,
  setSelectedPresetId,
  pluralizeText = false,
}: {
  selectedPresetId: TSelectedPresetId
  setSelectedPresetId: Dispatch<SetStateAction<TSelectedPresetId>>
  pluralizeText?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { debug } = useFlags()
  const { noise_profiles_released: presets } = useBackendFlags()

  const { orgId } = useOrganization()

  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets({
    orgId,
    enabled: presets,
  })
  // ICM presets is currently using mocked data
  const fetchICMPresets = useFetchIndoorClimateMonitoringPresets({
    enabled: presets && debug,
  })

  const noisePresets = fetchNoiseMonitoringPresets.data?.profiles
  const icmPresets = fetchICMPresets.data?.profiles

  const isLoading =
    fetchNoiseMonitoringPresets.isInitialLoading ||
    fetchICMPresets.isInitialLoading

  const isPresetError =
    fetchNoiseMonitoringPresets.isError || fetchICMPresets.isError

  if (isLoading) {
    return (
      <PresetStepWrapper pluralizeText={pluralizeText}>
        <MSkeleton variant="rect" height={40} />
      </PresetStepWrapper>
    )
  }

  if (isPresetError || !noisePresets) {
    return (
      <PresetStepWrapper pluralizeText={pluralizeText}>
        <MBanner title={t(langKeys.failed_something_went_wrong)} type="error" />
      </PresetStepWrapper>
    )
  }

  return (
    <PresetStepWrapper pluralizeText={pluralizeText}>
      <MonitoringPresetStepContent
        selectedPresetId={selectedPresetId}
        setSelectedPresetId={setSelectedPresetId}
        noisePresets={noisePresets}
        icmPresets={icmPresets ?? []}
      />
    </PresetStepWrapper>
  )
}

function MonitoringPresetStepContent({
  noisePresets,
  icmPresets,
  selectedPresetId,
  setSelectedPresetId,
}: {
  noisePresets: TNoiseMonitoringPreset[]
  icmPresets: TICMMonitoringPreset[]
  selectedPresetId: TSelectedPresetId
  setSelectedPresetId: Dispatch<SetStateAction<TSelectedPresetId>>
}) {
  const { t, langKeys } = useTranslate()
  const { debug } = useFlags()
  const { noise_profiles_released: presets } = useBackendFlags()

  useEffectOnce(() => {
    setSelectedPresetId({
      // @ts-expect-error: noUncheckedIndexedAccess
      noise: noisePresets[0]?.id,
      // @ts-expect-error: noUncheckedIndexedAccess
      icm: icmPresets[0]?.id,
    })
  })

  const monitoringPresets: TMonitoringPresetOptions[] = [
    {
      type: "noise",
      label: t(langKeys.sound_noise_monitoring),
      options: noisePresets.map((p) => ({ label: p.name, value: p.id })),
      defaultPresetId: selectedPresetId.noise,
    },
    {
      type: "icm",
      label: t(langKeys.indoor_climate),
      options: icmPresets.map((p) => ({ label: p.name, value: p.id })),
      defaultPresetId: selectedPresetId.icm,
    },
  ]

  // ICM is only for demo purposes so we filter it out if debug is disabled. The API for ICM is not implemented yet.
  const filteredMonitoringPresets = monitoringPresets.filter((preset) =>
    presets && debug ? true : preset.type !== "icm"
  )

  return (
    <>
      {filteredMonitoringPresets.map((preset) => {
        return (
          <MSelect
            key={preset.type}
            label={preset.label}
            options={preset.options}
            value={selectedPresetId[preset.type] || preset.defaultPresetId}
            onChange={(value) =>
              setSelectedPresetId((prev) => ({
                ...prev,
                [preset.type]: value,
              }))
            }
            required
          />
        )
      })}
    </>
  )
}

function PresetStepWrapper({
  children,
  pluralizeText,
}: {
  children: React.ReactNode
  pluralizeText: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <div>
        <MText variant="heading2" style={{ marginBottom: spacing.XS }}>
          {t(langKeys.home_create_select_preset_title, {
            count: pluralizeText ? 2 : 1,
          })}
        </MText>
        <MText color="secondary">
          {t(langKeys.home_create_select_preset_description, {
            count: pluralizeText ? 2 : 1,
          })}
        </MText>
      </div>

      {children}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
`
