import SearchIcon from "src/ui/icons/search.svg"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MTextField, TMTextFieldProps } from "src/ui/MTextField/MTextField"

type MSearchFieldProps = TMTextFieldProps & {
  isLoading?: boolean
}

export function MSearchField({
  value,
  placeholder,
  onChange,
  onClear,
  disabled,
  isLoading,
}: MSearchFieldProps) {
  return (
    <MTextField
      value={value}
      onChange={onChange}
      onClear={onClear}
      placeholder={placeholder}
      disabled={disabled}
      endAdornment={
        !isLoading ? (
          <SearchIcon width={18} color="unset" />
        ) : (
          <MCircularProgress size={18} />
        )
      }
      size="small"
      rounded
    />
  )
}
