import { TParadiseShippableProductsQueryParams } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import {
  TSetSearchParamsProp,
  TSortedSearchParams,
  useSearchParams,
} from "src/router/useSearchParams"
import { Nullable } from "src/utils/tsUtil"

type TParadiseShippableProductsFilterConfig = {
  [K in keyof Required<
    Omit<
      TParadiseShippableProductsQueryParams,
      "limit" | "sort_by" | "sort_order"
    >
  > as `shippable_products_${K}`]: K extends "currency_code"
    ? string
    : NonNullable<TParadiseShippableProductsQueryParams[K]>
}

export type TParadiseShippableProductsFilters =
  Nullable<TParadiseShippableProductsFilterConfig>
export type TSortedParadiseShippableProductsFilters =
  TSortedSearchParams<TParadiseShippableProductsFilterConfig>

export function useParadiseShippableProductsFilter(): {
  filter: TParadiseShippableProductsFilters
  sortedFilters: TSortedParadiseShippableProductsFilters
  setFilter: TSetSearchParamsProp<TParadiseShippableProductsFilters>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
    sortedSearchParams: sortedFilters,
  } = useSearchParams({
    keys: [
      {
        key: "shippable_products_sku",
        type: "string",
      },
      {
        key: "shippable_products_currency_code",
        type: "string",
      },
      {
        key: "shippable_products_include_deleted",
        type: "boolean",
      },
      {
        key: "shippable_products_offset",
        type: "number",
      },
    ],
  })

  return {
    filter,
    // We do not count pagination as a filter
    sortedFilters: sortedFilters.filter(
      (f) => f.key !== "shippable_products_offset"
    ),
    setFilter: (key, value) => {
      if (key === "shippable_products_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        shippable_products_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("shippable_products_offset", offset)
    },
    offset: filter.shippable_products_offset || 0,
  }
}
