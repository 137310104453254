import { useMemo } from "react"

import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseQuoteActions } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteDetails/ParadiseQuoteActions"
import { ParadiseQuoteCart } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteDetails/ParadiseQuoteCart"
import { ParadiseQuoteDetailsMeta } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteDetails/ParadiseQuoteDetailsMeta"
import { ParadiseQuoteOverview } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteDetails/ParadiseQuoteOverview"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchParadiseQuote } from "src/data/paradise/paradiseQuotes/paradiseQuoteQueries"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseQuoteDetails({ id }: { id: string }) {
  const { t, langKeys } = useTranslate()

  const fetchParadiseQuote = useFetchParadiseQuote({
    id,
  })

  const associatedUserIds = [
    fetchParadiseQuote.data?.sales_rep_user_id,
    fetchParadiseQuote.data?.customer_id,
  ].filter((id) => id !== undefined)
  const fetchAssociatedUsers = useFetchParadiseUsers({
    filter: {
      ids: associatedUserIds.join(",") || undefined,
    },
    options: {
      enabled: fetchParadiseQuote.isSuccess && associatedUserIds.length > 0,
    },
  })

  const combinedQuoteWithUsers = useMemo(() => {
    if (!fetchParadiseQuote.data) {
      return null
    }

    return {
      ...fetchParadiseQuote.data,
      salesRep: fetchAssociatedUsers.data?.users.find(
        (user) => user.id === fetchParadiseQuote.data?.sales_rep_user_id
      ),
      customer: fetchAssociatedUsers.data?.users.find(
        (user) => user.id === fetchParadiseQuote.data?.customer_id
      ),
    }
  }, [fetchParadiseQuote.data, fetchAssociatedUsers.data])

  useAddRecentlyViewedPage({
    title: fetchParadiseQuote.data?.id,
    enitytId: fetchParadiseQuote.data?.id,
    type: "Quote",
    enabled: fetchParadiseQuote.isSuccess,
  })

  if (fetchParadiseQuote.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (
    fetchParadiseQuote.isLoading ||
    fetchAssociatedUsers.isInitialLoading ||
    !combinedQuoteWithUsers
  ) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  return (
    <ParadiseDetailsLayout
      banner={!!combinedQuoteWithUsers.deleted_at && "Deleted"}
      meta={<ParadiseQuoteDetailsMeta quote={combinedQuoteWithUsers} />}
      actions={<ParadiseQuoteActions quoteId={combinedQuoteWithUsers.id} />}
      left={<ParadiseQuoteOverview quote={combinedQuoteWithUsers} />}
      right={<ParadiseQuoteCart quote={combinedQuoteWithUsers} />}
      breadcrumbs={[
        {
          label: "Quotes",
          to: Routes.ParadiseQuotes.location(),
        },
        {
          label: combinedQuoteWithUsers.id,
          to: Routes.ParadiseQuote.location(combinedQuoteWithUsers.id),
        },
      ]}
      breadcrumbCopyValue={combinedQuoteWithUsers.id}
    />
  )
}
