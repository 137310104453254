import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrderTrackingReference } from "src/components/Paradise/ParadiseOrders/ParadiseOrderTrackingReference"
import { chargebeeAddonUrl } from "src/constants/hrefs"
import { prettifyApiFieldValue } from "src/data/orders/orderUtils"
import { getWMSLink } from "src/data/paradise/paradiseOrders/logic/paradiseOrderLogic"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderFulfillment({ order }: { order: TParadiseOrder }) {
  return (
    <div>
      <Grid>
        <ParadiseDetailsSection title="Fulfillment">
          <WarehouseSection order={order} />
        </ParadiseDetailsSection>
      </Grid>
    </div>
  )
}

function WarehouseSection({ order }: { order: TParadiseOrder }) {
  return (
    <WarehouseGrid>
      <MText variant="bodyS" color="secondary">
        Assigned warehouse:
      </MText>
      <MText variant="bodyS">
        <ExternalLink
          href={getWMSLink(order.assigned_warehouse)}
          showExternalIcon
        >
          {prettifyApiFieldValue(order.assigned_warehouse)}
        </ExternalLink>
      </MText>

      <MText variant="bodyS" color="secondary">
        Shipping method:
      </MText>

      <MText variant="bodyS">
        {order.shipping_line_item?.shipping_method_id ? (
          <ExternalLink
            showExternalIcon
            openInNewTab
            href={chargebeeAddonUrl(
              order.shipping_line_item?.shipping_method_id
            )}
          >
            {order.shipping_line_item.name}
          </ExternalLink>
        ) : (
          "-"
        )}
      </MText>

      <MText variant="bodyS" color="secondary">
        Track order:
      </MText>

      <MText variant="bodyS">
        <ParadiseOrderTrackingReference
          trackingReference={order.tracking_reference}
          trackingUrl={order.tracking_url}
        />
      </MText>
    </WarehouseGrid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XS2};
`

const WarehouseGrid = styled.div`
  display: grid;
  column-gap: ${spacing.L};
  grid-template-columns: max-content 1fr;
`
