import styled from "styled-components"

import { spacing, Text } from "@minuthq/meatball-ui-react"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { TParadiseCombinedQuoteWithUsers } from "src/components/Paradise/ParadiseQuotes/paradiseQuoteTypes"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useTableColumns } from "src/ui/GridTable/useTableColumns/useTableColumns"

export function ParadiseQuoteCart({
  quote,
}: {
  quote: TParadiseCombinedQuoteWithUsers
}) {
  const cart = quote.cart

  const {
    headerElements: planAssociatedHeaderElements,
    rows: planAssociatedRows,
    templateColumns: planAssociatedTemplateColumns,
  } = useTableColumns<
    TParadiseCombinedQuoteWithUsers["cart"]["plan_associated_shippable_products"][number]
  >({
    columns: [
      {
        value: "product_id",
        label: "Product id",
        renderCell: (data) => <div>{data.product_id}</div>,
        columnWidth: "auto",
      },
      {
        value: "quantity",
        label: "Quantity",
        renderCell: (data) => <div>{data.quantity}</div>,
        columnWidth: "1fr",
      },
    ],
    data: cart.plan_associated_shippable_products,
  })

  const {
    headerElements: additionalHeaderElements,
    rows: additionalRows,
    templateColumns: additionalTemplateColumns,
  } = useTableColumns<
    NonNullable<
      TParadiseCombinedQuoteWithUsers["cart"]["additional_shippable_products"]
    >[number]
  >({
    columns: [
      {
        value: "product_id",
        label: "Product id",
        renderCell: (data) => <div>{data.product_id}</div>,
        columnWidth: "auto",
      },
      {
        value: "quantity",
        label: "Quantity",
        renderCell: (data) => <div>{data.quantity}</div>,
        columnWidth: "1fr",
      },
    ],
    data: cart.additional_shippable_products,
  })

  return (
    <ParadiseDetailsSection title="Quote Cart">
      <GridText>
        <Text variant="subtitleS">Plan Id:</Text>
        <Text variant="bodyS">{cart.plan_id}</Text>

        <Text variant="subtitleS">Home tokens quantity:</Text>
        <Text variant="bodyS">{cart.prepaid_home_quantity}</Text>

        <Text variant="subtitleS">Discount code:</Text>
        <Text variant="bodyS">{cart.discount_code}</Text>
      </GridText>

      <Text variant="subtitleS">Plan associated shippable products</Text>
      <ParadiseTable
        header={planAssociatedHeaderElements}
        rows={planAssociatedRows ?? []}
        templateColumns={planAssociatedTemplateColumns}
        removeMargin
      />

      <Text variant="subtitleS">Additional shippable products</Text>
      <ParadiseTable
        header={additionalHeaderElements}
        rows={additionalRows ?? []}
        templateColumns={additionalTemplateColumns}
        removeMargin
      />
    </ParadiseDetailsSection>
  )
}

const GridText = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS};

  margin-bottom: ${spacing.M};
`
