import { useState } from "react"

import { Banner, spacing, Text } from "@minuthq/meatball-ui-react"

import { ParadiseQuoteGenerateLinkDialog } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteDetails/ParadiseQuoteGenerateLink"
import { useDeleteParadiseQuote } from "src/data/paradise/paradiseQuotes/paradiseQuoteQueries"
import { DropdownListButton, IOption } from "src/ui/Button/DropdownListButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"

export function ParadiseQuoteActions({ quoteId }: { quoteId: string }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showGenerateLinkDialog, setShowGenerateLinkDialog] = useState(false)

  const deleteParadiseQuote = useDeleteParadiseQuote()

  function deleteQuote() {
    deleteParadiseQuote.mutate(quoteId, {
      onSuccess: () => {
        setShowDeleteDialog(false)
      },
    })
  }

  const options: IOption<"delete-quote" | "get-link">[] = [
    {
      name: "Get link",
      value: "get-link",
      onClick: () => {
        setShowGenerateLinkDialog(true)
      },
    },
    {
      name: "Delete quote",
      value: "delete-quote",
      onClick: () => {
        setShowDeleteDialog(true)
      },
      color: "destructive",
    },
  ]

  return (
    <div>
      <DropdownListButton displayValue="Quote actions" options={options} />

      <ConfirmDialog
        title="Delete quote"
        confirmLabel="Delete"
        onConfirm={deleteQuote}
        confirmButtonProps={{
          color: "destructive",
        }}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        width="medium"
      >
        <Text variant="subtitle" marginBottom={spacing.M}>
          Are you sure you want to delete this quote?
        </Text>
        <Banner type="warning" fullWidth>
          Customers will no longer be able to purchase this quote
        </Banner>
      </ConfirmDialog>

      <ParadiseQuoteGenerateLinkDialog
        open={showGenerateLinkDialog}
        onClose={() => setShowGenerateLinkDialog(false)}
        quoteId={quoteId}
      />
    </div>
  )
}
