import { useState } from "react"
import styled from "styled-components"

import { spacing, Text } from "@minuthq/meatball-ui-react"

import { ParadiseCopyBox } from "src/components/Paradise/ParadiseCopyBox"
import { ParadiseCountrySelector } from "src/components/Paradise/ParadiseCountrySelector"
import { generateQuoteLink } from "src/components/Paradise/ParadiseQuotes/paradiseQuoteUtils"
import { useFetchShippingDestinations } from "src/data/ecommerce/ecommerceQueries"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"

export function ParadiseQuoteGenerateLinkDialog({
  open,
  onClose,
  quoteId,
}: {
  open: boolean
  onClose: () => void
  quoteId: string
}) {
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("")
  const fetchShippingDestinations = useFetchShippingDestinations()

  return (
    <MDialog title="Get link" open={open} onClose={onClose} width="medium">
      <Text marginBottom={spacing.M}>
        Make sure to select the same shipping country as when the quote was
        created.
      </Text>

      <ParadiseCountrySelector
        name="Country code"
        countryCodes={
          fetchShippingDestinations.data?.map(
            (country) => country.country_code
          ) ?? []
        }
        selectedCountryCode={selectedCountryCode}
        onSelectCountryCode={setSelectedCountryCode}
        label="Country"
      />
      <CopyBoxWrapper>
        {!selectedCountryCode ? (
          <MBanner type="info" fullWidth>
            Please select a country
          </MBanner>
        ) : (
          <ParadiseCopyBox
            text={generateQuoteLink(quoteId, selectedCountryCode)}
          />
        )}
      </CopyBoxWrapper>
    </MDialog>
  )
}

const CopyBoxWrapper = styled.div`
  margin-top: ${spacing.M};
`
