import {
  ChangeEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  Ref,
} from "react"
import styled from "styled-components"

import {
  InputContainer,
  TInputContainerProps,
} from "src/ui/InputContainer/InputContainer"
import { canShowLabel } from "src/ui/InputContainer/inputContainerUtils"

export type TMTextFieldAcceptedTypes =
  | "text"
  | "number"
  | "search"
  | "email"
  | "url"
  | "tel"
  | "password"

type THTMLInputAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "size"
>

export type TMTextFieldProps = THTMLInputAttributes & {
  type?: Extract<HTMLInputTypeAttribute, TMTextFieldAcceptedTypes>
  /**
   * The value of the input field.
   *
   * If you want to control the value of the input field, you should pass the value and update it when the onChange event is triggered.
   * If this is not passed, the input field will be uncontrolled.
   */
  value?: string
  placeholder?: string
  ariaLabel?: string
  onChange?: (value: string, e: ChangeEvent) => void
  disabled?: boolean
  className?: string
} & TInputContainerProps

function _MTextField(
  {
    type = "text",
    value,
    label,
    placeholder,
    ariaLabel,
    onChange,
    onClear,
    error,
    helperText,
    disabled,
    startAdornment,
    endAdornment,
    requiredIndicator,
    rounded,
    size = "medium",
    className,
    disableClearButton,
    ...props
  }: TMTextFieldProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <Wrapper className={className}>
      <InputContainer
        {...(canShowLabel(size)
          ? {
              label,
              size,
            }
          : {
              size,
            })}
        error={error}
        showClearButton={!!onClear && !!value}
        onClear={onClear}
        helperText={helperText}
        disabled={disabled}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        requiredIndicator={requiredIndicator}
        cursor="text"
        rounded={rounded}
        disableClearButton={disableClearButton}
      >
        <StyledInput
          ref={ref}
          type={type}
          value={value}
          onChange={(e) => onChange?.(e.target.value, e)}
          placeholder={placeholder}
          aria-label={ariaLabel || label}
          aria-errormessage={typeof error === "string" ? error : undefined}
          aria-invalid={!!error}
          disabled={disabled}
          {...props}
        />
      </InputContainer>
    </Wrapper>
  )
}

export const MTextField = forwardRef<HTMLInputElement, TMTextFieldProps>(
  _MTextField
)

/**
 * If the caller would extend the MTextField component, the modified/added CSS properties would be set to this wrapper
 * Oherwise, the styles would be applied to the input field
 */
const Wrapper = styled.div``

const StyledInput = styled.input`
  all: unset;
  width: 100%;
  text-align: left;
`
