import styled from "styled-components"

import { spacing } from "@minuthq/meatball-ui-react"

import { ParadiseShippingDestinations } from "src/components/Paradise/ParadiseShipping/ParadiseShippingDestinations/ParadiseShippingDestinations"
import { ParadiseShippingMethods } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethods"
import {
  ParadiseLayout,
  ParadiseTitle,
} from "src/components/Paradise/sharedStyles"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function ParadiseShipping() {
  const tabs: ITab[] = [
    {
      id: "shipping_methods",
      label: "Shipping methods",
      view: <ParadiseShippingMethods />,
    },
    {
      id: "shipping_destinations",
      label: "Shipping destinations",
      view: <ParadiseShippingDestinations />,
    },
  ]

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={<ParadiseTitle>Shipping</ParadiseTitle>}
      />
      <Tabs labels={tabs}>
        {tabs.map((tab) => (
          <StyledTabPanel key={tab.id}>{tab.view}</StyledTabPanel>
        ))}
      </Tabs>
    </ParadiseLayout>
  )
}

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`
