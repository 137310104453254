import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { countryCodeToCountry } from "src/data/countries/countryUtil"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MText } from "src/ui/MText"

export function ParadiseOrderAddressSection({
  title,
  shippingAddress,
}: {
  title: string
  shippingAddress:
    | TParadiseOrder["shipping_address"]
    | TParadiseOrder["billing_address"]
}) {
  return (
    <div>
      <ParadiseSettingStatic
        title={title}
        value={
          <>
            <MText variant="bodyS">
              {shippingAddress.first_name} {shippingAddress.last_name}
            </MText>

            {shippingAddress.company && (
              <MText variant="bodyS">{shippingAddress.company}</MText>
            )}

            <MText variant="bodyS">{shippingAddress.phone}</MText>

            <MText variant="bodyS">{shippingAddress.line1}</MText>

            {shippingAddress.line2 && (
              <MText variant="bodyS">{shippingAddress.line2}</MText>
            )}

            <div>
              <MText variant="bodyS" as="span">
                {shippingAddress.zip}{" "}
              </MText>

              <MText variant="bodyS" as="span">
                {shippingAddress.city}{" "}
              </MText>

              <MText variant="bodyS" as="span">
                {shippingAddress.state_code}{" "}
              </MText>
            </div>

            <MText variant="bodyS">
              {countryCodeToCountry(shippingAddress.country)}
            </MText>
          </>
        }
      />
    </div>
  )
}
