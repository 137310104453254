import styled, { css } from "styled-components"

import { mColors } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import ExternalIcon from "src/ui/icons/external-link.svg"
import { MTextProps } from "src/ui/MText"

export type MLinkProps = {
  href: string
  children?: React.ReactNode
  style?: React.CSSProperties
  tabIndex?: number
  /** Use this if you want to perform an action before navigating */
  beforeNav?: () => void
  openInNewTab?: boolean
  showExternalIcon?: boolean
  underline?: "always" | "onHover" | "never"
  color?: MTextProps["color"]
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export function MLink({
  href,
  children,
  tabIndex,
  beforeNav,
  openInNewTab = false,
  showExternalIcon = false,
  underline = "always",
  color = "primary",
  ...rest
}: MLinkProps) {
  return (
    <StyledAnchorTag
      href={href}
      tabIndex={tabIndex}
      onClick={() => beforeNav?.()}
      $underline={underline}
      $color={color}
      rel={openInNewTab ? "noopener noreferrer" : ""}
      target={openInNewTab ? "_blank" : "_self"}
      {...rest}
    >
      {children}
      {showExternalIcon && <StyledExternalIcon width={12} height={12} />}
    </StyledAnchorTag>
  )
}

export type MLinkStyleProps = {
  $underline?: "always" | "onHover" | "never"
  $color?: MTextProps["color"]
  $noWrap?: boolean
}

export const MLinkStyle = css<MLinkStyleProps>`
  font-weight: ${fontWeight.semiBold};
  font-size: inherit;
  line-height: 150%;

  color: ${({ $color }) => getTextColor($color)};

  text-decoration: ${({ $underline = "always" }) => {
    if ($underline === "always") {
      return "underline"
    }

    return "none"
  }};

  &:hover {
    text-decoration: ${({ $underline = "always" }) => {
      if ($underline === "always") {
        return "underline"
      }

      if ($underline === "onHover") {
        return "underline"
      }

      return "none"
    }};
  }
`

const StyledAnchorTag = styled.a<MLinkStyleProps>`
  ${MLinkStyle};
  display: inline-flex;
  align-items: center;
`

const StyledExternalIcon = styled(ExternalIcon)`
  font-size: inherit;
  margin-right: 0.7ch;
  margin-left: 0.2ch;
`

function getTextColor(color: MTextProps["color"]) {
  switch (color) {
    case "primary":
      return mColors.primary
    case "secondary":
      return mColors.textSecondary
    case "tertiary":
      return mColors.textTertiary
    case "emergency":
      return mColors.systemErrorDark
    case "contrast":
      return mColors.textContrast
    case "unset":
    default:
      return "unset"
  }
}
