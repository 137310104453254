import {
  TPlanUpgradeClickedEventProperties,
  usePostPlanUpgradeClicked,
} from "src/data/analytics/queries/planUpgradeAnalyticsQueries"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"

export type { TUpgradeBlockerContext } from "src/data/analytics/queries/planUpgradeAnalyticsQueries"

type UpgradeBlockerButtonProps = {
  eventProperties: TPlanUpgradeClickedEventProperties
  plan?: TPlan
}

export function UpgradeBlockerButton({
  eventProperties,
  plan,
  ...props
}: TMButtonProps & UpgradeBlockerButtonProps) {
  const { navigate } = useRouter()
  const postPlanUpgradeClicked = usePostPlanUpgradeClicked()
  const { t, langKeys } = useTranslate()

  function handleClick() {
    postPlanUpgradeClicked.mutate({ ...eventProperties })
    navigate(Routes.ChangePlan.location({ plan }))
  }

  return (
    <MButton onClick={handleClick} {...props}>
      {t(langKeys.subscription_dialog_positive_action)}
    </MButton>
  )
}
