import styled from "styled-components"

import { HREF_CIGARETTE_FALSE_NEGATIVE_REPORT_FORM } from "src/constants/hrefs"
import {
  usePostSmokingDetectedClicked,
  usePostSmokingNotDetectedClicked,
} from "src/data/analytics/queries/smokingDetectionAnalyticQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function SmokingDetectionFeedback({ homeId }: { homeId: string }) {
  const { t, langKeys } = useTranslate()

  const postSmokingDetectedClicked = usePostSmokingDetectedClicked()
  function handleBeforeSmokingDetectedClick() {
    postSmokingDetectedClicked.mutate({
      type: "reported_events",
      context: "feature_card",
    })
  }

  const postSmokingNotDetectedClicked = usePostSmokingNotDetectedClicked()
  function handleBeforeSmokingNotDetectedClick() {
    postSmokingNotDetectedClicked.mutate({
      type: "unreported_events",
      context: "feature_card",
    })
  }

  return (
    <SmokeFeedbackSection>
      <MText variant="subtitleS">
        {t(langKeys.smoking_detection_feedback_title)}
      </MText>
      <MText variant="bodyS" color="secondary" textAlign="start">
        <Ul>
          <Li>
            {t(langKeys.smoking_detection_feedback_detection_correct)}{" "}
            <InternalLink
              to={Routes.HomeLog.location({ homeId })}
              beforeNav={handleBeforeSmokingDetectedClick}
            >
              {t(langKeys.share_feedback)}
            </InternalLink>
          </Li>
          <Li>
            {t(langKeys.smoking_detection_feedback_detection_missed_alert)}
            <ExternalLink
              href={HREF_CIGARETTE_FALSE_NEGATIVE_REPORT_FORM}
              beforeNav={handleBeforeSmokingNotDetectedClick}
            >
              {t(langKeys.report_it_here)}
            </ExternalLink>
          </Li>
        </Ul>
      </MText>
    </SmokeFeedbackSection>
  )
}
const SmokeFeedbackSection = styled.section`
  display: grid;
  gap: ${spacing.S};
`

const Ul = styled.ul`
  margin: unset;
  padding-inline-start: 20px;
`
const Li = styled.li``
