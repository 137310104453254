import styled from "styled-components"

import { MChip } from "src/components/MChip/MChip"
import { mColors } from "src/ui/colors"
import XIcon from "src/ui/icons/x.svg"
import { spacing } from "src/ui/spacing"

type TAppliedFilter = {
  label: string
  value: string | null
  onRemove: () => void
}

export type ParadiseAppliedFiltersProps = {
  filters: TAppliedFilter[]
}

export function ParadiseAppliedFilters({
  filters,
}: ParadiseAppliedFiltersProps) {
  if (filters.length === 0) {
    return null
  }

  return (
    <Wrapper>
      {filters.map((filter) => {
        if (!filter.value) {
          return null
        }

        return (
          <MChip
            key={filter.label}
            label={
              <StyledDl>
                <dt>{filter.label}</dt>
                <Separator />
                <dd>{filter.value}</dd>
              </StyledDl>
            }
            Icon={XIcon}
            onClick={filter.onRemove}
            size="small"
            borderType="dashed"
          />
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spacing.M};
`

const StyledDl = styled.dl`
  display: inline-flex;
  gap: ${spacing.XS};
  align-items: center;
  margin: 0;

  > dd,
  > dt {
    margin: 0;
  }
`

const Separator = styled.div`
  height: 0.75rem;
  width: 1px;
  background-color: ${mColors.divider};
`
