import { useCallback } from "react"

import { useUrlParamNumber } from "src/hooks/useUrlParam"

const KEY_OFFSET = "offset"
const KEY_LIMIT = "limit"

export function useUrlPager(props?: {
  offsetKey?: string
  limitKey?: string
  initialOffset?: number
  initialLimit?: number
}) {
  const {
    value: offset,
    pushValue: setOffset,
    replaceValue: replaceOffset,
  } = useUrlParamNumber(props?.offsetKey ?? KEY_OFFSET)
  const { value: limit, pushValue: _setLimit } = useUrlParamNumber(
    props?.limitKey ?? KEY_LIMIT
  )

  /** Whenever the limit changes we should also reset the offset */
  const setLimit = useCallback(
    (n: number) => {
      _setLimit(n)
      replaceOffset(0)
    },
    [_setLimit, replaceOffset]
  )

  return {
    setLimit,
    setOffset,
    replaceOffset,
    limit: limit ?? props?.initialLimit ?? 15,
    offset: offset ?? props?.initialOffset ?? 0,
  }
}
