import { TParadiseShippingMethodsFilters } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/useParadiseShippingMethodsFilter"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { getCountryDisplayName } from "src/data/countries/countryUtil"
import { useFetchParadiseShippingDestinations } from "src/data/paradise/paradiseShipping/paradiseShippingQueries"
import { TParadiseShippingDestination } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { Routes } from "src/router/routes"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { InternalLink } from "src/ui/Link/InternalLink"

export function ParadiseShippingDestinations() {
  const fetchParadiseShippingDestinations =
    useFetchParadiseShippingDestinations({
      options: {
        select: (data) =>
          // Client side sorting, due to API not being paginated
          data.toSorted((a, b) => {
            const aCountryName =
              getCountryDisplayName({
                countryCode: a.country_code,
                locale: "en",
                fallback: "code",
              }) || a.country_code

            const bCountryName =
              getCountryDisplayName({
                countryCode: b.country_code,
                locale: "en",
              }) || b.country_code

            return aCountryName.localeCompare(bCountryName)
          }),
      },
    })

  const tableColumns: TableColumn<TParadiseShippingDestination>[] = [
    {
      label: "Country",
      value: "country_code",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          {getCountryDisplayName({
            countryCode: data.country_code,
            locale: "en",
          })}{" "}
          ({data.country_code})
        </div>
      ),
    },
    {
      label: "Currency code",
      value: "currency_code",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.currency_code}</div>,
    },
    {
      label: "Stripe payment gateway",
      value: "payment_gateway_stripe",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{data.payment_gateway.stripe_api_gateway}</div>
      ),
    },
    {
      label: "Chargebee payment gateway",
      value: "payment_gateway_chargebee",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{data.payment_gateway.chargebee_api_gateway}</div>
      ),
    },
    {
      label: "Shipping methods",
      value: "shipping_methods",
      columnWidth: "auto",
      renderCell: (data) => (
        <InternalLink
          to={{
            pathname: Routes.ParadiseShipping.location().pathname,
            search: `?${"shipping_methods_countries" satisfies keyof TParadiseShippingMethodsFilters}=${data.country_code}`,
          }}
        >
          View shipping methods
        </InternalLink>
      ),
    },
  ]

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: tableColumns,
    data: fetchParadiseShippingDestinations.data,
    options: {
      disableColumnHiding: true,
    },
  })

  return (
    <div>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        isLoading={fetchParadiseShippingDestinations.isLoading}
        templateColumns={templateColumns}
      />
    </div>
  )
}
