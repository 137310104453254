import { useEffect, useRef, useState } from "react"

import { useSnackbar } from "notistack"

import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { NoiseMonitoringIcon } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringIcon"
import { NoiseMonitoringToggleActiveIcon } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringToggleIcon"
import { NoiseMonitoringState } from "src/data/homeActions/types/noiseMonitoringTypes"
import { Violations } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHomeDisturbanceMonitoring } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import {
  MonitoringButton,
  OnOffStatusLabel,
} from "src/ui/MonitoringButton/MonitoringButton"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { ErrorService } from "src/utils/ErrorService"

// TODO WEB-358: Split into multiple components

export function NoiseMonitoringButton({
  homeId,
  isActive,
  hideStatus,
  toggleAllowed,
  violations,
  graphicButton,
  row,
  monitoringState,
  ...props
}: {
  homeId: string
  isActive: boolean
  hideStatus?: boolean
  toggleAllowed: boolean
  violations?: Violations[]
  graphicButton?: boolean
  monitoringState: NoiseMonitoringState | undefined
  row?: boolean
  style?: React.ReactNode
}) {
  const { org } = useOrganization()
  const { t } = useTranslate()
  const patchHomeDisturbanceMonitoring = usePatchHomeDisturbanceMonitoring()
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const mTooltip = toggleAllowed
    ? getTooltipText({
        isActive,
        monitoringState,
        t,
      })
    : t(langKeys.not_enough_permissions)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setError(null)
  }

  const handleSetActiveState = async (active: boolean) => {
    handleClose()

    setIsUpdating(true)
    const onError = (error: Error) => {
      ErrorService.captureException(error)

      enqueueSnackbar(t(langKeys.failed_contact_support), {
        variant: "error",
      })
    }

    const orgId = org?.id
    if (!orgId) {
      throw Error("No active team")
    }

    patchHomeDisturbanceMonitoring.mutate(
      { orgId: org.id, homeId, data: { active } },
      { onError }
    )

    mounted.current && setIsUpdating(false)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <MTooltip title={mTooltip} placement="bottom">
        <div>
          {!!graphicButton ? (
            <MonitoringButton
              label={<OnOffStatusLabel isOn={isActive} />}
              labelType={isActive ? "good" : "default"}
              onClick={handleClick}
              updating={isUpdating}
              icon={
                <NoiseMonitoringIcon
                  active={isActive}
                  state={monitoringState}
                  iconProps={{ height: "30", width: "30" }}
                />
              }
              hideStatus={hideStatus}
              disabled={!toggleAllowed}
              row={row}
              {...props}
            >
              {t(langKeys.sound_noise_monitoring)}
            </MonitoringButton>
          ) : (
            <MButton
              variant="subtle"
              size="small"
              onClick={handleClick}
              loading={isUpdating}
              disabled={!toggleAllowed}
            >
              {t(langKeys.manage)}
            </MButton>
          )}
        </div>
      </MTooltip>

      <MonitoringPopup
        open={open}
        error={error}
        anchorEl={anchorEl}
        onClose={handleClose}
        title={t(langKeys.sound_noise_monitoring)}
        description={t(langKeys.disturbance_alarm_control_short_description)}
        violations={violations}
        warning={t(langKeys.subscription_violations_noise_warning)}
        buttons={[
          {
            icon: (
              <NoiseMonitoringToggleActiveIcon
                toggleState="off"
                active={isActive}
                iconProps={{ height: "56" }}
              />
            ),
            onClick: () => handleSetActiveState(false),
            label: t(langKeys.off),
            disablePointerEvents: !isActive,
            id: "noiseMonitoringOff",
          },
          {
            icon: (
              <NoiseMonitoringToggleActiveIcon
                toggleState="on"
                active={isActive}
                iconProps={{ height: "56" }}
              />
            ),
            onClick: () => handleSetActiveState(true),
            label: t(langKeys.on),
            disablePointerEvents: !!isActive,
            disableOnWarning: true,
            id: "noiseMonitoringOn",
          },
        ]}
      />
    </>
  )
}

function getTooltipText({
  isActive,
  monitoringState,
  t,
}: {
  isActive: boolean
  monitoringState: NoiseMonitoringState | undefined
  t: TTranslateFunction
}) {
  if (!isActive) {
    return t(langKeys.noise_monitoring_status_off)
  }

  if (monitoringState === "idle") {
    return t(langKeys.noise_monitoring_status_on)
  }

  return t(langKeys.noise_monitoring_status_ongoing)
}
