import styled from "styled-components"

import { GridTable, IGridTable } from "src/ui/GridTable/GridTable"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { spacing } from "src/ui/spacing"

export function ParadiseTable({
  header,
  rows,
  templateColumns,
  error,
  onRowClick,
  isLoadingMore,
  isLoading,
  removeMargin,
  ...props
}: {
  error?: {
    hasError: boolean
    title: React.ReactNode
    description?: React.ReactNode
  }
  isLoadingMore?: boolean
  isLoading?: boolean
  removeMargin?: boolean
} & Pick<
  IGridTable,
  | "header"
  | "rows"
  | "templateColumns"
  | "onRowClick"
  | "disableRowHover"
  | "showHeadingBackground"
>) {
  return (
    <>
      <TableWrapper $removeMargin={removeMargin} $dimmed={isLoadingMore}>
        <GridTable
          header={header}
          rows={rows ?? []}
          mobileRows={rows ?? []}
          templateColumns={templateColumns}
          minWidth={0}
          cellSize={"xSmall"}
          onRowClick={onRowClick}
          horizontalScroll
          loading={isLoading}
          {...props}
        />
      </TableWrapper>
      {error && error.hasError && rows.length === 0 && (
        <InfoBox type="warning" title={error.title ?? "An error occured"}>
          {error.description}
        </InfoBox>
      )}
    </>
  )
}

const TableWrapper = styled.div<{ $removeMargin?: boolean; $dimmed?: boolean }>`
  margin-block: ${({ $removeMargin }) => ($removeMargin ? 0 : spacing.S)};
  white-space: nowrap;
  font-size: 0.75rem;
  opacity: ${({ $dimmed }) => ($dimmed ? "0.7" : "1")};
`
