import { components, paths } from "@minuthq/minut-api-types/schema"

import { HomeGroupRole } from "src/data/homeGroups/types/homeGroupTypes"
import {
  HomeRole,
  IOrganizationMember,
  TOrganizationResponse,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import {
  IBasePaginatedEndpoint,
  IPaging,
  IPagingFilter,
} from "src/data/pagination/types/paginationTypes"

export const DEFAULT_ORG_ID = ""

/*
 * The role within the organization the invited user will have.
 *
 * If not set the user will only see the homes they're explicit members of.
 *
 * If this field is set the user will have access to all homes, with the
 * corresponding home role, belonging to the organization.
 */

export enum InvitationState {
  PENDING = "pending",
  ACCEPTED = "accepted",
}

export enum InvitationType {
  HOME = "home",
  HOMEGROUP = "homegroup",
  ORGANIZATION = "organization",
}

export interface IOrganizationInvitationPost {
  email: string
  role: TOrganizationRole | HomeRole | HomeGroupRole
  invitation_target_id: string
  accept_url: string
  type: InvitationType
}

export type TOrganizationInvitationsResponse =
  paths["/organizations/{organization_id}/invitations"]["get"]["responses"]["200"]["content"]["application/json"]

export type TOrganizationInvitation = components["schemas"]["Invitation"]

export type TInvitationInfo = {
  organization_name: string
  existing_user: boolean
  invitation_email: string
}

export interface IOrganizationInvitationMembersFilter extends IPagingFilter {
  invitation_state?: InvitationState
}

export interface IOrganizationInvitationUserFilter extends IPagingFilter {
  invitation_state?: InvitationState
  invitation_type?: InvitationType
}

export interface IFetchOrganizations extends IBasePaginatedEndpoint {
  organizations: IOrganization[]
}

export type TOrganizationPatch =
  paths["/organizations/{organization_id}"]["patch"]["requestBody"]["content"]["application/json"]

export interface IOrganizationGetParams {
  // Limit how many results to fetch. Default is 20. Only takes effect if the request is made by a superadmin.
  limit?: number // [ 1 .. 20 ] Default: 20

  // Starting offset when fetching results. Default is 0. Only takes effect if the request is made by a superadmin.
  offset?: number // >= 0 Default: 0

  // The id of a specific organization. Only takes effect if the request is made by a superadmin.
  id?: string

  // The id of the owner of the organization Only takes effect if the request is made by a superadmin.
  owner_id?: string

  // The id of the member of the organization Only takes effect if the request is made by a superadmin.
  membership_user_id?: string

  //The name of the organiation. Only takes effect if the request is made by a superadmin.
  name?: string
}

export interface IMembersFilter extends Partial<IPaging> {
  filter?: ("owner" | "self")[]
}
export interface IFetchMembers extends IBasePaginatedEndpoint {
  members: IOrganizationMember[]
}

export interface IOrganizationResponse extends TOrganizationResponse {}

export interface IOrganization extends TOrganizationResponse {}

export type TOrganizationUpdateTagsBody = {
  tags: string[]
}

export type TOrganizationNbrResponse = {
  total: number
  owned: number
}

export type TFetchCreateHomeEstimateResponse =
  paths["/organizations/{organization_id}/estimates/create_home_estimate"]["post"]["responses"]["200"]["content"]["application/json"]

export type TFetchCreateHomeEstimateResponseError =
  paths["/organizations/{organization_id}/estimates/create_home_estimate"]["post"]["responses"]["400"]["content"]["application/json"]

export type TDeleteMultipleHomesQuery =
  paths["/organizations/{organization_id}/homes"]["delete"]["parameters"]["query"]

export type TDeleteMultipleHomesResponse =
  paths["/organizations/{organization_id}/homes"]["delete"]["responses"]["204"]["content"]["application/json"]

export type TOrganizationAnalyticsEventQueryParams =
  paths["/organizations/{organization_id}/analytics/events"]["get"]["parameters"]["query"]

export type TOrganizationAnalyticsStateQueryParams =
  paths["/organizations/{organization_id}/analytics/states"]["get"]["parameters"]["query"]

export type TOrganizationAnalyticsMetricQueryParams =
  paths["/organizations/{organization_id}/analytics/metrics"]["get"]["parameters"]["query"]
