import styled from "styled-components"

import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderTrackingReference({
  trackingReference,
  trackingUrl,
}: {
  trackingReference: TParadiseOrder["tracking_reference"]
  trackingUrl: TParadiseOrder["tracking_url"]
}) {
  if (!trackingReference && !trackingUrl) {
    return null
  }

  return (
    <Box>
      <ExternalLink href={trackingUrl || ""}>
        {trackingReference || trackingUrl}
      </ExternalLink>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${spacing.XS2};
`
