import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { MText } from "src/ui/MText"

type TPricePerPlan = NonNullable<
  TParadiseShippableProduct["price_per_plan_type"]
>[number]

export function ParadiseShippableProductPriceSection({
  shippingProduct,
}: {
  shippingProduct: TParadiseShippableProduct
}) {
  const tableColumns: TableColumn<TPricePerPlan>[] = [
    {
      value: "plan",
      label: "Plan",
      renderCell: (data) => <div>{data.plan}</div>,
    },
    {
      value: "formatted_price",
      label: "Formatted Price",
      renderCell: (data) => <div>{data.price.formatted_amount}</div>,
    },
    {
      value: "raw_price",
      label: "Amount",
      renderCell: (data) => <div>{data.price.amount}</div>,
    },
  ]

  const { headerElements, rows, templateColumns } =
    useTableColumns<TPricePerPlan>({
      columns: tableColumns,
      data: shippingProduct.price_per_plan_type,
    })

  return (
    <ParadiseDetailsSection title="Price">
      <MText>
        Default price (compare at price):{" "}
        <b>{shippingProduct.compare_at_price.formatted_amount}</b>
      </MText>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
      />
    </ParadiseDetailsSection>
  )
}
