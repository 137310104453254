import styled from "styled-components"

import { mColors } from "src/ui/colors"

type TAcceptedSizeUnits = "px" | "em" | "rem"
type TSize = `${number}${TAcceptedSizeUnits}` | number

export function MCircularProgress({
  size = 40,
  color = mColors.primary,
  style,
  className,
}: {
  /**
   * Size of the circular progress indicator.
   * If number the unit is pixels, if string the caller needs to provide the unit.
   */
  size?: TSize
  color?: string
  style?: React.CSSProperties
  className?: string
}) {
  return (
    <Box $size={size} $color={color} style={style} className={className}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle
          r="11"
          cy="12"
          cx="12"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          fill="transparent"
          strokeDasharray="20 70"
        ></circle>
      </svg>
    </Box>
  )
}

const Box = styled.span<{ $size: number | string; $color?: string }>`
  display: inline-block;
  color: ${({ $color }) => $color};
  width: ${({ $size }) => (typeof $size === "number" ? `${$size}px` : $size)};
  height: ${({ $size }) => (typeof $size === "number" ? `${$size}px` : $size)};
  animation: spin 1s ease infinite;

  svg {
    display: block;
    transform: rotate(-140deg);
    transform-origin: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
