import styled from "styled-components"

import { spacing } from "@minuthq/meatball-ui-react"

import { TParadiseCreateOrderCart } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/useParadiseCreateOrder"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { MText } from "src/ui/MText"
import { formatPrice } from "src/utils/formatPrice"

export function ParadiseCreateOrderSummary({
  cart,
  user,
  currencyCode,
}: {
  cart: TParadiseCreateOrderCart
  user: TParadiseUser | null
  currencyCode: TSubscriptionCurrencyCode
}) {
  const totalItemsCount = cart.shippableProducts.reduce((acc, product) => {
    return acc + product.quantity
  }, 0)

  return (
    <div>
      <MText variant="subtitle" marginBottom={spacing.M}>
        Order summary
      </MText>

      <MText variant="bodyS" marginBottom={spacing.M}>
        User: {user?.email ?? "-"}
      </MText>

      <Grid>
        <MText variant="bodyS">Subtotal</MText>
        <MText variant="bodyS">
          {totalItemsCount} {totalItemsCount === 1 ? "item" : "items"}
        </MText>
        <MText variant="bodyS">{formatPrice(0, currencyCode)}</MText>

        <MText variant="bodyS">Shipping</MText>
        <div></div>
        <MText variant="bodyS">{formatPrice(0, currencyCode)}</MText>

        <MText variant="subtitleS">Total</MText>
        <div></div>
        <MText variant="bodyS">{formatPrice(0, currencyCode)}</MText>
      </Grid>
    </div>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: ${spacing.M};
`
