export const monitoringPresetKeys = {
  all: () => {
    return ["presets"] as const
  },

  noisePresets: (filters?: {
    name?: string
    limit?: number
    offset?: number
  }) => {
    if (filters) {
      return [
        ...monitoringPresetKeys.all(),
        "noise",
        filters.name,
        filters.limit,
        filters.offset,
      ] as const
    }

    return [...monitoringPresetKeys.all(), "noise"] as const
  },

  noisePreset: (noisePresetId: string) => {
    return [...monitoringPresetKeys.all(), "noise", noisePresetId] as const
  },

  icmPresets: () => {
    return [...monitoringPresetKeys.all(), "icm"] as const
  },

  icmPreset: (icmPresetId: string) => {
    return [...monitoringPresetKeys.all(), "icm", icmPresetId] as const
  },
}
