import styled from "styled-components"

import { TClockType } from "src/data/user/user"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import {
  TIMELINE_COLUMN_GAP,
  TIMELINE_ICON_SIZE,
} from "src/ui/Timeline/timelineConstants"
import { formatDateAsTime } from "src/utils/l10n"
import { SVGProps } from "src/utils/tsUtil"

export type TTimeline = {
  timestamp: string
  description: string
  indicator?: {
    Icon: React.FC<SVGProps>
    color?: string
  }
  content?: React.ReactNode
}

export function TimelineItem({
  timelineItem,
  timeFormatter,
  clockType,
}: {
  timelineItem: TTimeline
  timeFormatter?: (timestamp: string) => string
  clockType?: TClockType
}) {
  const Icon = timelineItem.indicator?.Icon

  return (
    <div>
      <TimeLineWrapper>
        <IndicatorWrapper>
          {Icon ? (
            <Icon color={timelineItem.indicator?.color} />
          ) : (
            <IndicatorDot />
          )}
        </IndicatorWrapper>
        <TimestampWrapper>
          <MText variant="bodyS" color="secondary">
            {timeFormatter
              ? timeFormatter(timelineItem.timestamp)
              : formatDateAsTime({
                  date: timelineItem.timestamp,
                  clockType,
                })}
          </MText>
        </TimestampWrapper>
        <DescriptionWrapper>
          <MText>{timelineItem.description}</MText>
        </DescriptionWrapper>
        {timelineItem.content && (
          <ContentWrapper>{timelineItem.content}</ContentWrapper>
        )}
      </TimeLineWrapper>
    </div>
  )
}

const TimeLineWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "indicator timestamp timestamp"
    ". description description"
    ". content content";
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: ${TIMELINE_COLUMN_GAP};
  padding-block: ${spacing.XS};
`

const IndicatorWrapper = styled.div`
  grid-area: indicator;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${TIMELINE_ICON_SIZE};
  aspect-ratio: 1/1;

  background-color: ${mColors.neutralLight};

  & > svg {
    width: 100%;
    height: 100%;
  }
`

const IndicatorDot = styled.div`
  width: 50%;
  aspect-ratio: 1/1;
  background-color: ${mColors.neutralDark3};
  border-radius: 2px;
`

const DescriptionWrapper = styled.div`
  grid-area: description;
`

const TimestampWrapper = styled.div`
  grid-area: timestamp;
`

const ContentWrapper = styled.div`
  grid-area: content;
  padding-top: ${spacing.XS};
`
