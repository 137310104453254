import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseShippingMethodActions } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethodDetails/ParadiseShippableProductActions"
import { ParadiseShippingMethodOverview } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethodDetails/ParadiseShippableProductOverview"
import { ParadiseShippingMethodMeta } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethodDetails/ParadiseShippingMethodMeta"
import { ParadiseShippingMethodZonesSection } from "src/components/Paradise/ParadiseShipping/ParadiseShippingMethods/ParadiseShippingMethodDetails/ParadiseShippingMethodZonesSection"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchParadiseShippingMethod } from "src/data/paradise/paradiseShipping/paradiseShippingQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseShippingMethodDetails({ id }: { id: string }) {
  const { t, langKeys } = useTranslate()

  const fetchParadiseShippingMethod = useFetchParadiseShippingMethod({
    id,
  })

  const shippingMethod = fetchParadiseShippingMethod.data

  useAddRecentlyViewedPage({
    title: shippingMethod?.id,
    enitytId: shippingMethod?.id,
    type: "Shipping Method",
    enabled: !!shippingMethod,
  })

  if (fetchParadiseShippingMethod.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchParadiseShippingMethod.isLoading || !shippingMethod) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  return (
    <ParadiseDetailsLayout
      banner={
        shippingMethod.deleted &&
        shippingMethod.deleted_at && (
          <MBanner type="error" fullWidth>
            Shipping method was deleted on{" "}
            {formatUtcDate({ date: shippingMethod.deleted_at })}
          </MBanner>
        )
      }
      meta={<ParadiseShippingMethodMeta shippingMethod={shippingMethod} />}
      actions={
        <ParadiseShippingMethodActions shippingMethod={shippingMethod} />
      }
      left={<ParadiseShippingMethodOverview shippingMethod={shippingMethod} />}
      right={
        <>
          <ParadiseShippingMethodZonesSection shippingMethod={shippingMethod} />
        </>
      }
      breadcrumbs={[
        {
          label: "Shipping methods",
          to: Routes.ParadiseShipping.location(),
        },
        {
          label: id,
          to: Routes.ParadiseShippingMethod.location(id),
        },
      ]}
      breadcrumbCopyValue={id}
    />
  )
}
