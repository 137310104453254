import { chargebeeAddonUrl } from "src/constants/hrefs"
import { TParadiseShippingMethod } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { ExternalLink } from "src/ui/Link/ExternalLink"

export function ParadiseShippingMethodActions({
  shippingMethod,
}: {
  shippingMethod: TParadiseShippingMethod
}) {
  return (
    <ExternalLink href={chargebeeAddonUrl(shippingMethod.shipping_method_id)}>
      Chargebee
    </ExternalLink>
  )
}
