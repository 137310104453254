import { useState } from "react"

import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { ComboboxStateful } from "src/ui/Combobox/ComboboxStateful"

export function ParadiseUserCombobox({
  selectedUser,
  onChange,
  required,
}: {
  selectedUser: TParadiseUser | null
  onChange: (user: TParadiseUser) => void
  required?: boolean
}) {
  const [searchedInput, setSearchedInput] = useState("")

  const fetchParadiseUsers = useFetchParadiseUsers({
    filter: {
      email: searchedInput,
    },
  })

  return (
    <ComboboxStateful
      label="Select user"
      selectedValue={selectedUser?.user_id ?? ""}
      options={
        fetchParadiseUsers.data?.users.map((user) => ({
          value: user.user_id,
          label: user.email,
        })) ?? []
      }
      onSearch={setSearchedInput}
      onChange={(value) => {
        const user = fetchParadiseUsers.data?.users.find(
          (user) => user.user_id === value
        )

        if (user) {
          onChange(user)
        }
      }}
      required={required}
    />
  )
}
