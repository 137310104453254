import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { MText } from "src/ui/MText"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseShippableProductOverview({
  shippableProduct,
}: {
  shippableProduct: TParadiseShippableProduct
}) {
  return (
    <ParadiseOverviewExpandable rawJson={shippableProduct}>
      <ParadiseSettingStatic
        title="ID"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.id}</MText>
            <CopyText
              value={shippableProduct.id}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Product ID"
        infoTooltip={{
          content: "The ID of the addon in Chargebee",
          iconProps: {
            width: 16,
          },
        }}
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.product_id}</MText>
            <CopyText
              value={shippableProduct.product_id}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Stock Keeping Unit (SKU)"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.sku}</MText>
            <CopyText
              value={shippableProduct.sku}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Barcode"
        infoTooltip={{
          content: <MText variant="bodyS">{shippableProduct.barcode}</MText>,
          iconProps: {
            width: 16,
          },
          interactive: true,
        }}
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.barcode}</MText>
            <CopyText
              value={shippableProduct.barcode}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Harmonized System Code"
        value={
          <CopyWrapper>
            <MText variant="bodyS">
              {shippableProduct.harmonized_system_code}
            </MText>
            <CopyText
              value={shippableProduct.harmonized_system_code}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Type"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.type}</MText>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Currency"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.currency_code}</MText>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Compare at Price"
        value={
          <CopyWrapper>
            <MText variant="bodyS">
              {shippableProduct.compare_at_price.formatted_amount}
            </MText>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Pricing model"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.pricing_model}</MText>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Country of Origin"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.country_of_origin}</MText>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Weight"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippableProduct.weight} grams</MText>
          </CopyWrapper>
        }
      />

      <ParadiseSettingStatic
        title="Updated at"
        value={
          <CopyWrapper>
            <MText variant="bodyS">
              {formatUtcDate({ date: shippableProduct.updated_at })}
            </MText>
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Created at"
        value={
          <CopyWrapper>
            <MText variant="bodyS">
              {formatUtcDate({ date: shippableProduct.created_at })}
            </MText>
          </CopyWrapper>
        }
      />
    </ParadiseOverviewExpandable>
  )
}
