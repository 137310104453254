import { TSignalQuality } from "src/data/devices/types/deviceTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import MeshExcellent from "src/ui/icons/mesh-excellent.svg"
import MeshGood from "src/ui/icons/mesh-good.svg"
import MeshNone from "src/ui/icons/mesh-none.svg"
import MeshOkay from "src/ui/icons/mesh-okay.svg"
import MeshWeak from "src/ui/icons/mesh-weak.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { SVGElement, SVGProps } from "src/utils/tsUtil"

export function MeshStatus({
  quality,
  offline = false,
  title,
  ...props
}: SVGProps & {
  quality: TSignalQuality
  offline: boolean
  title?: React.ReactNode
}) {
  const { t } = useTranslate()

  const mesh = offline
    ? { title: t(langKeys.offline), svg: MeshNone }
    : getIconMapping({ quality, t })

  const MeshIcon = mesh.svg

  return (
    <MTooltip title={title || mesh.title}>
      <div>
        <MeshIcon height={30} width={30} {...props} />
      </div>
    </MTooltip>
  )
}

type TIconMap = { svg: SVGElement; title: string }

function getIconMapping({
  quality,
  t,
}: {
  quality: TSignalQuality
  t: ITranslateFunction
}): TIconMap {
  switch (quality) {
    case 0:
      return {
        title: t(langKeys.signal_weak),
        svg: MeshWeak,
      }
    case 1:
      return {
        title: t(langKeys.signal_okay),
        svg: MeshOkay,
      }
    case 2:
      return {
        title: t(langKeys.signal_good),
        svg: MeshGood,
      }
    case 3:
      return {
        title: t(langKeys.signal_excellent),
        svg: MeshExcellent,
      }
    default:
      return {
        title: t(langKeys.signal_weak),
        svg: MeshWeak,
      }
  }
}
