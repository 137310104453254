import { purgeAccessTokenParam } from "src/data/auth/auth"
import { storedLocation } from "src/data/auth/authStorage"
import { useAppLocation } from "src/hooks/useAppLocation"
import { Routes } from "src/router/routes"
import { MLocation } from "src/router/routeTypes"
import { useRouter } from "src/router/useRouter"

export function useLogout() {
  const { navigate } = useRouter()
  const location = useAppLocation()

  /** Return the location for logout, while storing the previous location if
   * passed as the argument `fromLocation`. */
  function logoutLocation({
    reason,
    fromLocation,
    userInitiated,
  }: {
    reason: string
    fromLocation?: MLocation
    userInitiated: boolean
  }) {
    const purgedLocation = purgeAccessTokenParam(location)

    if (fromLocation) {
      storedLocation.set(fromLocation)
    }

    const path = Routes.Logout.location({ reason, userInitiated })
    return { ...purgedLocation, ...path, state: { from: fromLocation } }
  }

  /** Logout from the web app. The arg `fromLocation` can be passed if we want to
   * store the current location before logging out. */
  function logout({
    reason,
    fromLocation,
    userInitiated,
  }: {
    reason: string
    fromLocation?: MLocation
    userInitiated: boolean
  }) {
    const newLocation = logoutLocation({
      reason,
      fromLocation,
      userInitiated,
    })
    navigate(newLocation)
  }

  return { logout, logoutLocation }
}
