import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { queryKeys } from "src/data/ecommerce/ecommerceQueryCache"
import {
  TFetchProductsQuery,
  TFetchProductsResponse,
  TFetchShippingDestinationsResponse,
} from "src/data/ecommerce/ecommerceTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchShippingDestinations(
  options?: UseQueryOptions<
    TFetchShippingDestinationsResponse,
    AxiosError,
    TFetchShippingDestinationsResponse,
    ReturnType<typeof queryKeys.shippingDestinations>
  >
) {
  async function fetchShippingDestinations() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/shipping_methods/destinations`
    )
    return response.data
  }

  return useQuery({
    queryKey: queryKeys.shippingDestinations(),
    queryFn: fetchShippingDestinations,
    ...options,
  })
}

export function useFetchProducts({
  filter,
  options,
}: {
  filter: TFetchProductsQuery
  options?: UseQueryOptions<
    TFetchProductsResponse,
    AxiosError,
    TFetchProductsResponse,
    ReturnType<typeof queryKeys.products>
  >
}) {
  async function fetchPoruducts() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/products`,
      {
        params: filter,
      }
    )
    return response.data
  }

  return useQuery({
    queryKey: queryKeys.products(filter),
    queryFn: fetchPoruducts,
    ...options,
  })
}
