import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { CopyWrapper } from "src/components/Paradise/sharedStyles"
import { TParadiseShippingMethod } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { MText } from "src/ui/MText"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseShippingMethodOverview({
  shippingMethod,
}: {
  shippingMethod: TParadiseShippingMethod
}) {
  return (
    <ParadiseOverviewExpandable rawJson={shippingMethod}>
      <ParadiseSettingStatic
        title="ID"
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippingMethod.id}</MText>
            <CopyText
              value={shippingMethod.id}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Product ID"
        infoTooltip={{
          content: "The ID of the addon in Chargebee",
          iconProps: {
            width: 16,
          },
        }}
        value={
          <CopyWrapper>
            <MText variant="bodyS">{shippingMethod.shipping_method_id}</MText>
            <CopyText
              value={shippingMethod.shipping_method_id}
              copiedText="Copied!"
              width={18}
            />
          </CopyWrapper>
        }
      />
      <ParadiseSettingStatic
        title="Name"
        value={<MText variant="bodyS">{shippingMethod.name}</MText>}
      />
      <ParadiseSettingStatic
        title="Display Name"
        value={<MText variant="bodyS">{shippingMethod.display_name}</MText>}
      />
      <ParadiseSettingStatic
        title="Currency"
        value={<MText variant="bodyS">{shippingMethod.currency_code}</MText>}
      />

      <ParadiseSettingStatic
        title="Updated at"
        value={
          <MText variant="bodyS">
            {formatUtcDate({ date: shippingMethod.updated_at })}
          </MText>
        }
      />
      <ParadiseSettingStatic
        title="Created at"
        value={
          <MText variant="bodyS">
            {formatUtcDate({ date: shippingMethod.created_at })}
          </MText>
        }
      />
    </ParadiseOverviewExpandable>
  )
}
