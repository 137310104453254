import { TFetchProductsQuery } from "src/data/ecommerce/ecommerceTypes"

export const queryKeys = {
  all() {
    return ["ecommerce"] as const
  },

  shippingDestinations() {
    return [...this.all(), "shippingDestinations"] as const
  },

  products(filter: TFetchProductsQuery) {
    return [...this.all(), "products", filter] as const
  },
} as const
