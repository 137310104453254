import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_PARADISE } from "src/constants/minutApi"
import {
  IParadiseUpdateUserBody,
  IParadiseUpdateUserRolesResponse,
  IParadiseUserEmailError,
  IParadiseUserEmailFilter,
  IParadiseUserEmailResponse,
  IParadiseUserEventFilter,
  IParadiseUserEventResponse,
  IParadiseUserResponse,
  IParadiseUsersListFilter,
  TParadiseUser,
  TParadiseUserAvailableRolesResponse,
} from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import {
  useMinutDelete,
  useMinutPost,
  useMinutPut,
  useMinutQuery,
} from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

import {
  paradiseUsersKeys,
  useParadiseUsersCache,
} from "./paradiseUserQueryCache"

export function useFetchParadiseUsers({
  filter,
  options,
}: {
  filter?: IParadiseUsersListFilter
  options?: UseQueryOptions<
    IParadiseUserResponse,
    AxiosError,
    IParadiseUserResponse,
    ReturnType<typeof paradiseUsersKeys.userList>
  >
}) {
  async function fetchParadiseUsers() {
    const response = await minutApiHttpClient.get(`${API_PARADISE}/users`, {
      params: filter && {
        offset: filter.offset,
        limit: filter.limit,
        "filter[email]": filter.email || undefined,
        "filter[name]": filter.name || undefined,
        "filter[user_id]": filter.user_id || undefined,
        "filter[ids]": filter.ids || undefined,
        ...(filter?.sort && {
          [`sort[${filter.sort}]`]: filter.sort_by ?? "desc",
        }),
      },
    })

    return response.data
  }

  return useQuery({
    queryKey: paradiseUsersKeys.userList(filter),
    queryFn: fetchParadiseUsers,
    ...options,
  })
}

export function useFetchParadiseUser({
  userId,
  options,
}: {
  userId: string
  options?: UseQueryOptions<
    TParadiseUser,
    AxiosError,
    TParadiseUser,
    ReturnType<typeof paradiseUsersKeys.user>
  >
}) {
  async function fetchParadiseUser() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/users/${userId}`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseUsersKeys.user(userId),
    queryFn: fetchParadiseUser,
    ...options,
  })
}

export function useFetchParadiseUserEvents({
  userId,
  filter,
  options,
}: {
  userId: string
  filter?: IParadiseUserEventFilter
  options?: UseQueryOptions<
    IParadiseUserEventResponse,
    AxiosError,
    IParadiseUserEventResponse,
    ReturnType<typeof paradiseUsersKeys.event>
  >
}) {
  async function fetchParadiseUserEvent() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/users/${userId}/events`,
      {
        params: {
          offset: filter?.offset,
          limit: filter?.limit,
          ...(filter?.sort && {
            [`sort[${filter.sort}]`]: filter.sort_by ?? "desc",
          }),
        },
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseUsersKeys.event(userId, filter),
    queryFn: fetchParadiseUserEvent,
    ...options,
  })
}
// ts-prune-ignore-next
export function useDeleteParadiseUserGdpr() {
  const paradiseUserQueryCache = useParadiseUsersCache()

  async function deleteParadiseUserGdpr(userId: string) {
    await minutApiHttpClient.delete(`${API_PARADISE}/users/${userId}/gdpr`)
  }

  return useMutation({
    mutationFn: deleteParadiseUserGdpr,
    onSuccess: (data, vars) => {
      return Promise.all([
        paradiseUserQueryCache.invalidateParadiseUserList(),
        paradiseUserQueryCache.removeParadiseUser(vars),
      ])
    },
  })
}

export function usePutParadiseUpdateUser() {
  const paradiseUserCache = useParadiseUsersCache()

  async function putParadiseUpdateUser({
    userId,
    body,
  }: {
    userId: string
    body: IParadiseUpdateUserBody
  }) {
    const response = await minutApiHttpClient.put<TParadiseUser>(
      `${API_PARADISE}/users/${userId}`,
      body
    )

    return response.data
  }

  return useMutation({
    mutationFn: putParadiseUpdateUser,
    onSuccess: (data, vars) => {
      return paradiseUserCache.invalidateParadiseUser(vars.userId)
    },
  })
}

export function usePutParadiseUpdateUserRoles() {
  const paradiseUserCache = useParadiseUsersCache()

  async function putParadiseUpdateUserRoles({
    userId,
    roles,
  }: {
    userId: string
    roles: string[]
  }) {
    const response =
      await minutApiHttpClient.put<IParadiseUpdateUserRolesResponse>(
        `${API_PARADISE}/users/${userId}/roles`,
        { roles }
      )

    return response.data
  }

  return useMutation({
    mutationFn: putParadiseUpdateUserRoles,
    onSuccess: (data, vars) => {
      return paradiseUserCache.invalidateParadiseUser(vars.userId)
    },
  })
}

export function usePostParadiseUserNote() {
  const paradiseUserCache = useParadiseUsersCache()

  return useMinutPost<"/users/{user_id}/notes", "403">({
    pathFn: (path) => `/users/${path.user_id}/notes`,
    options: {
      onSuccess: (data, vars) => {
        return paradiseUserCache.invalidateParadiseUser(vars.path.user_id)
      },
    },
  })
}

export function useDeleteParadiseUserNote() {
  const paradiseUserCache = useParadiseUsersCache()

  return useMinutDelete<"/users/{user_id}/notes/{note_id}", "403">({
    pathFn: (path) => `/users/${path.user_id}/notes/${path.note_id}`,
    options: {
      onSuccess: (data, vars) => {
        return paradiseUserCache.invalidateParadiseUser(vars.path.user_id)
      },
    },
  })
}

export function usePutParadisePasswordReset() {
  const paradiseUserCache = useParadiseUsersCache()

  return useMinutPut<"/users/{user_id}/password/reset", "403">({
    pathFn: (path) => `/users/${path.user_id}/password/reset`,
    options: {
      onSuccess: (data, vars) => {
        return paradiseUserCache.invalidateParadiseUser(vars.path.user_id)
      },
    },
  })
}
export function useFetchParadiseUserEmails({
  userId,
  filter,
}: {
  userId: string
  filter?: IParadiseUserEmailFilter
}) {
  return useMinutQuery<"/users/{user_id}/email_activity", "403">({
    queryPath: `/users/${userId}/email_activity`,
    queryKey: paradiseUsersKeys.email({ userId, filter }),
    filters: filter,
  })
}

export function useFetchParadiseUserEmail({
  userId,
  emailId,
  options,
}: {
  userId: string
  emailId: string
  options?: UseQueryOptions<
    IParadiseUserEmailResponse,
    AxiosError<IParadiseUserEmailError>,
    IParadiseUserEmailResponse
  >
}) {
  return useMinutQuery<"/users/{user_id}/email_activity/{email_id}", "403">({
    queryPath: `/users/${userId}/email_activity/${emailId}`,
    queryKey: paradiseUsersKeys.email({ userId, emailId }),
    options,
  })
}

export function useFetchAvailableUserRoles(props?: {
  options: UseQueryOptions<
    TParadiseUserAvailableRolesResponse,
    AxiosError,
    TParadiseUserAvailableRolesResponse
  >
}) {
  async function fetchAvailableUserRoles() {
    const response = await minutApiHttpClient.get(`${API_PARADISE}/users/roles`)

    return response.data
  }

  return useQuery({
    queryKey: paradiseUsersKeys.availableRoles(),
    queryFn: fetchAvailableUserRoles,
    ...props?.options,
  })
}
