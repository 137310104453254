import { useRef, useState } from "react"
import styled from "styled-components"

import { Fade, IconButton, Paper } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Popper from "@material-ui/core/Popper"

import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import UpgradeIcon from "src/ui/icons/arrow-up-filled-upgrade.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type UpgradeIconPopoverProps = {
  className?: string
  ctaButton?: React.ReactNode
  hidden?: boolean
  text: string
  errorMsg: string | null
  description?: React.ReactNode
}

export function UpgradeIconPopover({
  className,
  ctaButton,
  hidden,
  text,
  errorMsg,
  description,
}: UpgradeIconPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const iconButtonRef = useRef<HTMLDivElement | null>(null)

  function openPopper(element: HTMLElement) {
    setAnchorEl(element)
  }

  function closePopper() {
    setAnchorEl(undefined)
  }

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    if (anchorEl) {
      closePopper()
    } else {
      openPopper(event.currentTarget)
    }
  }

  function handleClickAway() {
    closePopper()
  }

  if (hidden) {
    return null
  }

  return (
    <Box
      className={className}
      onMouseEnter={() =>
        iconButtonRef.current && openPopper(iconButtonRef.current)
      }
      onMouseLeave={closePopper}
      ref={iconButtonRef}
    >
      <IconButton size="small" onClick={handleClick}>
        <UpgradeIcon />
      </IconButton>

      <UpgradePopper open={!!anchorEl} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={250}>
              <ContentBox>
                <InfoBox>
                  <UpgradeIcon />
                  <div>
                    <MText variant="subtitleS" color="primary">
                      {text}
                    </MText>
                    {description && description}
                  </div>
                </InfoBox>
                {ctaButton}

                {!!errorMsg && (
                  <ErrorBox>
                    <MBanner type="error" fullWidth>
                      {errorMsg}
                    </MBanner>
                  </ErrorBox>
                )}
              </ContentBox>
            </Fade>
          </ClickAwayListener>
        )}
      </UpgradePopper>
    </Box>
  )
}

const Box = styled.div`
  display: inline-block;
`

const UpgradePopper = styled(Popper)`
  z-index: ${Z_INDEX_ABOVE_MODAL};
`

const ContentBox = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: ${spacing.M};
  padding: ${spacing.M};
  max-width: 450px;
`
const ErrorBox = styled.div`
  grid-column: span 2;
`

const InfoBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: top;
  gap: ${spacing.XS};
`
