import styled from "styled-components"

import { mColors, spacing } from "@minuthq/meatball-ui-react"

import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"

export function ParadiseShippableProductsDetailsMeta({
  shippableProduct,
}: {
  shippableProduct: TParadiseShippableProduct
}) {
  return (
    <div>
      <TitleWrapper>
        <ImageWrapper>
          <img src={shippableProduct.image_url} />
        </ImageWrapper>
        <ParadiseDetailsHeading>
          {shippableProduct.product_id}
        </ParadiseDetailsHeading>
      </TitleWrapper>
    </div>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`
const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  border: 1px solid ${mColors.divider};
  border-radius: 0.5rem;

  & > img {
    width: 100%;
    height: 100%;
  }
`
