import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { TParadiseShippingMethod } from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { MText } from "src/ui/MText"

export function ParadiseShippingMethodMeta({
  shippingMethod,
}: {
  shippingMethod: TParadiseShippingMethod
}) {
  return (
    <div>
      <ParadiseDetailsHeading>{shippingMethod.name}</ParadiseDetailsHeading>
      <MText>Display name: {shippingMethod.display_name}</MText>
    </div>
  )
}
