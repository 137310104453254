import { useMemo } from "react"
import styled from "styled-components"

import { TextField } from "@minuthq/meatball-ui-react"

import { getProvinceArray } from "src/components/Account/BillingPortal/countryData"
import { ParadiseCountrySelector } from "src/components/Paradise/ParadiseCountrySelector"
import { TParadiseCreateOrderShippingAddressForm } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/useParadiseCreateOrder"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useFetchParadiseShippingDestinations } from "src/data/paradise/paradiseShipping/paradiseShippingQueries"
import { MSelect } from "src/ui/MSelect/MSelect"
import { spacing } from "src/ui/spacing"

export function ParadiseCreateOrderShippingAddress({
  shippingAddress,
  updateShippingAddress,
  currencyCode,
}: {
  shippingAddress: TParadiseCreateOrderShippingAddressForm
  updateShippingAddress: (
    shippingAddress: Partial<TParadiseCreateOrderShippingAddressForm>
  ) => void
  currencyCode: TSubscriptionCurrencyCode
}) {
  const fetchShippingDestinations = useFetchParadiseShippingDestinations()

  const availableDestinations = useMemo(() => {
    return (
      fetchShippingDestinations.data?.filter(
        (destination) => destination.currency_code === currencyCode
      ) ?? []
    )
  }, [fetchShippingDestinations.data, currencyCode])

  const countryStates = getProvinceArray(shippingAddress.country)

  return (
    <Form>
      <InlineGrid>
        <TextField
          label="First name"
          name="first_name"
          value={shippingAddress.first_name}
          onChange={(value) => {
            updateShippingAddress({
              first_name: value,
            })
          }}
          required
          requiredIndicator
        />

        <TextField
          label="Last name"
          name="last_name"
          value={shippingAddress.last_name}
          onChange={(value) => {
            updateShippingAddress({
              last_name: value,
            })
          }}
          required
          requiredIndicator
        />
      </InlineGrid>

      <TextField
        label="Company"
        name="company"
        value={shippingAddress.company ?? ""}
        onChange={(value) => {
          updateShippingAddress({
            company: value,
          })
        }}
      />

      <TextField
        label="Phone"
        name="phone"
        value={shippingAddress.phone}
        onChange={(value) => {
          updateShippingAddress({
            phone: value,
          })
        }}
        type="tel"
        required
        requiredIndicator
      />

      <TextField
        label="Line 1"
        name="line1"
        value={shippingAddress.line1}
        onChange={(value) => {
          updateShippingAddress({
            line1: value,
          })
        }}
        required
        requiredIndicator
      />

      <TextField
        label="Line 2"
        name="line2"
        value={shippingAddress.line2 ?? ""}
        onChange={(value) => {
          updateShippingAddress({
            line2: value,
          })
        }}
      />

      <TextField
        label="City"
        name="city"
        value={shippingAddress.city}
        onChange={(value) => {
          updateShippingAddress({
            city: value,
          })
        }}
        required
        requiredIndicator
      />

      <TextField
        label="Zip"
        name="zip"
        value={shippingAddress.zip ?? ""}
        onChange={(value) => {
          updateShippingAddress({
            zip: value,
          })
        }}
        required
        requiredIndicator
      />

      <InlineGrid>
        <ParadiseCountrySelector
          label="Country"
          selectedCountryCode={shippingAddress.country}
          onSelectCountryCode={(country) => {
            updateShippingAddress({
              country,
              state_code: "",
            })
          }}
          countryCodes={[
            ...availableDestinations.map(
              (destination) => destination.country_code
            ),
          ]}
          name="country"
          required
          requiredIndicator
        />

        <MSelect
          name="state_code"
          label="State"
          value={shippingAddress.state_code ?? ""}
          onChange={(value) => {
            updateShippingAddress({
              state_code: value,
            })
          }}
          options={[
            {
              label: "",
              value: "",
              hidden: true,
            },
            ...countryStates.map((state) => ({
              value: state.code,
              label: state.label,
            })),
          ]}
          disabled={countryStates.length === 0}
          required
          requiredIndicator
        />
      </InlineGrid>
    </Form>
  )
}

const Form = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const InlineGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.M};
`
