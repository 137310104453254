import { ChangeEvent, InputHTMLAttributes } from "react"
import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import CheckIcon from "src/ui/icons/checkmark-round.svg"
import ImportantIcon from "src/ui/icons/important-outlined.svg"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type HTMLInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "file" | "onChange"
>

export type DropzoneProps = {
  file: File | null
  onChange: (file: File | null) => void
  error?: string
  loading?: boolean
  inputProps?: Pick<HTMLInputProps, "accept" | "required">
  replaceFileLabel?: string
  description?: string
}

export function Dropzone({
  file,
  onChange,
  error,
  loading,
  inputProps,
  replaceFileLabel,
  description,
}: DropzoneProps) {
  const { t, langKeys } = useTranslate()

  const replaceFileLabelWithDefault =
    replaceFileLabel || t(langKeys.drop_zone_replace_file)
  const descriptionWithDefault =
    description || t(langKeys.drop_zone_default_description)

  function renderInnerContent() {
    if (loading) {
      return (
        <InnerWrapper>
          <MCircularProgress size={24} />
        </InnerWrapper>
      )
    }

    if (file) {
      return (
        <SelecetdFileWrapper>
          {!error ? (
            <CheckIcon width={24} color={mColors.systemGood} />
          ) : (
            <ImportantIcon width={24} color={mColors.systemErrorDark} />
          )}
          <MText color="secondary">{file.name}</MText>
          <MButton variant="minimal">{replaceFileLabelWithDefault}</MButton>
        </SelecetdFileWrapper>
      )
    }

    return (
      <InnerWrapper>
        <MText variant="subtitle" color="primary">
          {descriptionWithDefault}
        </MText>
      </InnerWrapper>
    )
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files

    // Prevent the file from being cleared when the user cancels the file picker
    if (file && files?.length === 0) {
      const dt = new DataTransfer()
      dt.items.add(file)

      event.target.files = dt.files

      return
    }

    onChange(files?.item(0) ?? null)
  }

  return (
    <div>
      <Box $hasFile={!!file} $loading={!!loading} $error={!!error}>
        {renderInnerContent()}

        <VisuallyHiddenInput
          ref={(el) => {
            if (el && file) {
              const dt = new DataTransfer()
              dt.items.add(file)

              el.files = dt.files
            }
          }}
          type="file"
          onChange={handleChange}
          {...inputProps}
        />
      </Box>
      {error && (
        <ErrorText variant="bodyS" color="emergency">
          {error}
        </ErrorText>
      )}
    </div>
  )
}

const Box = styled.div<{
  $hasFile: boolean
  $error: boolean
  $loading: boolean
}>`
  position: relative;
  display: grid;
  background: ${mColors.neutral};
  border: 1px solid
    ${({ $error }) => ($error ? mColors.systemError : mColors.neutral)};
  border-radius: 12px;
  padding: ${spacing.M};
  min-height: ${({ $hasFile, $loading }) =>
    $hasFile || $loading ? "auto" : "200px"};
  transition:
    background-color 0.1s,
    border-color 0.1s;

  &:hover {
    background-color: ${mColors.neutralDark};
    border-color: ${({ $error }) =>
      $error ? mColors.systemError : mColors.neutralDark};
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SelecetdFileWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${spacing.XS};
`

const ErrorText = styled(MText)`
  margin-left: ${spacing.M};
`

const VisuallyHiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`
