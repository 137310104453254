import { TParadiseQuotesQueryParams } from "src/data/paradise/paradiseQuotes/paradiseQuoteTypes"

export const paradiseQuoteKeys = {
  all() {
    return ["paradiseQuotes"] as const
  },

  quoteLists() {
    return [...this.all(), "list"] as const
  },

  quoteList(filter?: TParadiseQuotesQueryParams) {
    if (!filter) {
      return [...this.quoteLists()] as const
    }

    return [...this.quoteLists(), filter] as const
  },

  quote(id: string) {
    return [...this.all(), id] as const
  },
} as const
