import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import {
  TParadiseOrdersFilters,
  TSortedParadiseOrdersFilters,
} from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { FilterWrapper } from "src/components/Paradise/sharedStyles"
import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { MButton } from "src/ui/Button/MButton"

const ordersFilterLabel: Record<keyof TParadiseOrdersFilters, string> = {
  orders_offset: "Offset",
  orders_order_number: "Order number",
  orders_user_ids: "User IDs",
  orders_country_codes: "Shipping countries",
} as const

export function ParadiseOrdersFilters({
  setFilter,
  sortedFilters,
}: {
  setFilter: TSetSearchParamsProp<TParadiseOrdersFilters>
  sortedFilters: TSortedParadiseOrdersFilters
}) {
  const appliedFilters = sortedFilters.map((f) => {
    const label = ordersFilterLabel[f?.key]

    let value = String(f.value)
    if (f.key === "orders_user_ids") {
      value = getUserIdsFilterValue(f.value)
    }

    return {
      label: label ?? "",
      value,
      onRemove: () => setFilter(f.key, null),
    }
  })
  return (
    <FilterWrapper>
      <ParadiseAppliedFilters filters={appliedFilters} />

      {appliedFilters.length > 0 && (
        <MButton
          onClick={() => {
            appliedFilters.forEach((filter) => filter.onRemove())
          }}
          variant="minimal"
          size="small"
        >
          Clear filters
        </MButton>
      )}
    </FilterWrapper>
  )
}

function getUserIdsFilterValue(value: string[]) {
  return `${value.length} selected`
}
