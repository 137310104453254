import { useState } from "react"
import styled from "styled-components"

import { prettifyApiFieldValue } from "src/data/orders/orderUtils"
import {
  getWMSLink,
  isOrderEditable,
} from "src/data/paradise/paradiseOrders/logic/paradiseOrderLogic"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { DropdownListButton, IOption } from "src/ui/Button/DropdownListButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderActions({ order }: { order: TParadiseOrder }) {
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showEditShippingAddressDialog, setShowEditShippingAddressDialog] =
    useState(false)

  const options: IOption<"cancel-order" | "edit-shipping-address">[] = [
    {
      name: "Edit shipping address",
      value: "edit-shipping-address",
      onClick: () => {
        setShowEditShippingAddressDialog(true)
      },
    },
    {
      name: "Cancel order",
      value: "cancel-order",
      onClick: () => {
        setShowCancelDialog(true)
      },
      color: "destructive",
    },
  ]

  return (
    <div>
      <DropdownListButton displayValue="Order actions" options={options} />

      {showEditShippingAddressDialog && (
        <EditShippingAddressDialog
          order={order}
          onClose={() => setShowEditShippingAddressDialog(false)}
        />
      )}

      {showCancelDialog && (
        <CancelOrderDialog
          order={order}
          onClose={() => setShowCancelDialog(false)}
        />
      )}
    </div>
  )
}

function EditShippingAddressDialog({
  order,
  onClose,
}: {
  order: TParadiseOrder
  onClose: () => void
}) {
  function getBody() {
    if (isOrderEditable(order.status)) {
      return <ManageOrderAtWMS warehouse={order.assigned_warehouse} />
    }

    return (
      <BodyBox>
        <MBanner type="error" fullWidth>
          Can not edit the order, the warehouse has already started fulfilling
          it. For further questions contact the supply chain manager or contact
          the warehouse directly.
        </MBanner>
        <ManageOrderAtWMS warehouse={order.assigned_warehouse} />
      </BodyBox>
    )
  }

  return (
    <ConfirmDialog
      open={true}
      title="Edit shipping address"
      onClose={onClose}
      closeLabel="Close"
    >
      {getBody()}
    </ConfirmDialog>
  )
}

function CancelOrderDialog({
  order,
  onClose,
}: {
  order: TParadiseOrder
  onClose: () => void
}) {
  function getBody() {
    if (isOrderEditable(order.status)) {
      return <ManageOrderAtWMS warehouse={order.assigned_warehouse} />
    }

    return (
      <BodyBox>
        <MBanner type="error" fullWidth>
          Can not cancel order, the warehouse has already started fulfilling it.
          For further questions contact the supply chain manager or contact the
          warehouse directly.
        </MBanner>
        <ManageOrderAtWMS warehouse={order.assigned_warehouse} />
      </BodyBox>
    )
  }

  return (
    <ConfirmDialog
      open={true}
      title="Cancel order"
      onClose={onClose}
      closeLabel="Close"
    >
      {getBody()}
    </ConfirmDialog>
  )
}

function ManageOrderAtWMS({
  warehouse,
}: {
  warehouse: TParadiseOrder["assigned_warehouse"]
}) {
  return (
    <MText>
      Manage the order in the WMS:{" "}
      <ExternalLink href={getWMSLink(warehouse)}>
        {prettifyApiFieldValue(warehouse)}
      </ExternalLink>
    </MText>
  )
}

const BodyBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`
