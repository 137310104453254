import { TParadiseQuote } from "src/data/paradise/paradiseQuotes/paradiseQuoteTypes"
import { MBadge, MBadgeProps } from "src/ui/MBadge/MBadge"
import { capitalizeFirstChar } from "src/utils/l10n"

type TParadiseQuoteStatus = TParadiseQuote["status"]

export function ParadiseQuoteStatus({
  status,
}: {
  status: TParadiseQuoteStatus
}) {
  const parsedStatus = parseStatus(status)
  const statusColor = getStatusColor(status)

  return (
    <MBadge color={statusColor} size="x-small">
      {parsedStatus}
    </MBadge>
  )
}

function parseStatus(status: TParadiseQuoteStatus) {
  return capitalizeFirstChar(status).replace(/_/g, " ")
}

function getStatusColor(status: TParadiseQuoteStatus): MBadgeProps["color"] {
  switch (status) {
    case "new":
      return "info"
    case "opened":
      return "warning"
    case "purchased":
      return "good"
  }
}
