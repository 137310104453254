import {
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { paradiseQuoteKeys } from "src/data/paradise/paradiseQuotes/paradiseQuotesQueryCache"
import {
  TParadiseQuoteResponse,
  TParadiseQuotesQueryParams,
  TParadiseQuotesResponse,
  TPostParadiseQuoteRequestBody,
  TPostParadiseQuoteResponse,
} from "src/data/paradise/paradiseQuotes/paradiseQuoteTypes"
import { useMinutQuery } from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchParadiseQuotes(props?: {
  filter?: TParadiseQuotesQueryParams
  options?: UseQueryOptions<
    TParadiseQuotesResponse,
    AxiosError,
    TParadiseQuotesResponse,
    ReturnType<typeof paradiseQuoteKeys.quoteList>
  >
}) {
  return useMinutQuery<"/ecommerce/quotes">({
    apiPath: "/ecommerce/quotes",
    queryKey: paradiseQuoteKeys.quoteList(),
    queryPath: "/ecommerce/quotes",
    ...props?.options,
  })
}

export function useFetchParadiseQuote(props: {
  id: string
  options?: UseQueryOptions<
    TParadiseQuoteResponse,
    AxiosError,
    TParadiseQuoteResponse,
    ReturnType<typeof paradiseQuoteKeys.quote>
  >
}) {
  return useMinutQuery<"/ecommerce/quotes/{quote_id}">({
    apiPath: "/ecommerce/quotes/{quote_id}",
    queryKey: paradiseQuoteKeys.quote(props.id),
    queryPath: `/ecommerce/quotes/${props.id}`,
    ...props.options,
  })
}

export function useDeleteParadiseQuote() {
  const queryClient = useQueryClient()

  async function deleteParadiseQuote(id: string) {
    await minutApiHttpClient.delete(`${API_DEFAULT}/ecommerce/quotes/${id}`)
  }

  return useMutation({
    mutationFn: deleteParadiseQuote,
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(paradiseQuoteKeys.quoteList())
      queryClient.invalidateQueries(paradiseQuoteKeys.quote(vars))
    },
  })
}

export function usePostParadiseQuote() {
  const queryClient = useQueryClient()

  async function postParadiseQuote(
    body: TPostParadiseQuoteRequestBody
  ): Promise<TPostParadiseQuoteResponse> {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/ecommerce/quotes`,
      body
    )

    return response.data
  }

  return useMutation<
    TPostParadiseQuoteResponse,
    AxiosError,
    TPostParadiseQuoteRequestBody
  >({
    mutationFn: postParadiseQuote,
    onSuccess: () => {
      queryClient.invalidateQueries(paradiseQuoteKeys.quoteList())
    },
  })
}
