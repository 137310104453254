import { useState } from "react"
import styled from "styled-components"

import { Badge } from "@material-ui/core"

import { DashboardWidgets } from "src/components/Dashboard/DashboardWidgets/DashboardWidgets"
import { NoHomes } from "src/components/EmptyState/NoHomes"
import { NoHomesOnFilter } from "src/components/EmptyState/NoHomesOnFilter"
import { FilterQueryBox } from "src/components/Filter/FilterQueryBox"
import { DeleteMultipleHomesDialog } from "src/components/Homes/HomeList/DeleteMultipleHomesDialog"
import { DownloadHomesCsvButton } from "src/components/Homes/HomeList/DownloadHomesCsvButton"
import { HomeTable } from "src/components/Homes/HomeList/HomeTable"
import { Pager } from "src/components/Pager/Pager"
import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { usePostCreateHomeClicked } from "src/data/analytics/queries/homeAnalyticsQueries"
import { DEVICE_LIST_LIMIT_MAX } from "src/data/devices/logic/deviceConstants"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import { useUrlHomeFilters } from "src/data/homes/hooks/useUrlParamHome"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { useFetchAvailableHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHomes,
  useFetchHomesCount,
} from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { useFlags } from "src/hooks/useFlags"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { brandText } from "src/ui/colors"
import { Container } from "src/ui/Container/Container"
import HomeIcon from "src/ui/icons/home.svg"
import DeviceIcon from "src/ui/icons/sensor.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"
import { unique } from "src/utils/genericUtil"

export function HomeList() {
  // #region Analytics
  const postCreateHomeClicked = usePostCreateHomeClicked()
  // #endregion

  const { org } = useOrganization()
  const orgId = org.id
  const { navigate } = useRouter()

  const { t } = useTranslate()
  const access = getAccessLogic({ role: org.user_role })
  const isHomegroupAdmin = useIsHomeGroupAdmin()
  const { isCancelled, isOwner } = useDunning()
  const canAddHomeAccess = access.hasAdminAccess || isHomegroupAdmin.value
  const canDownloadCsv = access.hasAdminAccess

  const { homeListWidgets } = useFlags()

  const [selectedHomes, setSelectedHomes] = useState<THome[]>([])
  const [showDeleteHomesDialog, setShowDeleteHomesDialog] = useState(false)

  const { setOffset, filters, searchFieldFilter, dropdownFilters } =
    useUrlHomeFilters()

  const fetchHomes = useFetchHomes({
    orgId,
    filters,
  })
  const fetchHomesCount = useFetchHomesCount({
    orgId: org.id,
    options: { enabled: access.hasAdminAccess },
  })
  const fetchHomeTokens = useFetchAvailableHomeTokens({
    orgId: org.id,
    options: {
      enabled:
        access.hasAdminAccess && org.billing_model === "plan_unit_per_home",
    },
  })

  const homes: THome[] = fetchHomes.data?.homes || []
  const paging = fetchHomes.data?.paging
  const totalHomesCount = fetchHomesCount.data || 0
  const unusedHomeTokens = fetchHomeTokens.data?.paging.total_count ?? 0
  const homeTokensProgress = unusedHomeTokens
    ? (100 * totalHomesCount) / (unusedHomeTokens + totalHomesCount)
    : 100

  const fetchDevices = useFetchDevices({
    orgId,
    filter: {
      home_ids: unique(fetchHomes.data?.homes.map((h) => h.home_id)),
      limit: DEVICE_LIST_LIMIT_MAX,
    },
    options: { enabled: !!fetchHomes.data?.homes.length },
  })

  const fetchHomeGroups = useFetchHomeGroups({
    orgId,
    filter: {
      ids: unique(fetchHomes.data?.homes.map((h) => h.homegroup_id))
        .filter((id) => !!id)
        .join(","),
    },
    options: {
      enabled: !!fetchHomes.data?.homes.length,
      keepPreviousData: true,
    },
  })

  function handleHomeChecked(home: THome, checked: boolean) {
    if (checked) {
      setSelectedHomes((prev) => [...prev, home])
    } else {
      setSelectedHomes(selectedHomes.filter((h) => h.home_id !== home.home_id))
    }
  }

  function handleAllHomesChecked(checked: boolean) {
    if (checked) {
      setSelectedHomes((prev) => [
        ...homes,
        // Make sure we get the previously selected homes but filter out the ones that are in the current list
        ...prev.filter(
          (h) => !homes.find((home) => home.home_id === h.home_id)
        ),
      ])
    } else {
      setSelectedHomes((prev) =>
        prev.filter(
          (selectedHome) =>
            !homes.find((home) => home.home_id === selectedHome.home_id)
        )
      )
    }
  }

  const {
    crowd_detected,
    fire_or_co_alarm_ongoing_filter,
    low_battery,
    name,
    noise_ongoing,
    offline_sensors,
    smoking_detected,
    indoor_climate_ongoing_alert_filter,
  } = filters

  const nonPagingFiltersApplied =
    fire_or_co_alarm_ongoing_filter ||
    noise_ongoing ||
    name ||
    crowd_detected ||
    smoking_detected ||
    offline_sensors ||
    low_battery ||
    indoor_climate_ongoing_alert_filter

  const showNoHomes =
    fetchHomes.isFetched &&
    !homes.length &&
    filters.offset === 0 &&
    !nonPagingFiltersApplied

  const showFilterEmpty =
    !fetchHomes.isFetching &&
    fetchHomes.isFetched &&
    !homes.length &&
    nonPagingFiltersApplied

  const showHomeTokenProgress =
    !fetchHomesCount.isInitialLoading &&
    access.hasAdminAccess &&
    unusedHomeTokens > 0 &&
    org.billing_model === "plan_unit_per_home" &&
    !isCancelled

  const isReactivating = isCancelled && isOwner

  if (
    fetchHomes.isLoading ||
    !isHomegroupAdmin.ready ||
    fetchDevices.isInitialLoading ||
    fetchHomeGroups.isInitialLoading
  ) {
    return (
      <div>
        <MSkeleton height={30} />
        <MSkeleton height={30} />
        <MSkeleton height={30} />
      </div>
    )
  }

  if (showNoHomes) {
    return <NoHomes />
  }

  return (
    <div>
      <Titlebar
        title={t(langKeys.home, { count: 2 })}
        size="unset"
        titleVariant={"heading2"}
        bottomMargin={
          homeListWidgets || canAddHomeAccess ? spacing.L : undefined
        }
        description={
          homeListWidgets && (
            <Description
              homeCount={fetchHomes.data?.paging.total_count}
              sensorCount={fetchDevices?.data?.paging.total_count}
            />
          )
        }
      />

      {showHomeTokenProgress && (
        <Header>
          <Counter>
            <CounterSection>
              <MText variant="bodyS">{totalHomesCount}</MText>
              <MText variant="bodyS" color="secondary">
                {t(langKeys.homes_created, { count: totalHomesCount })}
              </MText>
            </CounterSection>
            <CounterSection>
              <MText variant="heading3">{unusedHomeTokens}</MText>
              <MText variant="bodyS" color="secondary">
                {t(langKeys.homes_to_create, { count: unusedHomeTokens })}
              </MText>
            </CounterSection>
          </Counter>
          <LinearProgressBar
            segments={[{ value: homeTokensProgress, type: "good" }]}
          />
        </Header>
      )}

      <section>
        {homeListWidgets && <DashboardWidgets />}

        <Container>
          <Actions>
            <FilterQueryBox
              dropdownFilters={dropdownFilters}
              searchFieldFilter={searchFieldFilter}
            />

            {isReactivating ? (
              <ActionMenu>
                <Badge badgeContent={selectedHomes.length}>
                  <MButton
                    variant="subtle"
                    size="small"
                    onClick={() => setShowDeleteHomesDialog(true)}
                    disabled={selectedHomes.length === 0}
                  >
                    {t(langKeys.delete)}
                  </MButton>
                </Badge>
                <MButton
                  size="small"
                  appHref={Routes.ChangePlan.location()}
                  disabled={selectedHomes.length > 0}
                >
                  {t(langKeys.subscription_cancelled_banner_action)}
                </MButton>
              </ActionMenu>
            ) : (
              <ActionMenu>
                <Show if={canDownloadCsv}>
                  <DownloadHomesCsvButton
                    affectedNbrHomes={fetchHomes.data?.paging.total_count ?? 0}
                    filters={filters}
                  />
                </Show>

                <Show if={canAddHomeAccess}>
                  <MButton
                    onClick={() => {
                      postCreateHomeClicked.mutate({
                        context: "units_tab",
                      })

                      navigate(Routes.CreateHome.location())
                    }}
                    size="small"
                  >
                    {t(langKeys.home_create_new_home)}
                  </MButton>
                </Show>
              </ActionMenu>
            )}
          </Actions>
        </Container>

        <HomeTable
          homes={homes}
          devices={fetchDevices.data?.devices || []}
          homeGroups={fetchHomeGroups.data?.homegroups || []}
          loading={false}
          checkedHomes={selectedHomes}
          onHomeChecked={handleHomeChecked}
          onAllHomesChecked={handleAllHomesChecked}
          enableHomeChecking={isReactivating}
        />

        {showFilterEmpty && <NoHomesOnFilter />}

        {!showFilterEmpty && (
          <PagerWrapper>
            <Pager
              limit={filters.limit ?? HOME_LIST_LIMIT}
              offset={filters.offset ?? 0}
              setOffset={setOffset}
              totalCount={paging?.total_count}
            />
          </PagerWrapper>
        )}
      </section>
      <DeleteMultipleHomesDialog
        open={showDeleteHomesDialog}
        onClose={() => setShowDeleteHomesDialog(false)}
        onSuccess={() => {
          setSelectedHomes([])
          setShowDeleteHomesDialog(false)
        }}
        homes={selectedHomes}
      />
    </div>
  )
}

function Description({
  homeCount,
  sensorCount,
}: {
  homeCount: number | undefined
  sensorCount: number | undefined
}) {
  const { t, langKeys } = useTranslate()

  return (
    <DescriptionBox>
      <HomeIcon width={18} height={18} color={brandText} />
      {homeCount ?? "-"} {t(langKeys.home, { count: homeCount })}
      <DeviceIcon width={18} height={18} color={brandText} />
      {sensorCount ?? "-"} {t(langKeys.sensor, { count: sensorCount })}
    </DescriptionBox>
  )
}

const DescriptionBox = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const ActionMenu = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: ${spacing.XS};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.XL2};
  max-width: 800px;
`

const Counter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.XS};
`

const CounterSection = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.XS2};
`

const Actions = styled.div`
  display: grid;
  gap: ${spacing.M};
  margin-bottom: ${spacing.L};

  @container (width > 600px) {
    grid-template-columns: 1fr auto;
  }
`
