import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseShippableProductActions } from "src/components/Paradise/ParadiseShippableProducts/ParadiseShippableProductDetails/ParadiseShippableProductActions"
import { ParadiseShippableProductsDetailsMeta } from "src/components/Paradise/ParadiseShippableProducts/ParadiseShippableProductDetails/ParadiseShippableProductMeta"
import { ParadiseShippableProductOverview } from "src/components/Paradise/ParadiseShippableProducts/ParadiseShippableProductDetails/ParadiseShippableProductOverview"
import { ParadiseShippableProductPriceSection } from "src/components/Paradise/ParadiseShippableProducts/ParadiseShippableProductDetails/ParadiseShippableProductPriceSection"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function ParadiseShippableProductDetails({ id }: { id: string }) {
  const { t, langKeys } = useTranslate()

  const fetchParadiseShippableProduct = useFetchParadiseShippableProduct({
    id,
  })

  const shippableProduct = fetchParadiseShippableProduct.data

  useAddRecentlyViewedPage({
    title: shippableProduct?.id,
    enitytId: shippableProduct?.id,
    type: "Shippable Product",
    enabled: !!shippableProduct,
  })

  if (fetchParadiseShippableProduct.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchParadiseShippableProduct.isLoading || !shippableProduct) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  return (
    <ParadiseDetailsLayout
      meta={
        <ParadiseShippableProductsDetailsMeta
          shippableProduct={shippableProduct}
        />
      }
      actions={
        <ParadiseShippableProductActions shippableProduct={shippableProduct} />
      }
      left={
        <ParadiseShippableProductOverview shippableProduct={shippableProduct} />
      }
      right={
        <>
          <ParadiseShippableProductPriceSection
            shippingProduct={shippableProduct}
          />
        </>
      }
      breadcrumbs={[
        {
          label: "Shippable products",
          to: Routes.ParadiseShippableProducts.location(),
        },
        {
          label: id,
          to: Routes.ParadiseOrder.location(id),
        },
      ]}
      breadcrumbCopyValue={id}
    />
  )
}
