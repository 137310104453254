import styled from "styled-components"

import { mColors, spacing } from "@minuthq/meatball-ui-react"

import { MButton } from "src/ui/Button/MButton"
import { CopyText } from "src/ui/CopyText/CopyText"

export function ParadiseCopyBox({ text }: { text: string }) {
  return (
    <Box>
      <LinkWrapper>{text}</LinkWrapper>
      <CopyButtonWrapper>
        <CopyText
          copiedText="Copied!"
          value={text}
          button={<MButton variant="minimal">Copy</MButton>}
        />
      </CopyButtonWrapper>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;

  background-color: ${mColors.neutral};
  border-radius: 0.75rem;
  overflow: auto;
`

const LinkWrapper = styled.div`
  padding: ${spacing.M};

  flex: 1;
  white-space: nowrap;
  overflow: auto;
  flex-shrink: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const CopyButtonWrapper = styled.div`
  padding: ${spacing.M};
  box-shadow: -16px 0 15px 0 ${mColors.neutral};
`
