import { TParadiseShippableProductsQueryParams } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"

export const paradiseShippableProductKeys = {
  all() {
    return ["paradiseShippableProducts"] as const
  },

  shippableProductLists() {
    return [...this.all(), "list"] as const
  },

  shippableProductList(filter?: TParadiseShippableProductsQueryParams) {
    if (!filter) {
      return [...this.shippableProductLists()] as const
    }

    return [...this.shippableProductLists(), filter] as const
  },

  shippableProduct(id: string) {
    return [...this.all(), id] as const
  },
} as const
