import { countryData } from "src/components/Account/BillingPortal/countryData"
import { TCountryDataCodes } from "src/data/countries/countryTypes"
import { ErrorService } from "src/utils/ErrorService"

/** Use country code to get country name; will return the country code if no
 * match is found and the fallback option is enabled. */
export function countryCodeToCountry(
  countryCode: TCountryDataCodes | string,
  options?: { fallback: boolean }
): string | undefined {
  const countryName = countryData.find((c) => c.code === countryCode)?.label
  if (options?.fallback) {
    return countryName || countryCode
  }
  return countryName
}

/**
 * Formats the country name based on the provided country code and locale.
 *
 * @param  countryCode - The country code to format.
 * @param  locale - The locale to use for formatting.
 * @param  fallback - The fallback option to use if the country name cannot be determined. If set to "code", the country code will be returned else `undefined` will be returned.
 * @returns The formatted country name or the country code if the name cannot be determined.
 */
export function getCountryDisplayName({
  countryCode,
  locale,
  fallback = "code",
}: {
  countryCode: string
  locale: string
  fallback?: Intl.DisplayNamesOptions["fallback"]
}) {
  const IntlDisplayName = new Intl.DisplayNames([locale], {
    type: "region",
    fallback,
  })

  try {
    return IntlDisplayName.of(countryCode) || countryCode
  } catch (err) {
    ErrorService.captureException(err)
    return countryCode
  }
}
