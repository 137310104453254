import { spacing } from "@minuthq/meatball-ui-react"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import {
  TParadiseShippableProductsFilters,
  useParadiseShippableProductsFilter,
} from "src/components/Paradise/ParadiseShippableProducts/useParadiseShippableProductsFilter"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  ParadiseLayout,
  ParadiseTitle,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { isValidCurrency } from "src/data/paradise/logic/paradiseLogic"
import { parseParadiseShippableProductsSortField } from "src/data/paradise/paradiseShippableProducts/logic/paradiseShippableProductsLogic"
import { useFetchParadiseShippableProducts } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsQueries"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { formatUtcDate } from "src/utils/l10n"

const shippableProductsFilterLabels: Record<
  keyof TParadiseShippableProductsFilters,
  string
> = {
  shippable_products_currency_code: "Currency code",
  shippable_products_include_deleted: "Include deleted",
  shippable_products_offset: "Offset",
  shippable_products_sku: "SKU",
} as const

export function ParadiseShippableProducts() {
  const { navigate } = useRouter()

  const { filter, setFilter, offset, setOffset, sortedFilters } =
    useParadiseShippableProductsFilter()

  const { sortValue, setSortValue } = useSortParam()

  const limit = 50
  const fetchParadiseShippableProducts = useFetchParadiseShippableProducts({
    filter: {
      limit,
      offset,
      sku: filter.shippable_products_sku || undefined,
      currency_code: isValidCurrency(filter.shippable_products_currency_code)
        ? filter.shippable_products_currency_code
        : undefined,
      sort_by: parseParadiseShippableProductsSortField(sortValue?.id)
        ? sortValue?.id
        : undefined,
      sort_order: sortValue?.order || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const shippableProducts =
    fetchParadiseShippableProducts.data?.shippable_products

  const tableColumns: TableColumn<
    TParadiseShippableProduct,
    keyof TParadiseShippableProduct
  >[] = [
    {
      label: "ID",
      value: "id",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>
          <InternalLink to={Routes.ParadiseShippableProduct.location(data.id)}>
            {data.id}
          </InternalLink>
        </div>
      ),
      disabled: true,
    },
    {
      label: "Product ID",
      value: "id",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.product_id}</div>,
      disabled: true,
    },
    {
      label: "Created at",
      value: "created_at",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{formatUtcDate({ date: data.created_at })}</div>
      ),
      enableSort: true,
    },
    {
      label: "Updated at",
      value: "updated_at",
      columnWidth: "min-content",
      renderCell: (data) => (
        <div>{formatUtcDate({ date: data.updated_at })}</div>
      ),
    },
    {
      label: "SKU",
      value: "sku",
      columnWidth: "min-content",
      renderCell: (data) => {
        return <div>{data.sku}</div>
      },
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.shippable_products_sku ?? undefined}
            placeholder="Search by SKU"
            onChange={(v) => setFilter("shippable_products_sku", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact SKU</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
      enableSort: true,
    },
    {
      label: "Currency code",
      value: "currency_code",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.currency_code}</div>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <MSelect
            value={filter.shippable_products_currency_code ?? ""}
            options={[
              {
                label: "AUD",
                value: "AUD",
              },
              {
                label: "CAD",
                value: "CAD",
              },
              {
                label: "EUR",
                value: "EUR",
              },
              {
                label: "GBP",
                value: "GBP",
              },
              {
                label: "USD",
                value: "USD",
              },
            ]}
            onChange={(v) => {
              setFilter("shippable_products_currency_code", v)
            }}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Barcode",
      value: "barcode",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.barcode}</div>,
    },
    {
      label: "Harmonized System Code",
      value: "harmonized_system_code",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.harmonized_system_code}</div>,
    },
    {
      label: "Type",
      value: "type",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.type}</div>,
    },
    {
      label: "Compare at price",
      value: "compare_at_price",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.compare_at_price.formatted_amount}</div>,
    },

    {
      label: "Country of origin",
      value: "country_of_origin",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.country_of_origin}</div>,
    },
    {
      label: "Pricing model",
      value: "pricing_model",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.pricing_model}</div>,
    },
    {
      label: "Weight (g)",
      value: "weight",
      columnWidth: "min-content",
      renderCell: (data) => <div>{data.weight}</div>,
    },
  ]

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  } = useTableColumns<TParadiseShippableProduct>({
    columns: tableColumns,
    data: shippableProducts,
    sort: sortValue,
    onSortChange: setSortValue,
    options: {
      localStorageKey: "minut.paradise.shippable_products",
    },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Shippable Products
            <MBadge color="info">
              Total:{" "}
              {fetchParadiseShippableProducts.isSuccess &&
                fetchParadiseShippableProducts.data?.paging.total_count}
            </MBadge>
          </ParadiseTitle>
        }
      />

      <TopWrapper>
        <div>
          <ParadiseAppliedFilters
            filters={sortedFilters.map((f) => ({
              label: shippableProductsFilterLabels[f.key],
              value: String(f.value),
              onRemove: () => setFilter(f.key, null),
            }))}
          />
        </div>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        error={{
          hasError: fetchParadiseShippableProducts.isError,
          title: fetchParadiseShippableProducts.error?.message,
        }}
        isLoadingMore={fetchParadiseShippableProducts.isPreviousData}
        isLoading={fetchParadiseShippableProducts.isLoading}
        onRowClick={(index) => {
          const shippableProduct = shippableProducts?.[index]

          if (shippableProduct) {
            return navigate(
              Routes.ParadiseShippableProduct.location(shippableProduct.id)
            )
          }
        }}
      />

      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchParadiseShippableProducts.data?.paging.total_count}
      />
    </ParadiseLayout>
  )
}
