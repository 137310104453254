import { getTimezoneOffset } from "date-fns-tz"

export type TTimezone = {
  label: string
  tzCode: string
  name: string
  utc: string
  offsetMinutes: number
}

/**
 * Retrieves a sorted list of all supported timezones with formatted labels and offsets.
 *
 * Each timezone object contains:
 * - `label`: A formatted string including GMT offset and full timezone code.
 * - `tzCode`: The IANA timezone identifier.
 * - `name`: GMT offset and simplified timezone name.
 * - `utc`: The timezone's UTC offset in ±HH:mm format.
 * - `offsetMinutes`: The timezone's UTC offset in minutes.
 *
 * @returns {TTimezone[]} An array of timezone objects sorted by UTC offset (ascending).
 *
 * @example
 * const timezones = getTimezones();
 * console.log(timezones[0]);
 * // Output:
 * // {
 * //   "label": "(GMT-11:00) Pacific/Midway ",
 * //   "tzCode": "Pacific/Midway",
 * //   "name": "(GMT-11:00) Midway",
 * //   "utc": "-11:00",
 * //   "offsetMinutes": -660
 * // }
 *
 * @example
 * // Find a specific timezone
 * const berlinTZ = getTimezones().find(tz => tz.tzCode === 'Europe/Berlin');
 * console.log(berlinTZ);
 * // Output:
 * // {
 * //   "label": "(GMT+01:00) Europe/Berlin ",
 * //   "tzCode": "Europe/Berlin",
 * //   "name": "(GMT+01:00) Berlin",
 * //   "utc": "+01:00",
 * //   "offsetMinutes": 60
 * // }
 */
export function getTimezones(): TTimezone[] {
  const supportedTimezones = Intl.supportedValuesOf("timeZone")

  const timezones = supportedTimezones.map((tz) => {
    const utcOffset = getTimezoneOffset(tz)

    const totalMinutes = Math.abs(utcOffset / (60 * 1000))

    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    const formattedHours = String(hours).padStart(2, "0")
    const formattedMinutes = String(minutes).padStart(2, "0")

    const sign = utcOffset >= 0 ? "+" : "-"

    const utcString = `${sign}${formattedHours}:${formattedMinutes}`
    const gmtOffsetString = `(GMT${utcString})`

    return {
      label: `${gmtOffsetString} ${tz} `,
      tzCode: tz,
      name: `${gmtOffsetString} ${tz.split("/")[1]}`,
      utc: utcString,
      offsetMinutes: utcOffset / (60 * 1000),
    }
  })

  return timezones.sort((a, b) => a.offsetMinutes - b.offsetMinutes)
}

export function getTimezoneByTzCode(tzCode: string): TTimezone | undefined {
  return getTimezones().find((tz) => tz.tzCode === tzCode)
}
