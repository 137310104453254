import styled from "styled-components"

import { mColors, spacing } from "@minuthq/meatball-ui-react"

import { TParadiseCreateOrderCart } from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/useParadiseCreateOrder"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useFetchParadiseShippableProducts } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsQueries"
import { TParadiseShippableProduct } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { useTableColumns } from "src/ui/GridTable/useTableColumns/useTableColumns"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { formatPrice } from "src/utils/formatPrice"

export function ParadiseCreateOrderShippableProducts({
  cart,
  updateShippableProducts,
  currency,
}: {
  cart: TParadiseCreateOrderCart
  updateShippableProducts: (
    products: TParadiseCreateOrderCart["shippableProducts"]
  ) => void
  currency: TSubscriptionCurrencyCode
}) {
  const fetchShippableProducts = useFetchParadiseShippableProducts({
    filter: {
      currency_code: currency,
    },
  })

  const { rows, templateColumns, headerElements } =
    useTableColumns<TParadiseShippableProduct>({
      columns: [
        {
          value: "product",
          label: "Product",
          renderCell: (data) => {
            return (
              <ProductWrapper>
                <ImageWrapper>
                  <img src={data.image_url} />
                </ImageWrapper>
                <div>
                  <MText>{data.product_id}</MText>
                </div>
              </ProductWrapper>
            )
          },
          columnWidth: "1fr",
        },
        {
          value: "quantity",
          label: "Quantity",
          renderCell: (data) => {
            const cartProduct = cart.shippableProducts.find(
              (product) => product.product.product_id === data.product_id
            )

            const isInCart = !!cartProduct

            return (
              <div>
                <QuantityWrapper>
                  <MTextField
                    type="number"
                    value={(cartProduct?.quantity ?? 0).toString()}
                    max={100}
                    min={0}
                    onChange={(value) => {
                      const parsedValue = parseInt(value)

                      if (
                        !parsedValue ||
                        parsedValue > 100 ||
                        parsedValue < 0
                      ) {
                        return
                      }

                      if (isInCart) {
                        updateShippableProducts(
                          cart.shippableProducts.map((product) => {
                            if (
                              product.product.product_id === data.product_id
                            ) {
                              return {
                                ...product,
                                quantity: parseInt(value),
                              }
                            }

                            return product
                          })
                        )

                        return
                      }

                      updateShippableProducts([
                        ...cart.shippableProducts,
                        {
                          product: data,
                          quantity: parseInt(value),
                        },
                      ])
                    }}
                    size="small"
                    disableClearButton
                  />
                </QuantityWrapper>
              </div>
            )
          },
          columnWidth: "1fr",
        },
        {
          value: "total",
          label: "Total",
          renderCell: () => <div>{formatPrice(0, currency)}</div>,
          columnWidth: "auto",
        },
      ],
      // We only need to render one type of product
      // Client side filtering until BE starts supporting it. The list is small enough that this is fine.
      data: fetchShippableProducts.data?.shippable_products.filter(
        (product) => product.type === "additional"
      ),
    })

  return (
    <div>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        disableRowHover
      />
    </div>
  )
}

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  gap: ${spacing.S};
`

const ImageWrapper = styled.div`
  border: 1px solid ${mColors.divider};
  border-radius: 0.5rem;

  & > img {
    width: 100%;
    height: 100%;
  }
`

const QuantityWrapper = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
`
