import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { paradiseShippingKeys } from "src/data/paradise/paradiseShipping/paradiseShippingQueryCache"
import {
  TParadiseShippingDestinationsResponseBody,
  TParadiseShippingMethodsForCartRequestBody,
  TParadiseShippingMethodsForCartResponseBody,
  TParadiseShippingMethodsQueryParams,
  TParadiseShippingMethodsResponseBody,
} from "src/data/paradise/paradiseShipping/paradiseShippingTypes"
import { useMinutQuery } from "src/hooks/minutQuery"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchParadiseShippingDestinations(props?: {
  options?: UseQueryOptions<
    TParadiseShippingDestinationsResponseBody,
    AxiosError,
    TParadiseShippingDestinationsResponseBody,
    ReturnType<typeof paradiseShippingKeys.destinationsList>
  >
}) {
  async function fetchParadiseShippingDestinations() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/shipping_methods/destinations`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseShippingKeys.destinationsList(),
    queryFn: fetchParadiseShippingDestinations,
    ...props?.options,
  })
}

export function useFetchParadiseShippingMethods({
  filter,
  options,
}: {
  filter?: TParadiseShippingMethodsQueryParams
  options?: UseQueryOptions<
    TParadiseShippingMethodsResponseBody,
    AxiosError,
    TParadiseShippingMethodsResponseBody,
    ReturnType<typeof paradiseShippingKeys.shippingMethodsList>
  >
}) {
  async function fetchParadiseShippingMethods() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/ecommerce/pim/shipping_methods`,
      { params: filter }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseShippingKeys.shippingMethodsList(filter),
    queryFn: fetchParadiseShippingMethods,
    ...options,
  })
}

export function useFetchParadiseShippingMethod({ id }: { id: string }) {
  return useMinutQuery<`/ecommerce/pim/shipping_methods/{id}`>({
    apiPath: "/ecommerce/pim/shipping_methods/{id}",
    queryKey: paradiseShippingKeys.shippingMethod(id),
    queryPath: `/ecommerce/pim/shipping_methods/${id}`,
  })
}

export function useFetchParadiseShippingMethodsForCart({
  cart,
  options,
}: {
  cart: TParadiseShippingMethodsForCartRequestBody
  options?: UseQueryOptions<
    TParadiseShippingMethodsForCartResponseBody,
    AxiosError,
    TParadiseShippingMethodsForCartResponseBody,
    ReturnType<typeof paradiseShippingKeys.shippingMethodsForCart>
  >
}) {
  async function fetchParadiseShippingMethodsForCart() {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/ecommerce/cart_estimate/shipping_methods`,
      cart
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseShippingKeys.shippingMethodsForCart(cart),
    queryFn: fetchParadiseShippingMethodsForCart,
    ...options,
  })
}
