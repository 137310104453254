import { forwardRef } from "react"

import { Button, ButtonProps } from "@minuthq/meatball-ui-react"

import { MLocation } from "src/router/routeTypes"
import { useRouter } from "src/router/useRouter"

export type TMButtonProps = {
  appHref?: string | (Partial<MLocation> & { pathname: string })
} & ButtonProps

function _MButton(
  { appHref, ...props }: TMButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { navigate } = useRouter()

  function navigateToAppHref() {
    appHref && navigate(appHref)
  }

  return (
    <Button
      ref={ref}
      onClick={(e) => {
        if (appHref) {
          navigateToAppHref()
        } else {
          props.onClick?.(e)
        }
      }}
      {...props}
    />
  )
}

export const MButton = forwardRef(_MButton)
