import { useMemo, useState } from "react"

import { InfoIcon } from "@minuthq/meatball-ui-icons"
import { Button, mColors, spacing } from "@minuthq/meatball-ui-react"
import { isBefore } from "date-fns"

import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseCreateQuoteDialog } from "src/components/Paradise/ParadiseQuotes/ParadiseCreateQuoteDialog/ParadiseCreateQuoteDialog"
import { ParadiseQuoteStatus } from "src/components/Paradise/ParadiseQuotes/ParadiseQuoteStatus"
import { TParadiseCombinedQuoteWithUsers } from "src/components/Paradise/ParadiseQuotes/paradiseQuoteTypes"
import { useParadiseQuotesFilter } from "src/components/Paradise/ParadiseQuotes/useParadiseQuotesFilter"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  IdWrapper,
  ParadiseLayout,
  ParadiseTitle,
  StatusWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchParadiseQuotes } from "src/data/paradise/paradiseQuotes/paradiseQuoteQueries"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { Routes } from "src/router/routes"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { useTableColumns } from "src/ui/GridTable/useTableColumns/useTableColumns"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { unique } from "src/utils/genericUtil"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseQuotes() {
  const [showCreateQuoteDialog, setShowCreateQuoteDialog] = useState(false)

  const { setOffset, offset } = useParadiseQuotesFilter()

  const limit = 50
  const fetchParadiseQuotes = useFetchParadiseQuotes({
    filter: {
      limit: limit,
      offset,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const userIds = [
    fetchParadiseQuotes.data?.quotes.map((quote) => quote.sales_rep_user_id),
    fetchParadiseQuotes.data?.quotes.map((quote) => quote.customer_id),
  ]
    .flat()
    .filter((id) => id !== undefined)
  const fetchParadiseUsers = useFetchParadiseUsers({
    filter: {
      ids: unique(userIds).join(",") || undefined,
    },
    options: {
      enabled: !!fetchParadiseQuotes.data?.paging.total_count,
      keepPreviousData: true,
    },
  })

  const combinedQuotesWithUsers = useMemo(() => {
    if (!fetchParadiseQuotes.data?.quotes) {
      return []
    }

    return fetchParadiseQuotes.data?.quotes.map((quote) => ({
      ...quote,
      salesRep: fetchParadiseUsers.data?.users.find(
        (user) => user.id === quote.sales_rep_user_id
      ),
      customer: fetchParadiseUsers.data?.users.find(
        (user) => user.id === quote.customer_id
      ),
    }))
  }, [fetchParadiseQuotes.data?.quotes, fetchParadiseUsers.data?.users])

  const {
    headerElements,
    rows,
    templateColumns,
    interactiveColumns,
    interactiveVisibleColumns,
    updateColumnVisibility,
  } = useTableColumns<TParadiseCombinedQuoteWithUsers>({
    columns: [
      {
        value: "id",
        label: "Id",
        renderCell: (data) => (
          <div>
            <IdWrapper>
              <InternalLink to={Routes.ParadiseQuote.location(data.id)}>
                {data.id}
              </InternalLink>
              {!!data.deleted_at && (
                <MTooltip title="Deleted">
                  <InfoIcon width={12} fill={mColors.systemError} />
                </MTooltip>
              )}
            </IdWrapper>
          </div>
        ),
        columnWidth: "min-content",
      },
      {
        value: "customer_name",
        label: "Customer name",
        renderCell: (data) => <div>{data.customer_name}</div>,
        columnWidth: "min-content",
      },
      {
        value: "sales_rep",
        label: "Sales rep email",
        renderCell: (data) => (
          <div>
            {!!data.salesRep ? (
              <div>
                <InternalLink
                  to={Routes.ParadiseUser.location(data.salesRep.user_id)}
                >
                  {data.salesRep.email}
                </InternalLink>
              </div>
            ) : (
              <div>{data.sales_rep_user_id}</div>
            )}
          </div>
        ),
        columnWidth: "min-content",
      },
      {
        value: "status",
        label: "Status",
        renderCell: (data) => (
          <div>
            <div>
              <ParadiseQuoteStatus status={data.status} />
            </div>
          </div>
        ),
        columnWidth: "min-content",
      },
      {
        value: "created_at",
        label: "Created at",
        renderCell: (data) => (
          <div>
            {formatUtcDate({
              date: data.created_at,
            })}
          </div>
        ),
        columnWidth: "min-content",
      },
      {
        value: "expires_at",
        label: "Expires at",
        renderCell: (data) => (
          <div>
            <StatusWrapper>
              <div>
                {formatUtcDate({
                  date: data.expires_at,
                })}
              </div>
              {isBefore(new Date(data.expires_at), new Date()) && (
                <MBadge color="error" size="x-small">
                  Expired
                </MBadge>
              )}
            </StatusWrapper>
          </div>
        ),
        columnWidth: "min-content",
      },
      {
        value: "order_number",
        label: "Order number",
        renderCell: (data) => (
          <div>
            {data.order_number ? (
              <InternalLink
                to={Routes.ParadiseOrder.location(data.order_number)}
              >
                {data.order_number}
              </InternalLink>
            ) : (
              <div>-</div>
            )}
          </div>
        ),
        columnWidth: "min-content",
      },
      {
        value: "customer",
        label: "Customer",
        renderCell: (data) => (
          <div>
            {!!data.customer ? (
              <div>
                <InternalLink
                  to={Routes.ParadiseUser.location(data.customer.user_id)}
                >
                  {data.customer.email}
                </InternalLink>
              </div>
            ) : (
              <div>{data.customer_id || "-"}</div>
            )}
          </div>
        ),
        columnWidth: "1fr",
      },

      {
        value: "actions",
        label: "",
        renderCell: () => (
          <div>
            <MoreButton deleteLabel="Delete" onDelete={() => {}} />
          </div>
        ),
        columnWidth: "auto",
      },
    ],
    data: combinedQuotesWithUsers,
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Quotes
            <MBadge color="info">
              Total:{" "}
              {fetchParadiseQuotes.isSuccess &&
                fetchParadiseQuotes.data?.paging.total_count}
            </MBadge>
          </ParadiseTitle>
        }
        actionBar={
          <div>
            <Button
              variant="subtle"
              onClick={() => setShowCreateQuoteDialog(true)}
              size="small"
            >
              Create quote
            </Button>
          </div>
        }
        size="unset"
      />

      <TopWrapper>
        <div />
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        isLoading={
          fetchParadiseQuotes.isLoading || fetchParadiseUsers.isInitialLoading
        }
        isLoadingMore={
          fetchParadiseQuotes.isPreviousData ||
          fetchParadiseQuotes.isPreviousData
        }
        error={{
          hasError: fetchParadiseQuotes.isError,
          title: fetchParadiseQuotes.error?.message,
        }}
      />

      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchParadiseQuotes.data?.paging.total_count}
      />

      <ParadiseCreateQuoteDialog
        open={showCreateQuoteDialog}
        onClose={() => setShowCreateQuoteDialog(false)}
      />
    </ParadiseLayout>
  )
}
