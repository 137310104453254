import { useAppData } from "src/context/useAppData"
import { useLogout } from "src/hooks/useLogout"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function StoreBlockerDialog({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean
  onClose: () => void
  onConfirm?: () => void
}) {
  const { t, langKeys } = useTranslate()
  const { user } = useAppData()
  const { logout } = useLogout()

  return (
    <ConfirmDialog
      confirmLabel={t(langKeys.go_to_store)}
      closeLabel={t(langKeys.skip_for_now)}
      onClose={onClose}
      onConfirm={onConfirm || (() => {})}
      open={open}
    >
      <MText variant="heading2" marginBottom={spacing.S}>
        {t(langKeys.subscription_store_blocker_title)}
      </MText>
      <MText variant="body" marginBottom={spacing.L}>
        {t(langKeys.subscription_store_blocker_info)}
      </MText>
      <MText variant="heading3" marginBottom={spacing.S}>
        {t(langKeys.subscription_store_blocker_already_purchased_title)}
      </MText>
      <MText variant="body" marginBottom={spacing.L}>
        {t(langKeys.subscription_store_blocker_already_purchased_info)}
      </MText>
      {user?.email} |{" "}
      <TextButton
        onClick={() =>
          logout({
            reason: "User clicked logout in store blocker dialog",
            userInitiated: true,
          })
        }
      >
        {t(langKeys.sign_out)}
      </TextButton>
    </ConfirmDialog>
  )
}
