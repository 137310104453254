import styled from "styled-components"

import { HREF_MINUT_SUBSCRIPTION_TERMS } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function CreateHomeTerms({
  checkedTerms,
  onCheckTerms,
  checkedTermsError,
  homeCount,
}: {
  checkedTerms: boolean
  onCheckTerms: () => void
  checkedTermsError: boolean
  /**
   * Number of homes to be created
   * @default 1
   */
  homeCount?: number
}) {
  const { t, langKeys } = useTranslate()

  return (
    <TermsWrapper>
      <MBanner type="info" fullWidth>
        <MCheckbox
          label={
            <MText variant="bodyS">
              {t(langKeys.create_home_confirmation_accept_body, {
                count: homeCount ?? 1,
              })}{" "}
              <ExternalLink href={HREF_MINUT_SUBSCRIPTION_TERMS}>
                {t(langKeys.learn_more)}
              </ExternalLink>
            </MText>
          }
          checked={checkedTerms}
          onCheck={onCheckTerms}
          error={checkedTermsError}
        />
      </MBanner>

      {checkedTermsError && (
        <MBanner type="error" fullWidth>
          {t(langKeys.create_home_confirmation_accept_error)}
        </MBanner>
      )}
    </TermsWrapper>
  )
}

const TermsWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
  margin-top: ${spacing.L};
`
