import { isDev, isStagingEnv } from "src/constants/env"
import { storeUrl } from "src/constants/hrefs"

export function generateQuoteLink(quoteId: string, shippingCountry: string) {
  if (isDev || isStagingEnv) {
    return `${storeUrl.staging}/quote/${quoteId}?shipping_country=${shippingCountry}`
  }

  return `${storeUrl.production}/quote/${quoteId}?shipping_country=${shippingCountry}`
}
