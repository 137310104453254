import styled from "styled-components"

import { spacing, Text, TextField } from "@minuthq/meatball-ui-react"

import { NativeDateTimeField } from "src/components/Paradise/NativeDateTimeField"
import { ParadiseCountrySelector } from "src/components/Paradise/ParadiseCountrySelector"
import { ParadiseCreateQuoteDialogSuccess } from "src/components/Paradise/ParadiseQuotes/ParadiseCreateQuoteDialog/ParadiseCreateQuoteDialogSuccess"
import {
  initialParadiseCreateQuoteValues,
  useParadiseCreateQuote,
} from "src/components/Paradise/ParadiseQuotes/ParadiseCreateQuoteDialog/useParadiseCreateQuote"
import { ParadiseShippableProductsQuantityTable } from "src/components/Paradise/ParadiseShippableProductsQuantityTable"
import { Combobox } from "src/ui/Combobox/Combobox"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MTextField } from "src/ui/MTextField/MTextField"
import { capitalizeFirstChar } from "src/utils/l10n"

const FORM_ID = "paradise-create-quote-form"

export function ParadiseCreateQuoteDialog({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const {
    form,
    shippingDestinations,
    isLoadingShippingDestinations,
    products,
    isInitialLoadingProducts,
    selectedProduct,
    selectedPlanAssociatedShippableProducts,
    selectedAdditionalShippableProducts,
    postParadiseQuoteData,
    postParadiseQuoteIsLoading,
    postParadiseQuoteError,
    resetPostParadiseQuote,
  } = useParadiseCreateQuote()

  function getDialogContent() {
    if (isLoadingShippingDestinations) {
      return (
        <div>
          <MSkeleton height={50} />
          <MSkeleton height={40} width="30ch" />
          <MSkeleton height={40} width="30ch" />
        </div>
      )
    }

    if (postParadiseQuoteData) {
      return (
        <SuccessWrapper>
          <ParadiseCreateQuoteDialogSuccess
            quoteId={postParadiseQuoteData.id}
            shippingCountry={form.values.shippingCountry}
          />
        </SuccessWrapper>
      )
    }
    return (
      <Form onSubmit={form.handleSubmit} id={FORM_ID}>
        <FieldSet>
          <Text variant="subtitle">General</Text>
          <TextField
            label="Customer name"
            value={form.values.customerName}
            onChange={(value) => {
              form.setValues({
                customerName: value,
              })
            }}
            name="customer-name"
            required
            requiredIndicator
          />

          <TextField
            label="Discount code"
            value={form.values.cart.discountCode}
            onChange={(value) => {
              form.setValues({
                cart: {
                  ...form.values.cart,
                  discountCode: value,
                },
              })
            }}
            name="customer-name"
            required
            requiredIndicator
          />

          <ParadiseCountrySelector
            label="Shipping country"
            name="shipping-country"
            countryCodes={
              shippingDestinations?.map(
                (destination) => destination.country_code
              ) || []
            }
            selectedCountryCode={form.values.shippingCountry}
            onSelectCountryCode={(value) => {
              form.setValues({
                shippingCountry: value,
                cart: {
                  ...initialParadiseCreateQuoteValues.cart,
                  discountCode: form.values.cart.discountCode,
                },
              })
            }}
            required
            requiredIndicator
          />

          <NativeDateTimeField
            label="Expires at"
            value={form.values.expiresAt}
            onChange={(value) => {
              form.setValues({
                expiresAt: value,
              })
            }}
            required
            requiredIndicator
          />
        </FieldSet>

        <FieldSet
          disabled={!form.values.shippingCountry || isInitialLoadingProducts}
        >
          <Text variant="subtitle">Plan</Text>
          <PlanWrapper>
            <Combobox
              label="Plan"
              input={form.values.cart.planId}
              onInput={() => {}}
              selectedValue={form.values.cart.planId}
              onChange={(value) => {
                form.setValues({
                  cart: {
                    ...form.values.cart,
                    planId: value,
                  },
                })
              }}
              options={
                products?.map((product) => ({
                  label: `${capitalizeFirstChar(product.plan).replace("_", " ")} (${product.plan_id})`,
                  value: product.plan_id,
                })) || []
              }
              required
              requiredIndicator
            />
            <MTextField
              label="Quantity"
              type="number"
              min={1}
              value={form.values.cart.prepaidHomeQuantity.toString()}
              onChange={(value) => {
                form.setValues({
                  cart: {
                    ...form.values.cart,
                    prepaidHomeQuantity: parseInt(value),
                    planAssociatedShippableProducts:
                      selectedPlanAssociatedShippableProducts?.map(
                        (product) => ({
                          productId: product.id,
                          quantity: parseInt(value),
                        })
                      ) || [],
                  },
                })
              }}
              name="quantity"
              disabled={!selectedProduct}
              disableClearButton
              required
              requiredIndicator
            />
          </PlanWrapper>
        </FieldSet>

        <FieldSet disabled={!selectedProduct}>
          <Text variant="subtitle">Plan associated shippable products</Text>
          <ParadiseShippableProductsQuantityTable
            availableShippableProducts={
              selectedPlanAssociatedShippableProducts?.map((product) => ({
                productId: product.id,
                imageUrl: product.image_url,
              })) || []
            }
            selectedShippableProducts={
              form.values.cart.planAssociatedShippableProducts
            }
            updateShippableProducts={(products) => {
              form.setValues({
                cart: {
                  ...form.values.cart,
                  planAssociatedShippableProducts: products,
                },
              })
            }}
            currency="USD"
            hideTotal
            disableQuantity
          />
        </FieldSet>

        <FieldSet disabled={!selectedProduct}>
          <Text variant="subtitle">Additional shippable products</Text>
          <ParadiseShippableProductsQuantityTable
            availableShippableProducts={
              selectedAdditionalShippableProducts?.map((product) => ({
                productId: product.id,
                imageUrl: product.image_url,
              })) || []
            }
            selectedShippableProducts={
              form.values.cart.additionalShippableProducts
            }
            updateShippableProducts={(products) => {
              form.setValues({
                cart: {
                  ...form.values.cart,
                  additionalShippableProducts: products,
                },
              })
            }}
            currency="USD"
            hideTotal
          />
        </FieldSet>
      </Form>
    )
  }

  return (
    <MDialog
      title="Create quote"
      open={open}
      onClose={() => {
        resetPostParadiseQuote()
        onClose()
      }}
      width="large"
      confirmLabel="Create"
      confirmButtonProps={{
        loading: postParadiseQuoteIsLoading,
      }}
      formId={!postParadiseQuoteData ? FORM_ID : undefined}
      closeLabel={postParadiseQuoteData ? "Close" : "Cancel"}
      error={postParadiseQuoteError?.message}
    >
      <div>{getDialogContent()}</div>
    </MDialog>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.L};
`

const FieldSet = styled.fieldset`
  all: unset;

  display: flex;
  flex-direction: column;
  gap: ${spacing.M};

  transition: opacity 0.3s;

  &:disabled {
    opacity: 0.5;
  }
`

const PlanWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${spacing.M};
`

const SuccessWrapper = styled.div`
  height: 300px;
`
