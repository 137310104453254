import { paradiseShippableProductKeys } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsQueryCache"
import { TParadiseShippableProductsQueryParams } from "src/data/paradise/paradiseShippableProducts/paradiseShippableProductsTypes"
import { useMinutQuery } from "src/hooks/minutQuery"

export function useFetchParadiseShippableProducts({
  filter,
  options,
}: {
  filter?: TParadiseShippableProductsQueryParams
  options?: {
    keepPreviousData?: boolean
  }
}) {
  return useMinutQuery({
    apiPath: "/ecommerce/pim/shippable_products",
    queryPath: "/ecommerce/pim/shippable_products",
    queryKey: paradiseShippableProductKeys.shippableProductList(filter),
    filters: filter,
    options,
  })
}

export function useFetchParadiseShippableProduct({ id }: { id: string }) {
  return useMinutQuery<`/ecommerce/pim/shippable_products/{id}`>({
    apiPath: "/ecommerce/pim/shippable_products/{id}",
    queryPath: `/ecommerce/pim/shippable_products/${id}`,
    queryKey: paradiseShippableProductKeys.shippableProduct(id),
  })
}
