import { useState } from "react"

import { useDebounceCallback } from "usehooks-ts"

import {
  Combobox,
  TComboboxBaseCommonProps,
  TComboboxBaseOption,
} from "src/ui/Combobox/Combobox"

export type TComboboxOption = TComboboxBaseOption

export type ComboboxStatefulProps = {
  initialInput?: string
  onSearch: (input: string) => void
  searchDelay?: number
} & TComboboxBaseCommonProps

export function ComboboxStateful({
  searchDelay = 500,
  initialInput,
  onSearch,
  ...baseProps
}: ComboboxStatefulProps) {
  const [input, setInput] = useState(initialInput ?? "")

  const debouncedSearch = useDebounceCallback((value: string) => {
    onSearch(value)
  }, searchDelay)

  return (
    <Combobox
      {...baseProps}
      input={input}
      onInput={(value) => {
        debouncedSearch(value)
        setInput(value)
      }}
    />
  )
}
