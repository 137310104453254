import {
  TAcceptedTypesLiteral,
  TMapSearchParamType,
  TSearchParamsByKey,
} from "src/router/searchParamsTypes"

/**
 * Retrieves and converts a query parameter from the URL's search parameters.
 *
 * @param params.key - The key of the query parameter to retrieve.
 * @param params.type - The expected primitive type of the query parameter.
 * @returns The converted query parameter value or null if not found.
 *
 * @example
 * // For a URL like "https://example.com?userId=123"
 * const userId = getSearchParam({ key: "userId", type: "number" });
 * // Returns: 123 (as a number) or null if not found
 */
export function getSearchParam<const QueryKey extends TSearchParamsByKey>({
  key,
  type,
}: QueryKey): TMapSearchParamType<QueryKey["type"]> | null {
  if (typeof window === "undefined") {
    return null
  }

  const searchParams = new URLSearchParams(window.location.search)

  const searchParam = searchParams.get(key)

  return parseSearchParamValue({
    value: searchParam,
    type,
  }) as TMapSearchParamType<QueryKey["type"]> | null
}

export function parseSearchParamValue({
  value,
  type,
  list,
}: {
  value: string | null
  type: TAcceptedTypesLiteral
  list?: boolean
}) {
  if (value === null) {
    return null
  }

  if (!list) {
    return parseValue(value, type)
  }

  if (value === "") {
    return []
  }

  const splittedValues = value.split(",")

  return splittedValues.map((val) => parseValue(val, type))
}

export function parseValue(value: string, type: TAcceptedTypesLiteral) {
  if (type === "string") {
    return String(value)
  }

  if (type === "boolean") {
    return value === "true"
  }

  if (type === "number") {
    const parsedNumber = parseInt(value)

    return !isNaN(parsedNumber) ? parsedNumber : null
  }
}
