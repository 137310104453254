import qs from "qs"

import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import {
  createBasicLocation,
  ILocation,
  IPathMixin,
  ITabsMixin,
} from "src/router/routeTypes"

const HOME_GROUP_ROUTE = "/homes/groups"

const CreateHomeGroupBuilding = createBasicLocation(
  `${HOME_GROUP_ROUTE}/new-building`
)
const CreateHomeGroupLocation = createBasicLocation(
  `${HOME_GROUP_ROUTE}/new-location`
)

const HomeGroupDetails: ILocation<string> &
  ITabsMixin<readonly ["homes", "members", "address"]> &
  IPathMixin<string> = {
  location: (id) => {
    const pathname = `${HOME_GROUP_ROUTE}/details/${id}`
    return { pathname }
  },
  path(id) {
    return this.location(id).pathname
  },
  tabs: ["homes", "members", "address"],
} as const

const HomeGroupAddress: ILocation<string> = {
  location(homeGroupId) {
    const pathname = `${HomeGroupDetails.location(homeGroupId).pathname}/address`
    return { pathname }
  },
} as const

const HomeGroupBuildings: ILocation<{
  limit?: number
  offset?: number
} | void> = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return {
        pathname: `/homes`,
        search: `?limit=${HOME_LIST_LIMIT}&offset=0`,
        hash: "buildings",
      }
    }
    return {
      pathname: `/homes`,
      search: `?${queryFilter}`,
      hash: "buildings",
    }
  },
}

const HomeGroupLocations: ILocation<{
  limit?: number
  offset?: number
} | void> = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return {
        pathname: `/homes`,
        search: `?limit=${HOME_LIST_LIMIT}&offset=0`,
        hash: "locations",
      } as const
    }
    return {
      pathname: `/homes`,
      search: `?${queryFilter}`,
      hash: "locations",
    } as const
  },
}

const OldHomeGroups: ILocation<{ limit?: number; offset?: number } | void> = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return {
        pathname: `/home-groups`,
        search: `?limit=${HOME_LIST_LIMIT}&offset=0`,
      }
    }
    return {
      pathname: `/home-groups`,
      search: `?${queryFilter}`,
    }
  },
} as const

const HomeGroupAddHomes: ILocation<string> = {
  location(homeGroupId) {
    return {
      pathname: `${HomeGroupDetails.path(homeGroupId)}/homes/add-homes`,
    }
  },
} as const

const HomeGroupBuildingCreateHome: ILocation<string> = {
  location(homeGroupId) {
    return {
      pathname: `${HomeGroupDetails.path(homeGroupId)}/building/create-home`,
    }
  },
} as const

const HomeGroupBuildingImportHomes: ILocation<string> = {
  location(homeGroupId) {
    return {
      pathname: `${HomeGroupDetails.path(homeGroupId)}/building/import-homes`,
    }
  },
} as const

const HomeGroupAddMember: ILocation<string> = {
  location(homeGroupId) {
    return {
      pathname: `${HomeGroupDetails.path(homeGroupId)}/members/add-member`,
      hash: "home-group-access",
    }
  },
} as const

const HomeGroupAddMemberEmail: ILocation = {
  location(homeGroupId: string) {
    const pathname = `${HomeGroupDetails.path(homeGroupId)}/add-email-access`
    return { pathname, hash: HomeGroupDetails.tabs[1] }
  },
} as const

export const HomeGroupRoutes = {
  HomeGroupDetails,
  HomeGroupAddress,
  HomeGroupBuildings,
  HomeGroupLocations,
  OldHomeGroups,
  HomeGroupAddHomes,
  HomeGroupBuildingCreateHome,
  HomeGroupBuildingImportHomes,
  HomeGroupAddMember,
  HomeGroupAddMemberEmail,
  CreateHomeGroupBuilding,
  CreateHomeGroupLocation,
} as const
