import { useMemo, useState } from "react"

import { ParadiseSearchMultiSelect } from "src/components/ParadiseSearchMultiSelect"
import countries from "src/data/countries/countries.json"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"

export function ParadiseSelectShippingCountriesFilter({
  selectedCountries,
  onChange,
}: {
  selectedCountries: string[]
  onChange: (countries: string[]) => void
}) {
  const [searchedValue, setSearchedValue] = useState("")

  const searchedCountries = useMemo(() => {
    const normilizedSearchedValue = searchedValue.trim().toLowerCase()

    return countries.filter(
      (c) =>
        c.name.toLowerCase().includes(normilizedSearchedValue) ||
        c.code.toLowerCase().includes(normilizedSearchedValue)
    )
  }, [searchedValue])

  return (
    <ColumnPopoverWrapper>
      <ParadiseSearchMultiSelect
        placeholder="Search by country"
        selectedValues={selectedCountries}
        options={searchedCountries.map((c) => ({
          label: c.name,
          value: c.code,
        }))}
        onChange={onChange}
        onSearch={setSearchedValue}
        isLoading={false}
      />
    </ColumnPopoverWrapper>
  )
}
