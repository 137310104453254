import { usePostTrackEventWithParams } from "./analyticsQueries"

export type THomeEventTypes =
  | "Create New Units Clicked"
  | "Create Unit Initiated"
  | "Create Unit Confirmed"

function usePostHomeTrackingEvent<TEventProperties>(event: THomeEventTypes) {
  return usePostTrackEventWithParams<THomeEventTypes, TEventProperties>({
    event,
  })
}

export function usePostCreateHomeClicked() {
  return usePostHomeTrackingEvent<{
    context: "units_tab"
  }>("Create New Units Clicked")
}

export function usePostCreateHomeInitiated() {
  return usePostHomeTrackingEvent<{
    type: "building_selected" | "location_selected" | "no_selection"
  }>("Create Unit Initiated")
}

export function usePostCreateHomeConfirmed() {
  const x = usePostHomeTrackingEvent("Create Unit Confirmed")

  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}
