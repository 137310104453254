import { useEffect } from "react"

import { useDebounceValue } from "usehooks-ts"

import {
  TParadiseCreateOrderCart,
  TParadiseCreateOrderShippingAddressForm,
} from "src/components/Paradise/ParadiseOrders/ParadiseCreateOrder/useParadiseCreateOrder"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { TCountryCode } from "src/data/ecommerce/ecommerceTypes"
import { useFetchParadiseShippingMethodsForCart } from "src/data/paradise/paradiseShipping/paradiseShippingQueries"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { RadioGroup } from "src/ui/RadioGroup/RadioGroup"

export function ParadiseCreateOrderShippingMethod({
  cart,
  shippingCountry,
  updateShippingMethod,
  currency,
}: {
  cart: TParadiseCreateOrderCart
  shippingCountry: TParadiseCreateOrderShippingAddressForm["country"]
  updateShippingMethod: (shippingMethodId: string) => void
  currency: TSubscriptionCurrencyCode
}) {
  const hasShippableProducts =
    cart.shippableProducts.reduce((acc, product) => acc + product.quantity, 0) >
    0

  const [debouncedCart, setDebouncedCart] = useDebounceValue(
    {
      shippable_products: cart.shippableProducts.map((product) => ({
        id: product.product.product_id,
        quantity: product.quantity,
      })),
      // We ask for the country using a select, so we are more or less sure that it will be a valid country code
      shipping_country: shippingCountry as TCountryCode,
      currency,
    },
    1000
  )

  useEffect(() => {
    if (!hasShippableProducts) {
      return
    }

    if (!shippingCountry) {
      return
    }

    setDebouncedCart({
      shippable_products: cart.shippableProducts.map((product) => ({
        id: product.product.product_id,
        quantity: product.quantity,
      })),
      // We ask for the country using a select, so we are more or less sure that it will be a valid country code
      shipping_country: shippingCountry as TCountryCode,
      currency,
    })
  }, [cart, shippingCountry, currency, setDebouncedCart, hasShippableProducts])

  const fetchShippingMethods = useFetchParadiseShippingMethodsForCart({
    cart: debouncedCart,
    options: {
      enabled: hasShippableProducts && !!debouncedCart.shipping_country,
    },
  })

  return (
    <div>
      {!fetchShippingMethods.isInitialLoading ? (
        <RadioGroup
          direction="column"
          options={
            fetchShippingMethods.data?.map((shippingMethod) => ({
              value: shippingMethod.shipping_method_id,
              render: () => {
                return (
                  <div>
                    <MText>{shippingMethod.name}</MText>
                  </div>
                )
              },
            })) ?? []
          }
          value={cart.shippingMethodId}
          onChange={(value) => {
            if (!value) {
              return
            }

            updateShippingMethod(value)
          }}
        />
      ) : (
        <div>
          <MSkeleton width="100%" height={40} />
          <MSkeleton width="100%" height={40} />
          <MSkeleton width="100%" height={40} />
        </div>
      )}
    </div>
  )
}
