import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import EthernetConnected from "src/ui/icons/ethernet-connected.svg"
import EthernetDisconnected from "src/ui/icons/ethernet-disconnected.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { SVGProps } from "src/utils/tsUtil"

export function EthernetStatus({
  offline = false,
  ...props
}: {
  offline: boolean
} & SVGProps) {
  const { t } = useTranslate()

  const ethernet = offline
    ? { title: t(langKeys.offline), svg: EthernetDisconnected }
    : { title: t(langKeys.wired_network), svg: EthernetConnected }

  const EthernetIcon = ethernet.svg

  return (
    <MTooltip title={ethernet.title}>
      <div>
        <EthernetIcon height={30} width={30} {...props} />
      </div>
    </MTooltip>
  )
}
