import { SVGProps } from "react"
import styled, { css } from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type EmptyStateWithImageProps = {
  Image: React.FC<SVGProps<SVGSVGElement>>
  MobileImage?: React.FC<SVGProps<SVGSVGElement>>
  title: string
  description?: React.ReactNode
  content?: React.ReactNode
  imageSpacing?: `-${spacing}` | `${spacing}`
  limitImageWidth?: boolean
}

export function EmptyStateWithImage({
  Image,
  MobileImage,
  title,
  description,
  content,
  imageSpacing = `-${spacing.M}`,
  limitImageWidth = true,
}: EmptyStateWithImageProps) {
  return (
    <Box>
      <ImageBox $limitImageWidth={limitImageWidth} $mobileImage={!!MobileImage}>
        <Image />
        {MobileImage && <MobileImage />}
      </ImageBox>
      <ContentWrapper $spacing={imageSpacing}>
        <MText variant="heading2" textAlign="center" marginBottom={spacing.XS}>
          {title}
        </MText>
        <MText
          variant="bodyS"
          textAlign="center"
          marginBottom={spacing.L}
          color="secondary"
        >
          {description}
        </MText>
        <CenterWrapper>{content}</CenterWrapper>
      </ContentWrapper>
    </Box>
  )
}

const Box = styled.div`
  position: relative;
`

const ContentWrapper = styled.div<{
  $spacing: number | string
}>`
  margin-top: ${({ $spacing }) => $spacing};
  margin-inline: auto;
  max-width: 600px;
  text-wrap: pretty;
`

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImageBox = styled.div<{
  $limitImageWidth: boolean
  $mobileImage: boolean
}>`
  max-width: ${({ $limitImageWidth }) => ($limitImageWidth ? "800px" : "100%")};
  margin: 0 auto;
  container-type: inline-size;

  > svg {
    width: 100%;
    height: 100%;
  }

  ${({ $mobileImage }) =>
    $mobileImage &&
    css`
      > svg {
        display: none;
      }

      @container (${breakpoint.smallUp}) {
        > svg:first-child {
          display: block;
        }
      }

      @container (${breakpoint.smallDown}) {
        > svg:last-child {
          display: block;
        }
      }
    `}
`
