import { useMemo } from "react"
import styled from "styled-components"

import { List, ListItem } from "@material-ui/core"

import { IFilter } from "src/data/filters/types/filterTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { MButton } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"

const SFilterAdd = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  & > :not(:last-child) {
    margin-right: ${spacing.M};
  }
  & > button {
    white-space: nowrap;
    flex: 1 0 auto;
  }
`

function AddFilterButton({
  availableFilters,
  onSelect,
  disabled,
  onClick,
}: {
  availableFilters: IFilter[]
  onSelect: (itemId: string) => void
  disabled?: boolean
  onClick?: () => void
}) {
  const { t } = useTranslate()

  const onSelected = (
    f: IFilter,
    closeMenu: (event?: React.MouseEvent<EventTarget>) => void
  ) => {
    closeMenu()
    onSelect(f.id)
  }

  return (
    <DropdownButton
      displayValue={t(langKeys.filter)}
      placement="bottom-start"
      variant="secondary"
      disabled={!availableFilters.length || disabled}
      onClick={onClick}
    >
      {({ closeMenu }) => (
        <List>
          {availableFilters.map((f) => {
            const SvgIcon = f.icon
            return (
              <ListItem
                onClick={() => onSelected(f, closeMenu)}
                key={f.id}
                button
                disabled={f.disabled}
              >
                {SvgIcon && <SvgIcon height="18" width="18" />}
                <span style={{ marginLeft: "1ch" }}>{f.name(t)}</span>
              </ListItem>
            )
          })}
        </List>
      )}
    </DropdownButton>
  )
}

function filterAvailable(filter: IFilter, applied: IFilter[]): boolean {
  return !filter.unique || !applied.find((f) => f.id === filter.id)
}

export function FilterAdd<T extends IFilter>({
  filters,
  activeFilters,
  onAddFilter,
  onClearFilters,
  disabled,
  onClick,
  ...props
}: {
  filters: T[]
  activeFilters: T[]
  onAddFilter: (f: T) => void
  onClearFilters: () => void
  disabled?: boolean
  onClick?: () => void
}) {
  const { t } = useTranslate()

  const availableFilters = useMemo(() => {
    const available = filters.filter(
      (f) => !f.applied && filterAvailable(f, activeFilters)
    )
    return available
  }, [activeFilters, filters])

  const onFilterSelected = (id: string) => {
    const newFilter: T | undefined = availableFilters.find((f) => f.id === id)
    if (!newFilter) return

    onAddFilter(newFilter)
  }

  const clearClicked = () => {
    onClearFilters()
  }

  return (
    <SFilterAdd {...props}>
      <AddFilterButton
        onSelect={onFilterSelected}
        availableFilters={availableFilters}
        disabled={disabled}
        onClick={onClick}
      />
      {!!activeFilters.length && (
        <MButton variant="minimal" color="default" onClick={clearClicked}>
          {t(langKeys.clear_filters)}
        </MButton>
      )}
    </SFilterAdd>
  )
}
